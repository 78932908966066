<!-- Layout wrapper -->
<div class="layout-wrapper layout-content-navbar">

  @if (isLoggedIn) {
    <!-- Layout container -->
    <div class="layout-container">

      <!-- Menu -->
      <app-side-nav/>
      <!--/ Menu -->

      <!-- Layout page -->
      <div class="layout-page" [ngClass]="{'window-scrolled': isScrolled}">

        <!-- Navbar -->
        <app-header/>
        <!--/ Navbar -->

        <!-- Content wrapper -->
        <div class="content-wrapper">
          <!-- Content -->
          <div class="container-xxl flex-grow-1 container-p-y">
            <router-outlet></router-outlet>
          </div>
          <!--/ Content -->

          <!-- Footer -->
          <app-footer/>
          <!--/ Footer -->

          <!-- Content area backdrop -->
          <div class="content-backdrop fade"></div>
        </div>
        <!--/ Content wrapper -->

      </div>
      <!--/ Layout page -->

    </div>
    <!--/ Layout container -->

      <!-- Overlay -->
    <div class="layout-overlay layout-menu-toggle" (click)="handleMenuToggle()"></div>

    <!-- Drag Target Area To SlideIn Menu On Small Screens -->
    <div class="drag-target"></div>
  }
</div>
<!--/ Layout wrapper -->
