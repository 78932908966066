@if (control) {
  <div [formGroup]="formGroup">
    <re-captcha
      #captchaRef="reCaptcha"
      (resolved)="onCaptchaResolved($event)"
      siteKey="{{siteKey}}"></re-captcha>

    @if (formGroup?.invalid && (formGroup.get(control.name)?.dirty || formGroup.get(control.name)?.touched)) {
      <div class="fv-plugins-message-container fv-pluins-message-container--enabled invalid-feedback">
        @if (formGroup.hasError('required')) {
          {{ control.label }} is required.
        }
      </div>
    }
  </div>
}
