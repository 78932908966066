<div class="form-floating form-floating-outline">
  
    <input [ngClass]="{'is-invalid':error?.invalid && (error?.dirty || error?.touched)}"
      class="form-control"
      [type]="type"
      [formControl]="control"
      [placeholder]="placeholder"
      (blur)="onTouched()"
    />
    <label for="add-user-contact">{{label}}</label>
    <ng-container
    *ngIf="error?.invalid && (error?.dirty || error?.touched)">
    <div class="fv-plugins-message-container fv-pluins-message-container--enabled invalid-feedback">
      <ng-container *ngIf="error?.hasError('required')">
        {{label}} is required.
      </ng-container>
    </div>
  </ng-container>
</div>

<!--
<div class="form-floating form-floating-outline mb-5">
    <input
      [ngClass]="{'is-invalid':userForm.get('phone')?.invalid && (!userForm.get('phone')?.pristine || userForm.get('phone')?.touched)}"
      aria-label="+91-1234567890" class="form-control phone-mask"
      formControlName="phone"
      id="add-user-contact" placeholder="+91-1234567890" type="text">
    <label for="add-user-contact">Mobile</label>
    <ng-container
      *ngIf="userForm.get('phone')?.invalid && (!userForm.get('phone')?.pristine || userForm.get('phone')?.touched)">
      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
        <ng-container *ngIf="userForm.get('phone')?.hasError('required')">
          Phone is required.
        </ng-container>
      </div>
    </ng-container>
  </div> -->
