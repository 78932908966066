import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private baseUrl: string = 'https://authorization.mynextsalon.com';

  constructor(private http: HttpClient) {
  }

  getStoreList(token: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(this.baseUrl + '/auth/stores', {}, { headers });
  }
  selectStore(token:string,payload:ISelectStorePayload){
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(this.baseUrl + '/auth/select/store', payload, { headers });
  }
}

export interface IStore {
  key: string,
  account: string,
  store: string,
  role: string;
  [key: string]: any;
}

export interface ISelectStorePayload {
  key: string
}
