<ngx-spinner [fullScreen]="true" bdColor="rgba(0,0,0,0.5)" color="#ffffff" name="forgetPasswordPage" size="default"
             type="ball-pulse">
</ngx-spinner>

<div class="authentication-wrapper authentication-cover">
  <!-- Logo -->
  @if (isServiceFetched) {
    <a class="auth-cover-brand d-flex align-items-center gap-3" routerLink="/login">
      <div class="app-brand-logo">
        <div style="color:var(--bs-primary);">
          <img [src]="serviceData?.logo" alt="LOGO" class="rounded-2 logo" height="50"
               width="50">
        </div>
      </div>
      <span class="app-brand-text demo text-heading fw-semibold" *ngIf="false">{{ serviceData?.name }}</span>
    </a>
  }
  <!-- /Logo -->
  <div class="authentication-inner row m-0">

    <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
      <div>
        <img [src]="serviceData?.cover?serviceData?.cover:'https://app.mynextsalon.com/assets/img/services/cover.png'"
             alt="auth-model" class="authentication-image-model d-none d-lg-block"
             data-app-dark-img="illustrations/auth-cover-login-illustration-dark.png"
             data-app-light-img="illustrations/auth-cover-login-illustration-light.png"
             height="700"
             style="object-fit: contain">
      </div>
    </div>
    <!-- Forgot Password -->
    <div class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-md-12">
      <div class="w-px-400 mx-auto">
        <h4 class="mb-1">Forgot Password? 🔒</h4>
        <p class="mb-5">Enter your email and we'll send you instructions to reset your password</p>
        @if (isResetMailSent) {
          <div class="alert alert-success">
            Password reset instructions have been sent to your email address.You have 24 hours to complete the request.
          </div>
        }
        @if (wrongEmail) {
          <div class="alert alert-danger">
            <strong>ERROR: </strong>
            There is no user registered with that email address.
          </div>
        }
        <form (ngSubmit)="handleForgetSubmit()" [formGroup]="forgetPasswordForm"
              class="mb-5 fv-plugins-bootstrap5 fv-plugins-framework">
          @for (control of forgetFormMetaData?.create_form?.controls; track control.name) {
            <div class="mb-3">
              @if (control.type === 'email') {
                <text-email [error]="forgetPasswordForm.get(control.name)" autofocus=""
                            [formControlName]='control.name' [placeholder]='control.placeholder'
                            [label]='control.label'>
                </text-email>
              } @else if (control.type === 'recaptchaV2') {
                <mns-recaptcha [control]="control"></mns-recaptcha>
              }
            </div>
          }
          <button [disabled]="forgetPasswordForm.invalid || sendingEmail "
                  class="btn btn-primary d-grid w-100 mb-5 waves-effect waves-light"
                  type="submit">
            Send Reset Link
          </button>
        </form>

        <div class="text-center">
          <a class="d-flex align-items-center justify-content-center" routerLink="/login">
            <i class="ri-arrow-left-s-line scaleX-n1-rtl ri-20px me-1_5"></i>
            Back to login
          </a>
        </div>
      </div>
    </div>
    <!-- /Forgot Password -->
  </div>
</div>
