@if (viewRender) {
<div class="row">
    <div class="col-12">
        <list-filter [data]="[]" [metaData]="this.filterFormMetaData" (callback)="getlistData($event)"></list-filter>
    </div>
</div>
<div class="card mt-5">
    <div class="card-datatable table-responsive">
        <div class="dataTables_wrapper dt-bootstrap5 no-footer" id="DataTables_Table_0_wrapper">
            <div class="row mx-1">
                <div class="col-md-2 d-flex align-items-center justify-content-md-start justify-content-center ps-4">
                    <div class="dt-action-buttons mt-4 mt-md-0">
                        <div class="dt-buttons btn-group flex-wrap">
                            <div class="btn-group">
                                <button aria-controls="DataTables_Table_0" aria-expanded="false" aria-haspopup="dialog"
                                    class="btn btn-secondary buttons-collection dropdown-toggle btn-outline-secondary me-4 waves-effect waves-light"
                                    tabindex="0" type="button">
                                    <span><i class="ri-download-line ri-16px me-1"></i>
                                        <span class="d-none d-sm-inline-block">Export</span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="d-flex align-items-center justify-content-md-end justify-content-center">
                        <div class="me-4">
                            <div class="dataTables_filter" id="DataTables_Table_0_filter">
                                <label>
                                    <input aria-controls="DataTables_Table_0" class="form-control form-control-sm"
                                        placeholder="Search User" type="search" />
                                </label>
                            </div>
                        </div>
                        <div class="add-new">
                            <button (click)="createDynamicForm('add')" class="btn btn-primary waves-effect waves-light"
                                data-bs-target="#offcanvasAddUser" data-bs-toggle="offcanvas">
                                <i class="ri-add-line me-0 me-sm-1 d-inline-block d-sm-none"></i><span
                                    class="d-none d-sm-inline-block">
                                    Add New  </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            @if(listData){
                <dynamic-table [tableData]="listData" [url]="metaData?.datasource" [loading]="loading" (callback)="editRecord($event)"></dynamic-table>
            }
            
        </div>
    </div>

    <!-- Offcanvas to add new user -->
    @if (OffCanvasShow) {
    <div class="offcanvas offcanvas-end show" tabindex="-1" id="offcanvasAddUser" data-bs-backdrop="false"
        [style]="width" aria-labelledby="offcanvasAddUserLabel" aria-modal="true" role="dialog">
        <!-- <app-create-dynamic-form (callback)="closeOffCanvas($event)" [data]="data"></app-create-dynamic-form> -->
        <!-- <dynamic-group-list></dynamic-group-list> -->
        <div class="offcanvas-header border-bottom">
            <h5 class="offcanvas-title" id="offcanvasAddUser">
              <!-- <ng-container *ngIf="!formGroup?.get('key')?.value">
                Add User
              </ng-container>
              <ng-container *ngIf="formGroup.get('key')?.value">
                Update User
              </ng-container> -->
            </h5>
            <button (click)="closeOffCanvas()" aria-label="Close" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                    type="button"></button>
          </div>
          <div class="offcanvas-body mx-0 flex-grow-0 h-100">
         <dynamic-form-group [data]="data" (callback)="closeOffCanvas()"></dynamic-form-group>
         </div>

    </div>
    <div class="offcanvas-backdrop fade show"></div>
    }

</div>
}