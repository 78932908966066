import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {RecaptchaModule} from "ng-recaptcha-2";
import {Control} from "../../../interface/login.model";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'mns-recaptcha',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, RecaptchaModule],
  templateUrl: './recaptcha.component.html',
  styleUrl: './recaptcha.component.scss',
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class RecaptchaComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('captchaRef') captchaRef: any;
  @Input() control: Control | undefined;
  captchaResponse: string | null = null;
  public siteKey: string = '6LfQK2QqAAAAAGFK8zeB9peygwpGkvpCPUcrPu8l';
  private secretKey: string = '6LfQK2QqAAAAADd8kcueAsghtpiMnVmK_CkIto-F';

  constructor(private http: HttpClient, private controlContainer: ControlContainer, private cdr: ChangeDetectorRef) {
  }

  get formGroup() {
    return this.controlContainer.control as FormGroup;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  onCaptchaResolved(captchaResponse: string | null): void {
    this.captchaResponse = captchaResponse;
    if (this.control) {
      this.formGroup.get(this.control?.name)?.patchValue(captchaResponse);
      this.formGroup.get(this.control.name)?.markAsTouched();
      this.formGroup.get(this.control.name)?.markAsDirty();
    }
    if (captchaResponse && this.control) {
      // this.verifyToken(captchaResponse);
    }
  }

  resetCaptcha() {
    if (this.captchaRef) {
      this.captchaRef.reset();
    }

    if (this.control) {
      const control = this.formGroup.get(this.control.name);
      control?.setValue('');
      control?.markAsPristine();
      control?.markAsUntouched();
      control?.updateValueAndValidity();
    }
    this.cdr.detectChanges();
  }

  private verifyToken(token: string): any {
    const url = `https://www.google.com/recaptcha/api/siteverify`;
    this.http.post(url, null, {
      params: {
        secret: this.secretKey,
        response: token
      },
    }).subscribe({
      next: (response: any) => {
        console.log('response', response)
      },
      error: err => {
        console.log('error', err);
      },
      complete: () => {
        console.log('success');
      }
    })
  }
}
