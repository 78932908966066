@if (verificationStatus === undefined && code) {
  <ngx-spinner [fullScreen]="true" bdColor="rgba(0,0,0,0.5)" color="#ffffff" name="verifyEmail" size="default"
               type="ball-pulse" style="color:#fff">
    Verifying your email for account activation, please wait...
  </ngx-spinner>
} @else {
  <div class="position-relative">
    <div class="authentication-wrapper authentication-cover">
      <!-- Logo -->
      @if (isServiceFetched) {
        <a class="auth-cover-brand d-flex align-items-center gap-3" routerLink="/verify-email">
          <div class="app-brand-logo">
            <div style="color:var(--bs-primary);">
              <img [src]="serviceData?.logo" alt="LOGO" class="rounded-2 logo" height="50"
                   width="50">
            </div>
          </div>
          <span class="app-brand-text demo text-heading fw-semibold" *ngIf="false">{{ serviceData?.name }}</span>
        </a>
      }
      <!-- /Logo -->
      <div class="authentication-inner row m-0">
        <!-- /Left Section -->

        <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
          <div>
            <img
              [src]="serviceData?.cover?serviceData?.cover:'https://app.mynextsalon.com/assets/img/services/cover.png'"
              alt="auth-model" class="authentication-image-model d-none d-lg-block"
              data-app-dark-img="illustrations/auth-cover-login-illustration-dark.png"
              data-app-light-img="illustrations/auth-cover-login-illustration-light.png"
              height="700"
              style="object-fit: contain">
          </div>
        </div>
        <!-- /Left Section -->

        <!-- Reset Password -->
        <div
          class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-5 px-md-12 py-4">
          <div class="w-px-400 mx-auto pt-5 pt-lg-0">
            @if (verificationStatus) {
              <h4 class="mb-1">Email verified ✉️</h4>
              <p class="mb-5 alert alert-success alert-outline-success">
                Your email address has been successfully verified. You can now proceed to log in and access all features
                of your account.
              </p>
              <div class="px-5 justify-content-center d-flex">
                <button class="btn btn-primary" routerLink="/login">Proceed to Login</button>
              </div>
            } @else {
              <h4 class="mb-1">Verify your email ✉️</h4>
              @if (linkSendAgain) {
                <p class="mb-5 alert alert-success alert-outline-success">
                  Account activation has been sent to your email address : <u>{{ lastSentMailAddress }}</u>.
                </p>
              } @else if(verificationStatus===false){
                <p class="mb-5 alert alert-danger alert-outline-danger">
                  We regret that your email verification was unsuccessful. The verification link provided may be invalid, already used, or has expired.
                </p>

              }

              <form (ngSubmit)="sendNewVerificationLink()" [formGroup]="verifyAccountForm"
                    class="mb-5 fv-plugins-bootstrap5 fv-plugins-framework">
                @for (control of verifyAccountMetaData?.create_form?.controls; track control) {
                  @if (control.is_visible) {
                    <div class="mb-3">
                      @if (control.type === 'email') {
                        <text-email [error]="verifyAccountForm.get(control.name)"
                                    [formControlName]='control.name' [placeholder]='control.placeholder'
                                    [label]='control.label'>
                        </text-email>
                      } @else if (control.type === 'recaptchaV2') {
                        <mns-recaptcha [control]="control"></mns-recaptcha>
                      }
                    </div>
                  }
                }

                <button
                  [disabled]="verifyAccountForm?.invalid" type="submit"
                  class="btn btn-primary d-grid w-100 mb-5 waves-effect waves-light">
                  Send Verification Link
                </button>
                <div class="text-center">
                  <a class="d-flex align-items-center justify-content-center" routerLink="/login">
                    <i class="ri-arrow-left-s-line scaleX-n1-rtl ri-20px me-1_5"></i>
                    Back to login
                  </a>
                </div>
              </form>
            }
          </div>
        </div>
        <!-- /Reset Password -->
      </div>
    </div>
  </div>
}

