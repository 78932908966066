<div class="form-floating form-floating-outline">  
    <input [ngClass]="{'is-invalid':error?.invalid && (error?.dirty || error?.touched)}"
      class="form-control"
      type="email"
      [name]="'email'"
      [formControl]="control"
      [placeholder]="placeholder"
      (blur)="onTouched()"  
    />
    <label for="add-user-contact">{{label}}</label>
    <ng-container
    *ngIf="error?.invalid && (error?.dirty || error?.touched)">
    <div class="fv-plugins-message-container fv-pluins-message-container--enabled invalid-feedback">
      <ng-container *ngIf="error?.hasError('required')">
        {{label}} is required.
      </ng-container>
    </div>
  </ng-container>
</div>
