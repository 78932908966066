import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UsersService} from "../../services/users.service";
import {CommonModule, NgForOf} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";
import {MenuService} from "../../services/menu.service";
import {MicroPasswordComponent} from "../../mirco-components/micro-password/micro-password.component";
import {TableModule} from "primeng/table";
import {TableComponent} from "../../mirco-components/table/table.component";
import {MatDialog} from '@angular/material/dialog'
import {CreateDynamicFormComponent} from '../create-dynamic-form/create-dynamic-form.component';
import {HelperService} from '../../services/helper.service';
import {MicroCheckBoxComponent} from '../../mirco-components/micro-check-box/micro-check-box.component';
import {MicroDateComponent} from '../../mirco-components/micro-date/micro-date.component';
import {MicroMultiSelectComponent} from '../../mirco-components/micro-multi-select/micro-multi-select.component';
import {MicroSelectComponent} from '../../mirco-components/micro-select/micro-select.component';
import {MicroTextBoxComponent} from '../../mirco-components/micro-text-box/micro-text-box.component';
import {MicroTextareaComponent} from '../../mirco-components/micro-textarea/micro-textarea.component';
import {MicroToggleButtonComponent} from '../../mirco-components/micro-toggle-button/micro-toggle-button.component';
import {TextEmailComponent} from '../../mirco-components/text-email/text-email.component';
import {httpService} from '../../services/http.service';
import { ListFilterComponent } from "../list-filter/list-filter.component";

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxSpinnerComponent, MicroPasswordComponent, TableModule, TableComponent, MicroCheckBoxComponent, MicroPasswordComponent, MicroTextBoxComponent, ReactiveFormsModule, MicroDateComponent, MicroSelectComponent, MicroMultiSelectComponent, MicroToggleButtonComponent, MicroTextareaComponent, NgForOf, TextEmailComponent, CreateDynamicFormComponent, ListFilterComponent],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit, AfterViewInit {
  accessToken = localStorage.getItem('access') || '';
  public usersList: any[] = [];
  public loading: boolean = true;
  public filterForm!: FormGroup;
  public verifyMenuData: any;
  @ViewChild('closeButton') closeButton!: ElementRef;
  @ViewChild('addUserButton') addUserButton!: ElementRef;
  filterFormMetaData: any;
  isRenderFilter: boolean = false;
  data: any;
  OffCanvasShow: boolean = false
  offcanvasInstance: any;

  constructor(private userService: UsersService, private fb: FormBuilder, private spinner: NgxSpinnerService, private menuService: MenuService, 
    private dialog: MatDialog, private helperService: HelperService, private changeDetectorRef: ChangeDetectorRef, private http: httpService,) {
    this.filterForm = this.fb.group({})
  }

  ngOnInit() {
    this.getVerifyMenu();
  }

  createDynamicForm(actionType: string = 'add', rowData: any = null) {
    if (this.dialog?.openDialogs?.length == 0) {
      console.log('metadata', this.verifyMenuData?.create_form)
      let formMetaData = actionType == 'add' ? this.verifyMenuData?.create_form : this.verifyMenuData?.update_form;
      formMetaData['dataSource'] = this.verifyMenuData?.datasource
      this.data = {
        formMetaData: formMetaData, rowData: rowData
      }
      this.OffCanvasShow = true;
      // const dialogRef = this.dialog.open(CreateDynamicFormComponent, {
      //     data: {
      //       formMetaData: formMetaData,
      //       rowData: rowData
      //     }
      // },)

      // dialogRef?.afterClosed()?.subscribe((result: any) => {
      //   if (result) {
      //     console.log('Off-canvas closed with result:', result);
      //     this.fetchUsers();
      //   } else {
      //     console.log('Off-canvas closed without result');
      //   }
      // });

      this.changeDetectorRef.detectChanges();
    }

  }

  closeOffCanvas(data: any) {
    this.OffCanvasShow = false;
    if (data) {
      this.fetchUsers();
    }

  }

  editRecord(data: any = undefined) {
    console.log('edit form data', data)
    this.createDynamicForm('edit', data);
  }


  ngAfterViewInit() {
  }

  getVerifyMenu() {
    this.menuService.verifyMenu(this.accessToken).subscribe({
      next: (response: any) => {
        console.log('response:', response);
        this.verifyMenuData = response?.data;
        this.filterFormMetaData = this.verifyMenuData?.filter_form;
        this.filterFormMetaData['datasource']=this.verifyMenuData?.datasource;
        this.filterForm = this.helperService.initiateForm(this.filterFormMetaData?.controls);
        this.isRenderFilter = true;
        this.fetchUsers()
      }, error: (error) => {
        console.log('Something went wrong')
      }
    })
  }

  filters() {
    this.http.get(this.helperService.createQueryString(this.verifyMenuData?.datasource, this.filterForm.value)).subscribe(res => {
      console.log('filter', res);
      this.usersList = res?.data;
    }, error => {
      console.error('Error:', error);
    });

  }

  frmResets() {
    this.filterForm.reset();
    this.fetchUsers()
  }

  fetchUsers() {
    this.loading = true;
    let url = this.verifyMenuData.datasource;
    this.userService.getUsers(this.accessToken, url).subscribe({
      next: (response: any) => {
        this.usersList = response.data;
        this.loading = false;
      }, error: err => {
        console.log('hello', err)
        this.loading = false
      }
    })
  }
  listData(data:any){
    this.usersList=data;
  }

}
