<div class="position-relative">
  <div class="authentication-wrapper authentication-cover">
    <!-- Logo -->
    @if (isServiceFetched) {
      <a class="auth-cover-brand d-flex align-items-center gap-3" routerLink="/login">
        <div class="app-brand-logo">
          <div style="color:var(--bs-primary);">
            <img [src]="serviceData?.logo" alt="LOGO" class="rounded-2 logo" height="50"
                 width="50">
          </div>
        </div>
        <span class="app-brand-text demo text-heading fw-semibold" *ngIf="false">{{ serviceData?.name }}</span>
      </a>
    }
    <!-- /Logo -->
    <div class="authentication-inner row m-0">

      <!-- /Left Section -->

      <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
        <div>
          <img [src]="serviceData?.cover?serviceData?.cover:'https://app.mynextsalon.com/assets/img/services/cover.png'"
               alt="auth-model" class="authentication-image-model d-none d-lg-block"
               data-app-dark-img="illustrations/auth-cover-login-illustration-dark.png"
               data-app-light-img="illustrations/auth-cover-login-illustration-light.png"
               height="700"
               style="object-fit: contain">
        </div>
      </div>
      <!-- /Left Section -->

      <!-- Reset Password -->
      <div
        class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-5 px-md-12 py-4">
        <div class="w-px-400 mx-auto pt-5 pt-lg-0">
          <h4 class="mb-1">Reset Password 🔒</h4>
          <p class="mb-5">Your new password must be different from previously used passwords</p>
          <form (ngSubmit)="handleCreateNewPassword()" [formGroup]="resetPasswordForm"
                class="mb-5 fv-plugins-bootstrap5 fv-plugins-framework">
            @for (control of resetPasswordMetaData?.create_form?.controls; track control) {
              @if (control.is_visible) {
                <div class="mb-3">
                  @if (control.type === 'password') {
                    <micro-password [error]="resetPasswordForm.get(control.name)"
                                    [formControlName]='control.name' [placeholder]='control.placeholder'
                                    [label]='control.label'>
                    </micro-password>
                  } @else if (control.type === 'recaptchaV2') {
                    <mns-recaptcha [control]="control"></mns-recaptcha>
                  }
                </div>
              }
            }
            @if (!resetPasswordForm?.get('new_password')?.pristine && !resetPasswordForm?.get('confirm_password')?.pristine) {
              @if (resetPasswordForm?.get('new_password')?.value !== resetPasswordForm?.get('confirm_password')?.value) {
                <div
                  class="fv-plugins-message-container fv-pluins-message-container--enabled text-danger invalid-feedback mb-2">
                  New Password and Cofirm password does not match
                </div>
              }
            }
            <button
              [disabled]="resetPasswordForm?.invalid || resetPasswordForm?.get('new_password')?.value !== resetPasswordForm?.get('confirm_password')?.value"
              class="btn btn-primary d-grid w-100 mb-5 waves-effect waves-light">
              Set new password
            </button>
            <div class="text-center">
              <a class="d-flex align-items-center justify-content-center" routerLink="/login">
                <i class="ri-arrow-left-s-line scaleX-n1-rtl ri-20px me-1_5"></i>
                Back to login
              </a>
            </div>
          </form>
        </div>
      </div>
      <!-- /Reset Password -->
    </div>
  </div>
</div>
