@if (isRender) {
<div class="card shadow-none">
    <p-table (onPage)="pageChange($event)" [(selection)]="selectedProducts" [loading]="loading" [paginator]="true"
        [rowsPerPageOptions]="[1,10, 25, 50, 100]" [rows]="10" [scrollable]="true" [showCurrentPageReport]="true"
        [sortOrder]="-1" [tableStyle]="{ 'min-width': '80rem' }" [value]="tableData"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" dataKey="key"
        scrollHeight="80vh" sortField="name" sortMode="multiple" styleClass="">
        <ng-template pTemplate="header">
            <tr>
                <th pFrozenColumn>
                    <p-tableHeaderCheckbox />
                </th>
                @for (columnName of tableColumns; track $index) {
                <th pSortableColumn="{{columnName}}">
                    {{columnName}}
                    <p-sortIcon field="{{columnName}}" />
                </th>
                }
                <th [frozen]="true" alignFrozen="right" pFrozenColumn>Action</th>
            </tr>
        </ng-template>
        <ng-template let-row pTemplate="body">
            <tr>
                <td pFrozenColumn>
                    <p-tableCheckbox [value]="row" />
                </td>

                @for (name of tableColumns; track $index) {
                @if(name!='is_active' && name!='is_verified'){
                <td>{{ row?.[name] }}</td>
                }

                @if(name=='is_active'){
                <td>
                    @if (row.is_active) {
                    <span class="badge rounded-pill bg-label-success">Active</span>
                    }
                    @if (!row.is_active) {
                    <span class="badge rounded-pill bg-label-secondary">Inactive</span>
                    }
                </td>
                }
                @if(name=='is_verified'){
                <td>
                    {{ row.is_verified ? 'Yes' : 'No' }}
                </td>
                }
                }
                <td [frozen]="true" alignFrozen="right" pFrozenColumn>
                    <div class="d-flex align-items-center">
                        <a class="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect delete-record"
                            data-bs-toggle="tooltip" title="Delete Invoice">
                            <i class="ri-delete-bin-7-line ri-22px"></i>
                        </a>
                        <a class="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect"
                            data-bs-toggle="tooltip" title="Preview">
                            <i class="ri-eye-line ri-22px"></i>
                        </a>
                        <a class="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect"
                            data-bs-toggle="tooltip" title="Edit" (click)="openEditPopup(row)">
                            <i class="ri-edit-box-line me-2"></i>
                        </a>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
}