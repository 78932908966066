<div class="offcanvas-header border-bottom">
  <h5 class="offcanvas-title" id="offcanvasAddUser">
    <ng-container *ngIf="!formGroup?.get('key')?.value">
      Add User
    </ng-container>
    <ng-container *ngIf="formGroup.get('key')?.value">
      Update User
    </ng-container>
  </h5>
  <button (click)="closeDialog()" aria-label="Close" class="btn-close text-reset" data-bs-dismiss="offcanvas"
          type="button"></button>
</div>
<div class="offcanvas-body mx-0 flex-grow-0 h-100">

  <form *ngIf="viewRender" [formGroup]="formGroup">
    <div class="row">
      <div class="col-12">
        <div *ngFor=" let control of  data?.formMetaData?.controls" class="col-xl-12 col-md-6 col-sm-12 mb-6">
          @if (control?.is_visible) {
            @if (control?.type == 'text') {
              <micro-text-box [error]="formGroup.get(control?.name)"
                              formControlName='{{control?.name}}'
                              placeholder='{{control?.placeholder}}' label='{{control?.label}}'>
              </micro-text-box>
            } @else if (control.type === 'number') {
              <micro-text-box [error]="formGroup.get(control?.name)"
                              [type]="control.type"
                              formControlName='{{control?.name}}'
                              placeholder='{{control?.placeholder}}' label='{{control?.label}}'>
              </micro-text-box>
            } @else if (control?.type == 'checkbox') {
              <micro-check-box [error]="formGroup.get(control?.name)"
                               formControlName='{{control?.name}}' type="checkbox"
                               placeholder='{{control?.placeholder}}'
                               label='{{control?.label}}'>
              </micro-check-box>
            } @else if (control.type === 'datetimepicker') {
              <micro-datetimepicker [error]="formGroup.get(control?.name)"
                                    formControlName='{{control?.name}}' type="checkbox"
                                    placeholder='{{control?.placeholder}}'
                                    label='{{control?.label}}'>
              </micro-datetimepicker>
            } @else if (control?.type == 'datepicker') {
              <micro-date [error]="formGroup.get(control?.name)"
                          formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
                          label='{{control?.label}}'>
              </micro-date>
            } @else if (control?.type == 'email') {
              <text-email [error]="formGroup.get(control?.name)"
                          formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
                          label='{{control?.label}}'>
              </text-email>
            } @else if (control?.type == 'select') {
              <micro-select [dataHoldingKey]="control?.name"
                            [dataSource]="control?.datasource"
                            [error]="formGroup.get(control?.name)" formControlName='{{control?.name}}' type="checkbox"
                            placeholder='{{control?.placeholder}}' label='{{control?.label}}'>
              </micro-select>
            } @else if (control?.type == 'toggle') {
              <micro-toggle-button [error]="formGroup.get(control?.name)"
                                   formControlName='{{control?.name}}' type="checkbox"
                                   placeholder='{{control?.placeholder}}'
                                   label='{{control?.label}}'>
              </micro-toggle-button>
            } @else if (control?.type == 'textarea') {
              <micro-textarea [error]="formGroup.get(control?.name)"
                              formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
                              label='{{control?.label}}'>
              </micro-textarea>
            } @else if (control?.type == 'password') {

              <micro-password [error]="formGroup.get(control?.name)"
                              formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
                              label='{{control?.label}}'>
              </micro-password>
            }




            <!--  <micro-multi-select *ngIf="control?.type=='checkbox'" [error]="formGroup.get('Language')" formControlName="Language" type="text"-->
              <!--  placeholder="select" label="Language">-->
              <!--  </micro-multi-select>-->


          }
        </div>
      </div>
    </div>


    <button (click)="submit()" [disabled]="!formGroup.valid"
            class="btn btn-primary me-sm-3 me-1 data-submit waves-effect waves-light" type="submit">Submit
    </button>
    <button (click)="closeDialog()" class="btn btn-outline-danger waves-effect" data-bs-dismiss="offcanvas"
            type="reset">Cancel
    </button>
    <br>
  </form>
</div>


