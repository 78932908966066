import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MicroTextBoxComponent } from "../../mirco-components/micro-text-box/micro-text-box.component";
import { MicroCheckBoxComponent } from "../../mirco-components/micro-check-box/micro-check-box.component";
import { TextEmailComponent } from "../../mirco-components/text-email/text-email.component";
import { MicroSelectComponent } from "../../mirco-components/micro-select/micro-select.component";
import { MicroToggleButtonComponent } from "../../mirco-components/micro-toggle-button/micro-toggle-button.component";
import { MicroTextareaComponent } from "../../mirco-components/micro-textarea/micro-textarea.component";
import { MicroPasswordComponent } from "../../mirco-components/micro-password/micro-password.component";
import { NgForOf, NgIf } from '@angular/common';
import {DatetimepickerComponent} from '../../mirco-components/datetimepicker/datetimepicker.component'
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'dynamic-group-list',
  standalone: true,
  imports: [NgIf, NgForOf, ReactiveFormsModule, MicroTextBoxComponent, MicroCheckBoxComponent,DatetimepickerComponent, TextEmailComponent, MicroSelectComponent, MicroToggleButtonComponent, MicroTextareaComponent, MicroPasswordComponent],
  templateUrl: './dynamic-group-list.component.html',
  styleUrl: './dynamic-group-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicGroupListComponent implements OnInit {
     @Input() metadata:any;
      accessToken = localStorage.getItem('access') || '';
      formGroup: FormGroup = new FormGroup({});
  constructor(private fb: FormBuilder, private menuService: MenuService,) { 
    this.getVerifyMenu();
  }
  ngOnInit() {
    console.log('Create_dynamic_form', this.formMetaData?.controls)
    this.createNativeForm();
  }
  setFormData() {
    this.formGroup.patchValue(this.response.data);
  }
  createNativeForm() {
    this.formGroup = this.fb.group({
    });
    let frm: any = this.initiateForm(this.formMetaData?.controls, this.response.data);
    this.formGroup = frm;
    this.setFormData();
  }
  click(value:any){
    
    console.log('click',value)
  }
  // Callback method for value changes
  onTextboxValueChange(value: string): void {
    //this.customTextboxValue = value;  
    console.log('Value from custom textbox:', value);
  }
  onTextboxValueChanges = (value: string) => {
    //console.log(this.customTextboxValue);
  };
  trackArray: any = [];
  initiateForm(metaData: any, data: any = null) {
    let controlsObj: any = {};
    metaData?.forEach((ele: any) => {
      if (ele?.type === 'formarray') {
        if (data) {
          this.trackArray?.push({ formarray: ele?.name, controls: ele?.controls });
          data = data[ele?.name]
          data?.forEach((x: any) => {
            let frm: any = this.initiateForm(ele?.controls, x);
            if (controlsObj[ele?.name]) {
              controlsObj[ele?.name].push(frm);
            } else {
              controlsObj[ele?.name] = new FormArray([frm]);
            }

          });
        }
        else {
          let frm: any = this.initiateForm(ele?.controls);
          controlsObj[ele?.name] = new FormArray([frm]);
        }

        return;
      }
      controlsObj[ele?.name] = new FormControl(null)
    });
    return this.fb.group(controlsObj);
  }
  getFormArrayDataAsPerKey(keyArray: any[]) {
    let result
    keyArray.forEach((x: any) => {
      result = this.response?.[x]
    });
    return result != undefined ? result : []
  }
  getVerifyMenu() {
    this.menuService.verifyMenu(this.accessToken).subscribe({
      next: (response: any) => {
        console.log('inventory:', response);
        //this.verifyMenuData = response?.data;
       //this.filterFormMetaData = this.verifyMenuData?.filter_form;
       // this.filterForm = this.helperService.initiateForm(this.filterFormMetaData?.controls);
        //this.isRenderFilter = true;
       // this.fetchUsers()
      }, error: (error:any) => {
        console.log('Something went wrong')
      }
    })
  }
  submit() {
    debugger
    let submitData = this.formGroup.value;
    let child = this.formGroup.get('variants') as FormArray;
    let sub_child = this.formGroup.get('bundles') as FormArray;
  }
  // Get the parent FormArray
  getparents(key: any): any {
    return this.getFormArray(key)
  }

  getFormArray(arrayOfKeys: any): FormArray {
    let dd = this.formGroup?.get(arrayOfKeys) as FormArray;
    return this.formGroup?.get(arrayOfKeys) as FormArray;
  }
  // Get the children FormArray for a specific parent
  getChildren(parentIndex: number, parentkey: string, childKey: string): any {
    let form: FormArray = this.getFormArray(parentkey)
    form = this.getparents(parentkey)?.at(parentIndex)?.get(childKey) as FormArray;
    return form//this.getparents([]).at(parentIndex).get('children') as FormArray;
  }
  // Add Perants in formGroup
  addPerants(key: any, metaData: any) {
    (this.formGroup?.get(key) as FormArray).push(this.initiateForm(metaData));
  }
  // Add childs in formGroup
  addChild(parentIndex: number, parentkey: string, childKey: string, metaData: any) {
    ((this.formGroup?.get(parentkey) as FormArray)?.at(parentIndex)?.get(childKey) as FormArray)?.push(this.initiateForm(metaData));
  }
  // Remove Perants in formGroup
  removeParent(parent:string,parentIndex:number){
    (this.formGroup?.get(parent) as FormArray)?.removeAt(parentIndex);
  }
  // Remove childs form formGoup
  removechild(parent:string,child:string,parentIndex:number,childIndex:number){
    ((this.formGroup?.get(parent) as FormArray)?.at(parentIndex)?.get(child) as FormArray)?.removeAt(childIndex);
  }
  reset(){
    this.formGroup?.reset();
  }
  formMetaData: any = {
    "name": "service_create",
    "controls": [
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "category",
        "type": "select",
        "label": "Cateogry",
        "datasource": {
          "url": "https://salon.mynextsalon.com/api/lookup/category",
          "method": "GET",
          "value_key": "key",
          "display_key": "name"
        },
        "is_visible": true,
        "is_readonly": false,
        "is_required": true,
        "placeholder": "Select Category"
      },
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "gender",
        "type": "select",
        "label": "Gender",
        "datasource": {
          "url": "https://salon.mynextsalon.com/api/lookup/gender",
          "method": "GET",
          "value_key": "key",
          "display_key": "name"
        },
        "is_visible": true,
        "is_readonly": false,
        "is_required": false,
        "placeholder": "Select Gender"
      },
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "name",
        "type": "text",
        "label": "Product Name",
        "regex": "",
        "is_visible": true,
        "max_length": 255,
        "min_length": 1,
        "is_readonly": false,
        "is_required": true,
        "placeholder": "Enter Product Name"
      },
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "hsn_code",
        "type": "text",
        "label": "HSN Code",
        "regex": "",
        "is_visible": true,
        "max_length": 10,
        "min_length": 10,
        "is_readonly": false,
        "is_required": false,
        "placeholder": "Enter HSN Code"
      },
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "variants",
        "type": "formarray",
        "is_visible": true,
        "label": "Prices",
        "controls": [
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "sku",
            "type": "text",
            "label": "SKU",
            "regex": "",
            "is_visible": true,
            "max_length": 255,
            "min_length": 1,
            "is_readonly": false,
            "is_required": true,
            "placeholder": "Enter SKU"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "barcode",
            "type": "text",
            "label": "Barcode",
            "regex": "",
            "is_visible": true,
            "max_length": 255,
            "min_length": 1,
            "is_readonly": false,
            "is_required": false,
            "placeholder": "Enter Barcode"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "unit",
            "type": "select",
            "label": "Unit",
            "datasource": {
              "url": "https://salon.mynextsalon.com/api/lookup/unit",
              "method": "GET",
              "value_key": "key",
              "display_key": "name"
            },
            "is_visible": true,
            "is_readonly": false,
            "is_required": false,
            "placeholder": "Select Unit"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "price",
            "type": "integer",
            "label": "Price",
            "regex": "",
            "is_visible": true,
            "max_length": 100,
            "min_length": 1,
            "is_readonly": false,
            "is_required": true,
            "placeholder": "Enter Price"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "mrp",
            "type": "integer",
            "label": "MRP",
            "regex": "",
            "is_visible": true,
            "max_length": 100,
            "min_length": 1,
            "is_readonly": false,
            "is_required": true,
            "placeholder": "Enter MRP"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "tax_rate",
            "type": "integer",
            "label": "Tax Rate",
            "regex": "",
            "is_visible": true,
            "max_length": 100,
            "min_length": 1,
            "is_readonly": false,
            "is_required": true,
            "placeholder": "Tax Rate"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "is_tax_inclusive",
            "type": "swtich",
            "label": "Is Tax Inclusive",
            "regex": "",  
            "is_visible": true,
            "is_readonly": false,
            "is_required": true
          },
          {
            "div": "col-xl-4 col-md-4 col-sm-4",
            "name": "bundles",
            "type": "formarray",
            "is_visible": true,
            "label": "Included Services",
            "controls": [
              {
                "div": "col-xl-5 col-md-5 col-sm-5",
                "name": "included_variant",
                "type": "select",
                "label": "Select Product",
                "datasource": {
                  "url": "https://salon.mynextsalon.com/api/itemmaster/service/variant",
                  "method": "GET",
                  "value_key": "key",
                  "display_key": "name"
                },
                "is_visible": true,
                "is_readonly": false,
                "is_required": true,
                "placeholder": "Select Service"
              },
              {
                "div": "col-xl-5 col-md-5 col-sm-5",
                "name": "quantity",
                "type": "integer",
                "label": "Quantity",
                "regex": "",
                "is_visible": true,
                "max_length": 100,
                "min_length": 1,
                "is_readonly": false,
                "is_required": true,
                "placeholder": "Enter Quantity"
              }
            ],
            "add_button": true,
            "remove_button": true
          }
        ]
      }
    ]
  }
  response: any = {
    "msg_code": [],
    "data": {
      "key": "BLKK3GVN4XZPQYMKI5YJ",
      "category": "7F619ATPKFJBWKL35M7T",
      "gender": "RD312AZBQI82AHTKIZM5",
      "name": "Hair Cut & Beard Trim",
      "description": "this is desciption",
      "hsn_code": "1236",
      "variants": [
        {
          "key": "V3PYXYC7UXDZKF5M16A3",
          "sku": "SKU003",
          "barcode": "BARCODE003",
          "unit": "48UZRCK1XRXVMHRXDXE3",
          "price": 249,
          "mrp": 449,
          "tax_rate": 18,
          "is_tax_inclusive": false,
          "duration": 45,
          "bundles": [
            {
              "key": "PLDBJQIEL4QAJXZ3ZH5D",
              "included_variant": "HVOESYBDLRWKAL7FCBM3",
              "quantity": 1
            },
            {
              "key": "9CJ2SR5DQBR31FZEOFJB",
              "included_variant": "RC9HCS6GRZMQJYN9E3A3",
              "quantity": 1
            }
          ]
        }, {
          "key": "V3PYXYC7UXDZKF5M16A3",
          "sku": "SKU003",
          "barcode": "BARCODE003",
          "unit": "48UZRCK1XRXVMHRXDXE3",
          "price": 249,
          "mrp": 449,
          "tax_rate": 18,
          "is_tax_inclusive": false,
          "duration": 45,
          "bundles": [
            {
              "key": "PLDBJQIEL4QAJXZ3ZH5D",
              "included_variant": "HVOESYBDLRWKAL7FCBM3",
              "quantity": 1
            },
            {
              "key": "9CJ2SR5DQBR31FZEOFJB",
              "included_variant": "RC9HCS6GRZMQJYN9E3A3",
              "quantity": 1
            }
          ]
        }
      ]
    },
    "r_id": "ff9c0df6-a4ba-11ef-9553-0242ac170002",
    "status": 200
  }
  
}
