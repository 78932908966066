import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Inject, input, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { HelperService } from "../../services/helper.service";
import { JsonPipe, NgForOf, NgIf } from "@angular/common";
import { MicroCheckBoxComponent } from "../../mirco-components/micro-check-box/micro-check-box.component";
import { MicroPasswordComponent } from "../../mirco-components/micro-password/micro-password.component";
import { MicroTextBoxComponent } from "../../mirco-components/micro-text-box/micro-text-box.component";
import { MicroDateComponent } from "../../mirco-components/micro-date/micro-date.component";
import { MicroSelectComponent } from "../../mirco-components/micro-select/micro-select.component";
import { MicroMultiSelectComponent } from "../../mirco-components/micro-multi-select/micro-multi-select.component";
import { MicroToggleButtonComponent } from "../../mirco-components/micro-toggle-button/micro-toggle-button.component";
import { MicroTextareaComponent } from "../../mirco-components/micro-textarea/micro-textarea.component";
import { httpService } from '../../services/http.service';
import { TextEmailComponent } from '../../mirco-components/text-email/text-email.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DatetimepickerComponent} from "../../mirco-components/datetimepicker/datetimepicker.component";

@Component({
  selector: 'app-create-dynamic-form',
  standalone: true,
  imports: [
    JsonPipe,
    MicroCheckBoxComponent,
    MicroPasswordComponent,
    MicroTextBoxComponent,
    ReactiveFormsModule,
    MicroDateComponent,
    MicroSelectComponent,
    MicroMultiSelectComponent,
    MicroToggleButtonComponent,
    MicroTextareaComponent,
    NgForOf,
    TextEmailComponent,
    NgIf,
    DatetimepickerComponent
  ],
  templateUrl: './create-dynamic-form.component.html',
  styleUrl: './create-dynamic-form.component.scss'
})
export class CreateDynamicFormComponent implements OnInit {
  formGroup: FormGroup = new FormGroup({})
  appData: any;
  @Input() data:any;
  @Output() callback:any=new EventEmitter<any>();
  constructor(private formBuilder: FormBuilder, private helperService: HelperService,
    private http: httpService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  viewRender: boolean = false;
  ngOnInit() {

    setTimeout(() => {
      console.log('Ref component data', this.data?.formMetaData)
      if (this.data?.formMetaData != undefined) {
        this.formGroup = this.helperService.initiateForm(this.data?.formMetaData?.controls);
        this.changeDetectorRef.detectChanges();
        this.viewRender = true;
        this.formGroup.patchValue(this.data?.rowData);
        this.formGroup.updateValueAndValidity();
        this.changeDetectorRef?.detectChanges();
      }
    }, 200);

  }

  submit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      let data: any = this.formGroup.value;
      if (data?.key == null) {
        this.http.post(this.data?.formMetaData?.dataSource, data).subscribe(result => {
          console.log(result);
          this.closeDialog(result)
        }, error => {
          console.error('Error:', error);
        });
      }
      else if (data?.key != null) {
        this.http.patch(this.data?.formMetaData?.dataSource, data).subscribe(result => {
          console.log(result);
          this.closeDialog(result)
        }, error => {
          console.error('Error:', error);
        });
      }

    } else {
      console.log('form is not valid');
    }

  }
  closeDialog(result: any = null) {

    console.log('closeDialog')
    this.formGroup.reset()
    this.callback.emit(result);
    // if (result) {
    //   this.dialogRef.close(result);
    // }
    // else {
    //   this.dialogRef.close();
    // }
  }
}

