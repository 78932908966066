<label class="switch">
    <input type="checkbox"  [formControl]="control" class="switch-input">
    <span class="switch-toggle-slider">
      <span class="switch-on">
        <i class="ri-check-line"></i>
      </span>
      <span class="switch-off">
        <i class="ri-close-line"></i>
      </span>
    </span>
    <span class="switch-label">{{label}}</span>
  </label>