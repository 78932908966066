<div class="form-floating form-floating-outline">
  <select [formControl]="control" [ngClass]="{'is-invalid':error?.invalid && (error?.dirty || error?.touched)}"
    class="form-select ng-pristine" (blur)="onTouched()">
    <option value="">{{placeholder}}</option>
    <option *ngFor="let option of options" [value]="option[dataSource?.value_key]"
      class="ng-star-inserted">{{option[dataSource?.display_key] | titlecase}}</option>
  </select>
  <label>{{label}}</label>
  <ng-container *ngIf="error?.invalid && (error?.dirty || error?.touched)">
    <div class="fv-plugins-message-container fv-pluins-message-container--enabled invalid-feedback">
      <ng-container *ngIf="error?.hasError('required')">
        {{label}} is required.
      </ng-container>
    </div>
  </ng-container>
<!-- {{options|json}} -->
</div>
