import { Component } from '@angular/core';

@Component({
  selector: 'app-maintenance',
  standalone: true,
  imports: [],
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss','../../../../assets/vendor/css/pages/page-misc.scss']
})
export class MaintenanceComponent {

}
