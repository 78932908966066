import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {EventClickArg} from '@fullcalendar/core'
import {FullCalendarComponent, FullCalendarModule} from "@fullcalendar/angular";
import {CalendarOptions} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {MicroCheckBoxComponent} from "../../../mirco-components/micro-check-box/micro-check-box.component";
import {MicroDateComponent} from "../../../mirco-components/micro-date/micro-date.component";
import {MicroSelectComponent} from "../../../mirco-components/micro-select/micro-select.component";
import {MicroTextBoxComponent} from "../../../mirco-components/micro-text-box/micro-text-box.component";
import {NgForOf, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {TextEmailComponent} from "../../../mirco-components/text-email/text-email.component";
import {UsersService} from "../../../services/users.service";
import {NgxSpinnerService} from "ngx-spinner";
import {MatDialog} from "@angular/material/dialog";
import {httpService} from "../../../services/http.service";
import {Control, MenuItem} from "../../../interface/login.model";
import {ValidatorsService} from "../../../services/validators.service";
import {CreateDynamicFormComponent} from "../../create-dynamic-form/create-dynamic-form.component";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-fullcalendar',
  standalone: true,
  imports: [FullCalendarModule, MicroCheckBoxComponent, MicroDateComponent, MicroSelectComponent, MicroTextBoxComponent, NgForOf, NgIf, ReactiveFormsModule, TextEmailComponent, CreateDynamicFormComponent],
  templateUrl: './fullcalendar.component.html',
  styleUrl: './fullcalendar.component.scss'
})
export class FullcalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  public filterForm: FormGroup;
  public filterFormMeta!: MenuItem;
  public filterControls = {
    "name": "appointment_filter",
    "controls": [{
      "div": "col-12 col-xs-12 col-md-2 col-lg-2",
      "name": "user_name",
      "type": "text",
      "label": "Name",
      "regex": "",
      "is_visible": true,
      "max_length": 255,
      "min_length": 1,
      "is_readonly": false,
      "is_required": false,
      "placeholder": "Enter Name"
    }, {
      "div": "col-12 col-xs-12 col-md-2 col-lg-2",
      "name": "user_email",
      "type": "email",
      "label": "Email",
      "regex": "",
      "is_visible": true,
      "max_length": 255,
      "min_length": 5,
      "is_readonly": false,
      "is_required": false,
      "placeholder": "Enter Email"
    }, {
      "div": "col-12 col-xs-12 col-md-2 col-lg-2",
      "name": "appointment_status",
      "type": "select",
      "label": "Appointment Status",
      "datasource": {
        "url": "https://authorization.mynextsalon.com/api/role",
        "method": "GET",
        "value_key": "key",
        "display_key": "name"
      },
      "regex": "",
      "is_visible": true,
      "is_readonly": false,
      "is_required": false,
      "placeholder": "Enter Appointment Status"
    }]
  }
  public createControls = {
    "name": "appointment_create",
    "controls": [{
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "name",
      "type": "text",
      "label": "Title",
      "regex": "",
      "is_visible": true,
      "max_length": 255,
      "min_length": 1,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Enter Title"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "start_date",
      "type": "datetimepicker",
      "label": "Start Date",
      "regex": "",
      "is_visible": true,
      "max_length": 255,
      "min_length": 5,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Enter Start Date"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "end_date",
      "type": "datetimepicker",
      "label": "End Date",
      "regex": "",
      "is_visible": true,
      "max_length": 255,
      "min_length": 5,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Enter End Date"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "phone",
      "type": "text",
      "label": "Phone",
      "regex": "",
      "is_visible": true,
      "max_length": 15,
      "min_length": 10,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Enter Phone"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "gender",
      "type": "select",
      "label": "Gender",
      "datasource": {
        "url": "https://authorization.mynextsalon.com/api/lookup?name=gender",
        "method": "GET",
        "value_key": "key",
        "display_key": "name"
      },
      "is_visible": true,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Select Gender"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "dob",
      "type": "number",
      "label": "Age",
      "is_visible": true,
      "is_readonly": false,
      "is_required": false,
      "placeholder": "Select Age"
    }]
  }
  public OffCanvasShow: boolean = false;
  public data: any;

  public calendarMode: string = '';
  private calendarModes: string[] = ['multiMonthYear', 'dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listMonth'];
  public calendarModeApplied: boolean = false;
 public calendarOptions: CalendarOptions = {};

  constructor(private userService: UsersService, private fb: FormBuilder, private spinner: NgxSpinnerService, private dialog: MatDialog,
              private http: httpService, private validatorService: ValidatorsService, private toastr: ToastrService) {
    this.filterForm = this.fb.group({})
  }

  ngOnInit() {
    this.setInitialCalendarOptions();
    this.createFilterForm();
  }

  ngAfterViewInit() {
    // this.scrollCurrentIntoView()
    // setInterval(() => {
    //   this.scrollCurrentIntoView()
    // }, 1000 * 60)
  }

  handleDragSelect(selectionInfo: any) {
    this.data = {
      formMetaData: this.createControls,
      rowData: {
        start_date: selectionInfo.startStr.split('+')[0],
        end_date: selectionInfo.endStr.split('+')[0],
      },
    }
    this.OffCanvasShow = true;
  }

  handleDateClick(arg: any) {
    this.OffCanvasShow = true;
    console.log('args', arg)
    this.data = {
      formMetaData: this.createControls,
      rowData: {
        name: 'I am date click not new range'
      }
    }
  }

  handleEventClick(info: EventClickArg) {
    info.jsEvent.preventDefault();
    const event = info.event;
    if (event.allDay) {
      this.data = {
        formMetaData: this.createControls,
        rowData: {
          name: event.title,
          start_date: event.startStr ? event.startStr + 'T00:00:00' : '',
          end_date: event.endStr ? event.endStr + 'T00:00:00' : '',
        }
      }
    } else {
      this.data = {
        formMetaData: this.createControls,
        rowData: {
          name: event.title,
          start_date: event.startStr.split('+')[0],
          end_date: event.endStr.split('+')[0],
        }
      }
    }
    this.OffCanvasShow = true;
  }

  createFilterForm() {
    this.filterControls?.controls?.forEach((control: Control) => {
      if (control.is_visible) {
        const validators = this.validatorService.getValidators(control);
        this.filterForm.addControl(
          control.name,
          this.fb.control('', validators)
        )
      }
    });
  }

  handleAppointmentFilter() {
    console.log('Submit', this.filterForm.value);
    this.filterForm.disable();
    setTimeout(() => {
      this.filterForm.enable();
    }, 2500)
  }

  closeOffCanvas(data: any) {
    this.OffCanvasShow = false;
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.unselect(); // Programmatically unselects any current selection
    if (data) {
      this.fetchAppointments();
    }
  }

  fetchAppointments() {
  }

  scrollCurrentIntoView(): void {
    (document.querySelector('.fc .fc-timegrid-now-indicator-arrow') as HTMLElement).scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  calendarModeChanged(info: any) {
    console.log('calendarModeChanged: ', info);
    localStorage.setItem('calendarMode', JSON.stringify(info.view.type));
  }

 private getCalendarMode() {
    const type = localStorage.getItem('calendarMode');
    if (type && this.calendarModes.includes(JSON.parse(type))) {
      return JSON.parse(type);
    }
    return  'timeGridDay'
  }
  setInitialCalendarOptions() {
    const savedViewMode = this.getCalendarMode();
    this.calendarOptions = {
      initialView: savedViewMode,
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin, listPlugin],
      weekends: true,
      selectable: true,
      selectMirror: true,
      unselectAuto: false,
      select: (selectionInfo: any) => this.handleDragSelect(selectionInfo),
      unselect: (selectionInfo: any) => {

      },
      datesSet: (info) => {
        console.log('datesSet', info)
        if (this.calendarMode !== info.view.type) {
          console.log('calendarMode changed for view type', info.view.type, this.calendarMode);
          this.calendarMode = info.view.type;
          this.calendarModeChanged(info);
        }
      },
      customButtons: {
        addAppointment: {
          text: 'Add Appointment',
          click: () => {
            this.OffCanvasShow = true;
            this.data = {
              formMetaData: this.createControls,
              rowData: {}
            }
          }
        }
      },
      headerToolbar: {
        left: "prev,next addAppointment",
        center:'title',
        right: this.calendarModes.join(','),
      },
      nowIndicator: true,
      buttonText: {
        year: 'Year',
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List'
      },
      views: {
        multiMonthFourMonth: {
          type: 'multiMonth',
          duration: {months: 12}
        }
      },
      businessHours: [
        {
          daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
          startTime: '07:00',
          endTime: '21:00'
        }
      ],
      dateClick: (arg) => this.handleDateClick(arg),
      eventClick: (info: EventClickArg) => this.handleEventClick(info),
      events: [
        {title: 'event 1', date: '2024-10-31'},
        {title: 'event 2', date: '2024-11-02'},
        {title: 'event 2.1', date: '2024-11-02'},
        {title: 'event 2.2', date: '2024-11-02'},
        {title: 'event 2.3', date: '2024-11-02'},
        {title: 'event 2.4', start: '2024-11-04T08:00:00', end: '2024-11-05T08:30:00', allDay: false},
        {title: 'event 3', date: '2024-11-03', durationEditable: true}
      ]
    };
    this.calendarModeApplied=true;
  }
}
