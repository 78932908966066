<div class="row">
    <div class="col-12">
      <div class="card">
        <h5 class="card-header border-bottom"> Filters</h5>
        <div class="card-body">
          <!-- <dynamic-form [data]="[]" [metaData]="filterFormMetaData" (callback)="filter($event)"></dynamic-form> -->
          <form [formGroup]="filterForm" *ngIf="viewRender" class="row mt-4">
            <div *ngFor=" let control of  metaData?.controls" class="col-xl-4 col-md-6 col-sm-12 mb-3">
  
              <micro-text-box *ngIf="control?.type=='text'" [error]="filterForm.get('name')"
                formControlName='{{control?.name}}' type="control?.type" placeholder='{{control?.placeholder}}'
                label='{{control?.label}}'>
              </micro-text-box>
  
              <micro-check-box *ngIf="control?.type=='checkbox'" [error]="filterForm.get(control?.name)"
                formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
                label='{{control?.label}}'>
              </micro-check-box>
  
              <micro-date *ngIf="control?.type=='datepicker'" [error]="filterForm.get(control?.name)"
                formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
                label='{{control?.label}}'>
              </micro-date>
  
              <text-email *ngIf="control?.type=='email'" [error]="filterForm.get(control?.name)"
                formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
                label='{{control?.label}}'>
              </text-email>
              <micro-select *ngIf="control?.type=='select'" [dataHoldingKey]="control?.name"
                [dataSource]="control?.datasource" [error]="filterForm.get(control?.name)"
                formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
                label='{{control?.label}}'>
              </micro-select>
            </div>
            <br>
          </form>
          <div class="row text-end">
            <div class="col-xl-1 col-md-4 col-sm-12 mb-3">
              <button type="submit" class="btn btn-primary me-sm-3 me-1 data-submit waves-effect waves-light"
                (click)="filter()">Search</button>
            </div>
            <div class="col-xl-1 col-md-4 col-sm-12 mb-3">
              <button type="reset" class="btn btn-outline-danger waves-effect" data-bs-dismiss="offcanvas"
                (click)="frmReset()">Reset</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  