<aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
  <div class="app-brand">
    <a class="app-brand-link" routerLink="/dashboard">
              <span class="app-brand-logo me-1">
                <span style="color: var(--bs-primary)">
                  <img [src]="serviceData?.logo" alt="" width="30" height="30"
                       class="rounded-2 logo">
                </span>
              </span>
      <span class="app-brand-text menu-text fw-semibold ms-2" *ngIf="false">True Invoice</span>
    </a>

    <a class="layout-menu-toggle menu-link text-large ms-auto" (click)="toggleLayoutFixed()">
      <i class="menu-toggle-icon d-xl-block align-middle"></i>
    </a>
  </div>

  <div class="menu-inner-shadow"></div>

  <ul class="menu-inner py-1" #layoutMenu>
    <ngx-spinner type="ball-pulse" [fullScreen]="false" name="sidemenu" size="default" bdColor = "rgba(0,0,0,0.5)">
    </ngx-spinner>
    <!-- Dashboards -->
    <li *ngFor="let menu of menuList; let MenuIndex = index;"
        class="menu-item"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
      <a class="menu-link" [routerLink]="getCorrectRoute(menu.route)">
        <i class="menu-icon" [ngClass]="menu.icon"></i>
        <div>{{menu.name}}</div>
      </a>
    </li>
    <li class="menu-header fw-medium mt-4">
      <span class="menu-header-text">Stores</span>
    </li>
    <li class="menu-item">
      <a class="menu-link" [routerLink]="'workspace'">
        <i class="menu-icon ri-store-line"></i>
        <div>Change Store</div>
      </a>
    </li>
  </ul>


</aside>
