import {Component, OnInit} from '@angular/core';
import {AsyncPipe, CommonModule} from "@angular/common";
import {ISelectStorePayload, IStore, StoreService} from "../../services/store.service";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {AuthService} from "../../services/auth.service";
import {ServicesService} from "../../services/services.service";
import {MenuService} from "../../services/menu.service";
import {ValidatorsService} from "../../services/validators.service";
import {Control, MenuItem} from "../../interface/login.model";

@Component({
  selector: 'app-workspace-selector',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxSpinnerModule, MatFormField, MatAutocompleteModule, MatSelectModule, AsyncPipe, MatLabel, MatInputModule],
  templateUrl: './work-space-selector.component.html',
  styleUrl: './work-space-selector.component.scss'
})
export class WorkSpaceSelector implements OnInit {
  public workspaceList: IStore[] = [];
  public loading: boolean = true;
  public accessToken: string = '';
  public workspaceForm: FormGroup;
  public workspaceMetadata!: MenuItem;

  constructor(private storeService: StoreService,
              private fb: FormBuilder,
              private router: Router,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private services: ServicesService,
              private menuService: MenuService,
              private validatorService: ValidatorsService
  ) {
    this.workspaceForm = this.fb.group({})
  }

  ngOnInit() {
    this.accessToken = String(window.localStorage.getItem('access'));
    this.verifyPublicMenu();
  }

  fetchWorkspaceList() {
    this.loading = true;
    this.spinner.show();
    const url: string = this.workspaceMetadata?.datasource;
    this.authService.getWorkspace(this.accessToken, url).subscribe({
      next: (response: any) => {
        if (response?.data?.length === 1) {
          const storeControl = this.workspaceForm.get('key');
          storeControl?.patchValue(response?.data?.[0]?.key);
          storeControl?.updateValueAndValidity();
          // this.selectWorkSpace();
        }
        this.workspaceList = response?.data;
        this.loading = false;
        this.spinner.hide();
      }, error: (error) => {
        this.loading = false;
        this.spinner.hide();
        console.log('Error:', error);
      }
    })
  }

  selectWorkSpace() {
    const payload: ISelectStorePayload = this.workspaceForm.value
    this.spinner.show();
    const url: string = this.workspaceMetadata?.datasource;
    this.authService.selectWorkspace(this.accessToken, payload,url).subscribe({
      next: (response: any) => {
        console.log('data', response);
        localStorage.setItem('access', response?.data?.access_token)
        localStorage.setItem('refresh', response?.data?.refresh_token)
        this.spinner.hide();
        this.router.navigate(['/dashboard'])
      }, error: (error) => {
        console.log('error', error)
        this.spinner.hide();
      }
    })
  }

  verifyPublicMenu() {
    this.spinner.show('forgetPasswordPage');
    this.menuService.verifyPublicMenu().subscribe({
      next: (response: any) => {
        console.log('response', response)
        this.workspaceMetadata = response?.data;
        this.spinner.hide('forgetPasswordPage');
        this.fetchWorkspaceList();
        this.createForgetForm();
      },
      error: (error) => {
        this.spinner.hide('forgetPasswordPage')
        console.log('error', error);
        this.createForgetForm();
      }
    })
  }

  createForgetForm() {
    this.workspaceMetadata?.create_form?.controls?.forEach((control: Control) => {
      const validators = this.validatorService.getValidators(control);
      let controlValue: string | null = '';
      this.workspaceForm.addControl(
        control.name,
        this.fb.control(controlValue, validators)
      )
    })
  }

}

