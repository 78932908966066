<div class="card app-calendar-wrapper">
  <h5 class="card-header border-bottom"> Filters</h5>
  <div class="card-body">
    <form (ngSubmit)="handleAppointmentFilter()" [formGroup]="filterForm" class="row mt-4">
      @if (appointmentMetaData?.filter_form?.controls?.length) {
        @for (control of appointmentMetaData?.filter_form?.controls; track control.name) {
        <div class="col-xl-4 col-md-6 col-sm-12 mb-3">
          @if (control.type === 'text') {
            <micro-text-box [error]="filterForm.get(control.name)"
                            formControlName='{{control.name}}'
                            placeholder='{{control?.placeholder}}'
                            label='{{control?.label}}'>
            </micro-text-box>
          } @else if (control.type === 'email') {
            <text-email [error]="filterForm.get(control.name)"
                        formControlName='{{control.name}}' placeholder='{{control.placeholder}}'
                        label='{{control.label}}'>
            </text-email>
          }  @else if (control.type === 'select') {
            <micro-select [dataHoldingKey]="control?.name"
                          [dataSource]="control.datasource"
                          [error]="filterForm.get(control.name)" formControlName='{{control?.name}}' type="checkbox"
                          placeholder='{{control?.placeholder}}' label='{{control?.label}}'>
            </micro-select>
          }
        </div>
        }
      }

      <div class="justify-content-end">
        <div class="d-flex justify-content-end gap-3 p-0">
          <button class="btn btn-primary data-submit waves-effect waves-light" type="submit">Filter</button>
          <button class="btn btn-outline-danger waves-effect" data-bs-dismiss="offcanvas" type="reset">Reset</button>
        </div>
      </div>
    </form>
  </div>
  <hr class="mt-0 mb-0">
  <div class="row g-0">
    <!-- Calendar & Modal -->
    <div class="col app-calendar-content">
      <div class="card shadow-none border-0 ">
        <div class="card-body">
          @if (calendarModeApplied){
          <full-calendar #calendar [options]="calendarOptions"></full-calendar>
          }
        </div>
      </div>
      <div class="app-overlay"></div>


      <div *ngIf="OffCanvasShow" aria-labelledby="offcanvasAddUserLabel" aria-modal="true" class="offcanvas offcanvas-end show"
           data-bs-backdrop="false"
           id="offcanvasAddUser" role="dialog" tabindex="-1">
        @if (data) {
          <app-create-dynamic-form
            (callback)="closeOffCanvas($event)"
            [data]="data"></app-create-dynamic-form>
        }
      </div>
      <div *ngIf="OffCanvasShow" class="offcanvas-backdrop fade show"></div>


      <!-- FullCalendar Offcanvas -->
      <div aria-labelledby="addEventSidebarLabel" class="offcanvas offcanvas-end event-sidebar" id="addEventSidebar"
           tabindex="-1">
        <div class="offcanvas-header border-bottom">
          <h5 class="offcanvas-title" id="addEventSidebarLabel">Add Event</h5>
          <button aria-label="Close" class="btn-close text-reset" data-bs-dismiss="offcanvas" type="button"></button>
        </div>
        <div class="offcanvas-body">
          <form class="event-form pt-0 fv-plugins-bootstrap5 fv-plugins-framework" id="eventForm"
                novalidate="novalidate" onsubmit="return false">
            <div class="form-floating form-floating-outline mb-5 fv-plugins-icon-container">
              <input class="form-control" id="eventTitle" jf-ext-cache-id="8" name="eventTitle"
                     placeholder="Event Title"
                     type="text">
              <label for="eventTitle">Title</label>
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
            </div>
            <div class="form-floating form-floating-outline mb-5 form-floating-select2">
              <div class="position-relative"><select
                aria-hidden="true" class="select2 select-event-label form-select select2-hidden-accessible"
                data-select2-id="eventLabel" id="eventLabel" jf-ext-cache-id="36" name="eventLabel" tabindex="-1">
                <option data-label="primary" data-select2-id="2" selected="" value="Business">Business</option>
                <option data-label="danger" value="Personal">Personal</option>
                <option data-label="warning" value="Family">Family</option>
                <option data-label="success" value="Holiday">Holiday</option>
                <option data-label="info" value="ETC">ETC</option>
              </select><span class="select2 select2-container select2-container--default" data-select2-id="1" dir="ltr"
                             style="width: 360px;"><span class="selection"><span
                aria-disabled="false" aria-expanded="false" aria-haspopup="true"
                aria-labelledby="select2-eventLabel-container" class="select2-selection select2-selection--single"
                role="combobox" tabindex="0"><span
                aria-readonly="true" class="select2-selection__rendered" id="select2-eventLabel-container"
                role="textbox" title="Business"><span
                class="badge badge-dot bg-primary me-2"> </span>Business</span><span class="select2-selection__arrow"
                                                                                     role="presentation"><b
                role="presentation"></b></span></span></span><span aria-hidden="true"
                                                                   class="dropdown-wrapper"></span></span></div>
              <label for="eventLabel">Label</label>
            </div>
            <div class="form-floating form-floating-outline mb-5 fv-plugins-icon-container">
              <input class="form-control flatpickr-input" id="eventStartDate" jf-ext-cache-id="9" name="eventStartDate"
                     placeholder="Start Date" readonly="readonly" type="text">
              <label for="eventStartDate">Start Date</label>
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
            </div>
            <div class="form-floating form-floating-outline mb-5 fv-plugins-icon-container">
              <input class="form-control flatpickr-input" id="eventEndDate" jf-ext-cache-id="10" name="eventEndDate"
                     placeholder="End Date" readonly="readonly" type="text">
              <label for="eventEndDate">End Date</label>
              <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
            </div>
            <div class="mb-4">
              <div class="form-check form-switch">
                <input class="form-check-input allDay-switch" id="allDaySwitch" jf-ext-cache-id="11" type="checkbox">
                <label class="form-check-label" for="allDaySwitch">All Day</label>
              </div>
            </div>
            <div class="form-floating form-floating-outline mb-5">
              <input class="form-control" id="eventURL" jf-ext-cache-id="12" name="eventURL"
                     placeholder="https://www.google.com"
                     type="url">
              <label for="eventURL">Event URL</label>
            </div>
            <div class="form-floating form-floating-outline mb-5 select2-primary form-floating-select2">
              <div class="position-relative"><select
                aria-hidden="true" class="select2 select-event-guests form-select select2-hidden-accessible"
                data-select2-id="eventGuests" id="eventGuests" jf-ext-cache-id="37" multiple="" name="eventGuests"
                tabindex="-1">
                <option data-avatar="1.png" value="Jane Foster">Jane Foster</option>
                <option data-avatar="3.png" value="Donna Frank">Donna Frank</option>
                <option data-avatar="5.png" value="Gabrielle Robertson">Gabrielle Robertson</option>
                <option data-avatar="7.png" value="Lori Spears">Lori Spears</option>
                <option data-avatar="9.png" value="Sandy Vega">Sandy Vega</option>
                <option data-avatar="11.png" value="Cheryl May">Cheryl May</option>
              </select><span class="select2 select2-container select2-container--default" data-select2-id="3" dir="ltr"
                             style="width: 360px;"><span class="selection"><span
                aria-disabled="false" aria-expanded="false" aria-haspopup="true"
                class="select2-selection select2-selection--multiple" role="combobox" tabindex="-1"><ul
                class="select2-selection__rendered"><li
                class="select2-search select2-search--inline"><input aria-autocomplete="list" autocapitalize="none"
                                                                     autocomplete="off" autocorrect="off"
                                                                     class="select2-search__field"
                                                                     jf-ext-cache-id="13" placeholder="Select value"
                                                                     role="searchbox" spellcheck="false"
                                                                     style="width: 326px;" tabindex="0"
                                                                     type="search"></li></ul></span></span><span
                aria-hidden="true" class="dropdown-wrapper"></span></span></div>
              <label for="eventGuests">Add Guests</label>
            </div>
            <div class="form-floating form-floating-outline mb-5">
              <input class="form-control" id="eventLocation" jf-ext-cache-id="14" name="eventLocation"
                     placeholder="Enter Location" type="text">
              <label for="eventLocation">Location</label>
            </div>
            <div class="form-floating form-floating-outline mb-5">
              <textarea class="form-control" id="eventDescription" jf-ext-cache-id="38"
                        name="eventDescription"></textarea>
              <label for="eventDescription">Description</label>
            </div>
            <div class="mb-5 d-flex justify-content-sm-between justify-content-start my-6 gap-2">
              <div class="d-flex">
                <button class="btn btn-primary btn-add-event me-4 waves-effect waves-light" jf-ext-button-ct="add"
                        type="submit">Add
                </button>
                <button class="btn btn-outline-secondary btn-cancel me-sm-0 me-1 waves-effect"
                        data-bs-dismiss="offcanvas"
                        jf-ext-button-ct="cancel" type="reset">Cancel
                </button>
              </div>
              <button class="btn btn-outline-danger btn-delete-event d-none waves-effect" jf-ext-button-ct="delete">
                Delete
              </button>
            </div>
            <input type="hidden"></form>
        </div>
      </div>
    </div>
    <!-- /Calendar & Modal -->
  </div>
</div>
