@if (isRenderView) {
<!--Start tab -->
<div class="row">
    <div class="col">
        <!-- <h6 class="mt-4"> Form with Tabs </h6> -->
        <div class="card mb-6">
            <div class="card-header">
                <ul class="nav nav-tabs" role="tablist">
                    @if (tab) {
                    @for (item of tab; track $index) {
                    <li class="nav-item" role="presentation">
                        <button [class]="$index==0?'nav-link waves-effect active':'nav-link waves-effect'"
                            data-bs-toggle="tab" (click)="clickTab($index)" data-bs-target="#form-tabs-personal"
                            role="tab" aria-selected="true">
                            <span class="ri-user-line ri-20px d-sm-none"></span><span
                                class="d-none d-sm-block">{{item?.name}}</span></button>
                    </li>
                    }
                    }
                    <!-- <span class="tab-slider" style="left: 0px; width: 142.062px; bottom: 0px;"></span> -->
                </ul>
            </div>

            @if (tab?.length) {
            <div class="tab-content">
                @for (x of tab; track $index) {
                <!-- tab Info -->
                <div *ngIf="$index==activeTab" class="tab-pane fade active show" id="form-tabs-personal"
                    role="tabpanel">
                    <dynamic-list-with-filter [metaData]="x"></dynamic-list-with-filter>
                </div>
                }
            </div>
            }@else{
                <dynamic-list-with-filter [metaData]="this.metadata"></dynamic-list-with-filter>
            }
            
        </div>
    </div>
</div>
<!-- END TAB-->
}