import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {NotificationService} from "./notification.service";
import {BehaviorSubject, catchError, filter, Observable, tap, throwError} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  private baseUrl: string = 'https://authorization.mynextsalon.com';

  private serviceDataSubject = new BehaviorSubject<any>(null);
  public serviceData$: Observable<any> = this.serviceDataSubject.asObservable().pipe(
    filter(data => data !== null) // Only emit when data is available
  );


  constructor(private http: HttpClient, private notificationService: NotificationService) {
  }

  fetchServiceData(): void {
    console.log('Fetch Service Data');
    this.http.get<{ data: any }>(`${this.baseUrl}/api/service`).pipe(
      map(response => response.data),
      tap((data) => {
        this.serviceDataSubject.next(data);
        if (data?.favicon) {
          this.updateFavicon(data.favicon);
        }
        if (data?.name) {
          this.updateApplicationName(data.name);
        }
      }),
      catchError((err) => {
        this.notificationService.error(err?.error?.message || 'Server Error', 'Error');
        return throwError(err);
      })
    ).subscribe()
  }

  getService(){
    return new Promise<any>((resolve, reject) => {
      return this.http.get(this.baseUrl + '/api/service').subscribe({
        next: (response: any) => {
          resolve(response?.data);
          if(response?.data?.favicon) {
            this.updateFavicon(response?.data?.favicon);
            this.updateApplicationName(response?.data?.name);
          }
        },
        error: (err) => {
          reject(err);
          this.notificationService.error(err?.error?.message ? err?.error?.message : 'Server Error', "Error");
        }
      })

    })

  }

  private updateFavicon(faviconUrl: string): void {
    const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = faviconUrl;
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.type = 'image/x-icon';
      newLink.href = faviconUrl;
      document.head.appendChild(newLink);
    }
  }

  private updateApplicationName(name: string): void {
    const title: HTMLTitleElement | null = document.querySelector("head > title");
    if (title) {
      title.textContent = name;
    }
  }
}


