import {Injectable} from '@angular/core';
import {Validators} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() {
  }

  public getValidators(control: any): any[] {
    const validators: any[] = [];
    if (control.is_required) {
      validators.push(Validators.required);
    }
    if (control?.min_length) {
      validators.push(Validators.minLength(control.min_length))
    }
    if (control?.max_length) {
      validators.push(Validators.maxLength(control.max_length))
    }
    if (control?.regex) {
      const pattern = new RegExp(control?.regex);
      validators.push(Validators.pattern(pattern))
    }
    if (control?.type === 'email') {
      validators.push(Validators.email)
    }
    return validators;
  }
}
