import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ReactiveFormsModule, Validator
} from "@angular/forms";

import {CommonModule} from "@angular/common";

@Component({
  selector: 'micro-datetimepicker',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './datetimepicker.component.html',
  styleUrl: './datetimepicker.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimepickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatetimepickerComponent),
      multi: true
    }
  ]
})
export class DatetimepickerComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() error: any
  @Input() minuteStep: number=30;
  public innerValue: any = '';
  formGroup!: FormGroup;
  control: FormControl = new FormControl('');

  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {

  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChange(value);
    }
  }

  public onChange: (value: any) => void = () => {
  };

  public onTouched: () => void = () => {
  };

  ngOnInit(): void {
    this.control.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }

  triggerChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isError']) {
      this.error = changes['isError'].currentValue;
    }
  }

  writeValue(value: any): void {
    this.innerValue = value;
    this.control.setValue(value, {emitEvent: false});
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  validate(control: FormControl): { [key: string]: any } | null {
    return this.control.errors;
  }
}
