<div class="row g-6 mb-6">
  <div class="col-sm-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="me-1">
            <p class="text-heading mb-1">Session</p>
            <div class="d-flex align-items-center">
              <h4 class="mb-1 me-2">21,459</h4>
              <p class="text-success mb-1">(+29%)</p>
            </div>
            <small class="mb-0">Total Users</small>
          </div>
          <div class="avatar">
            <div class="avatar-initial bg-label-primary rounded">
              <div class="ri-group-line ri-26px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="me-1">
            <p class="text-heading mb-1">Paid Users</p>
            <div class="d-flex align-items-center">
              <h4 class="mb-1 me-2">4,567</h4>
              <p class="text-success mb-1">(+18%)</p>
            </div>
            <small class="mb-0">Last week analytics</small>
          </div>
          <div class="avatar">
            <div class="avatar-initial bg-label-danger rounded">
              <div class="ri-user-add-line ri-26px scaleX-n1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="me-1">
            <p class="text-heading mb-1">Active Users</p>
            <div class="d-flex align-items-center">
              <h4 class="mb-1 me-2">19,860</h4>
              <p class="text-danger mb-1">(-14%)</p>
            </div>
            <small class="mb-0">Last week analytics</small>
          </div>
          <div class="avatar">
            <div class="avatar-initial bg-label-success rounded">
              <div class="ri-user-follow-line ri-26px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="me-1">
            <p class="text-heading mb-1">Pending Users</p>
            <div class="d-flex align-items-center">
              <h4 class="mb-1 me-2">237</h4>
              <p class="text-success mb-1">(+42%)</p>
            </div>
            <small class="mb-0">Last week analytics</small>
          </div>
          <div class="avatar">
            <div class="avatar-initial bg-label-warning rounded">
              <div class="ri-user-search-line ri-26px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <h5 class="card-header border-bottom"> Filters</h5>
      <div class="card-body">
        <form [formGroup]="filterForm" *ngIf="isRenderFilter" class="row mt-4">
          <div *ngFor=" let control of  filterFormMetaData?.controls" class="col-xl-4 col-md-6 col-sm-12 mb-3">

            <micro-text-box *ngIf="control?.type=='text'" [error]="filterForm.get('name')"
              formControlName='{{control?.name}}' type="control?.type" placeholder='{{control?.placeholder}}'
              label='{{control?.label}}'>
            </micro-text-box>

            <micro-check-box *ngIf="control?.type=='checkbox'" [error]="filterForm.get(control?.name)"
              formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
              label='{{control?.label}}'>
            </micro-check-box>

            <micro-date *ngIf="control?.type=='datepicker'" [error]="filterForm.get(control?.name)"
              formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
              label='{{control?.label}}'>
            </micro-date>

            <text-email *ngIf="control?.type=='email'" [error]="filterForm.get(control?.name)"
              formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
              label='{{control?.label}}'>
            </text-email>
            <micro-select *ngIf="control?.type=='select'" [dataHoldingKey]="control?.name"
              [dataSource]="control?.datasource" [error]="filterForm.get(control?.name)"
              formControlName='{{control?.name}}' type="checkbox" placeholder='{{control?.placeholder}}'
              label='{{control?.label}}'>
            </micro-select>
          </div>

          <br>
        </form>
        <div class="row text-end">
          <div class="col-xl-1 col-md-4 col-sm-12 mb-3">
            <button type="submit" class="btn btn-primary me-sm-3 me-1 data-submit waves-effect waves-light"
              (click)="filter()">Search</button>
          </div>
          <div class="col-xl-1 col-md-4 col-sm-12 mb-3">
            <button type="reset" class="btn btn-outline-danger waves-effect" data-bs-dismiss="offcanvas"
              (click)="frmReset()">Reset</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card mt-5">
  <div class="card-datatable table-responsive">
    <div class="dataTables_wrapper dt-bootstrap5 no-footer" id="DataTables_Table_0_wrapper">
      <div class="row mx-1">
        <div class="col-md-2 d-flex align-items-center justify-content-md-start justify-content-center ps-4">
          <div class="dt-action-buttons mt-4 mt-md-0">
            <div class="dt-buttons btn-group flex-wrap">
              <div class="btn-group">
                <button aria-controls="DataTables_Table_0" aria-expanded="false" aria-haspopup="dialog"
                  class="btn btn-secondary buttons-collection dropdown-toggle btn-outline-secondary me-4 waves-effect waves-light"
                  tabindex="0" type="button">
                  <span><i class="ri-download-line ri-16px me-1"></i>
                    <span class="d-none d-sm-inline-block">Export</span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-10">
          <div class="d-flex align-items-center justify-content-md-end justify-content-center">
            <div class="me-4">
              <div class="dataTables_filter" id="DataTables_Table_0_filter">
                <label>
                  <input aria-controls="DataTables_Table_0" class="form-control form-control-sm"
                    placeholder="Search User" type="search" />
                </label>
              </div>
            </div>
            <div class="add-new">
              <button (click)="createDynamicForm('add')" class="btn btn-primary waves-effect waves-light"
                data-bs-target="#offcanvasAddUser" data-bs-toggle="offcanvas">
                <i class="ri-add-line me-0 me-sm-1 d-inline-block d-sm-none"></i><span class="d-none d-sm-inline-block">
                  Add New User </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <app-table [tableData]="usersList" [loading]="loading" (callback)="editRecord($event)"></app-table>
    </div>
  </div>

  <!-- Offcanvas to add new user -->
  <div *ngIf="OffCanvasShow" class="offcanvas offcanvas-end show" tabindex="-1" id="offcanvasAddUser" data-bs-backdrop="false"
    aria-labelledby="offcanvasAddUserLabel" aria-modal="true" role="dialog">
    <app-create-dynamic-form (callback)="closeOffCanvas($event)" [data]="data"></app-create-dynamic-form>
  </div>
  <div *ngIf="OffCanvasShow" class="offcanvas-backdrop fade show"></div>
</div>
