<div  class="form-floating form-floating-outline">
  <input  [ngClass]="{'is-invalid':error?.invalid && (error?.dirty || error?.touched)}"
          type="datetime-local" class="form-control ng-dirty ng-valid ng-touched"
          [formControl]="control"
          [placeholder]="placeholder" (blur)="onTouched()">
  <label>{{label}}@if (isRequired) {<span class="text-danger">*</span>}</label>
  <ng-container
    *ngIf="error?.invalid && (error?.dirty || error?.touched)">
    <div class="fv-plugins-message-container fv-pluins-message-container--enabled invalid-feedback">
      <ng-container *ngIf="error?.hasError('required')">
        {{label}} is required.
      </ng-container>
    </div>
  </ng-container>
</div>
