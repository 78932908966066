<ng-container type='checkbox'>
    <input [ngClass]="{'is-invalid':error?.invalid && (error?.dirty || error?.touched)}" type="checkbox" class="form-check-input" [formControl]="control" [placeholder]="placeholder"
        [checked]="value" (change)="onCheckboxChange($event)" (blur)="onTouched()" [id]="forId">
  <label [for]="forId" class="cursor-pointer user-select-none">{{label}}</label>
</ng-container>
<ng-container *ngIf="error?.invalid && (error?.dirty || error?.touched)">
    <div class="fv-plugins-message-container fv-pluins-message-container--enabled invalid-feedback">
        <ng-container *ngIf="error?.hasError('required')">
            {{label}} is required.
        </ng-container>
    </div>
</ng-container>
