import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Observable, startWith, map } from 'rxjs';

@Component({
  selector: 'micro-toggle-button',
  standalone: true,
  imports: [ReactiveFormsModule,CommonModule],
  templateUrl: './micro-toggle-button.component.html',
  styleUrl: './micro-toggle-button.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush ,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MicroToggleButtonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MicroToggleButtonComponent),
      multi: true
    }
  ],
})
export class MicroToggleButtonComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {
  @Input() placeholder: string = '';
  @Input() label:string='';
  @Input() error: any 
  public innerValue: any = '';
  public onChange: (value: any) => void = () => { };
  public onTouched: () => void = () => { };
  formGroup!: FormGroup;
  control: FormControl = new FormControl('');

  constructor(private fb: FormBuilder,private changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.control.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }
  triggerChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['isError']) {
      this.error = changes['isError'].currentValue;
    }
  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    this.innerValue = value;
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  validate(control: FormControl): { [key: string]: any } | null {
    return this.control.errors;
  }

}
