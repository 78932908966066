<div class="row fv-plugins-icon-container">
  <div class="col-md-12">
    <div class="nav-align-top">
      <ul class="nav nav-pills flex-column flex-md-row mb-6 gap-2 gap-lg-0">
        <li class="nav-item">
          <a class="nav-link active waves-effect waves-light" href="javascript:void(0);">
            <i class="ri-group-line me-1_5"></i>Account</a></li>
      </ul>
    </div>
    <div class="card mb-6">
      <!-- Account -->
      <div class="card-body">
        <div class="d-flex align-items-start align-items-sm-center gap-6">
          <img alt="user-avatar" class="d-block w-px-100 h-px-100 rounded" id="uploadedAvatar"
               src="../../assets/img/avatars/1.png">
          <div class="button-wrapper">
            <label class="btn btn-sm btn-primary me-3 mb-4 waves-effect waves-light" for="upload" tabindex="0">
              <span class="d-none d-sm-block">Upload new photo</span>
              <i class="ri-upload-2-line d-block d-sm-none"></i>
              <input accept="image/png, image/jpeg" class="account-file-input" hidden="" id="upload" type="file">
            </label>
            <button class="btn btn-sm btn-outline-danger account-image-reset mb-4 waves-effect" jf-ext-button-ct="reset"
                    type="button">
              <i class="ri-refresh-line d-block d-sm-none"></i>
              <span class="d-none d-sm-block">Reset</span>
            </button>

            <div>Allowed JPG, GIF or PNG. Max size of 800K</div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        @if (profileFormMetaData?.update_form?.controls?.length && isFormCreated){
          <form [formGroup]="profileForm" class="fv-plugins-bootstrap5 fv-plugins-framework" (ngSubmit)="handleProfileUpdate()">
            <div class="row mt-1">
              @for (control of profileFormMetaData?.update_form?.controls; track control.name) {
                @if (control.is_visible){
                  <div class="col-md-6 fv-plugins-icon-container mb-3">
                    @if (control.type === 'text') {
                      <micro-text-box [error]="profileForm.get(control.name)"
                                      [formControlName]='control.name'
                                      placeholder='{{control?.placeholder}}'
                                      label='{{control?.label}}'>
                      </micro-text-box>
                    } @else if (control.type === 'email') {
                      <text-email [error]="profileForm.get(control.name)"
                                  [formControlName]='control.name' placeholder='{{control.placeholder}}'
                                  label='{{control.label}}'>
                      </text-email>
                    } @else if (control.type === 'select') {
                      <micro-select [error]="profileForm.get(control.name)" [dataSource]="control.datasource"
                                    [formControlName]='control.name' [dataHoldingKey]="control?.datasource?.value_key"
                                    [placeholder]="control.placeholder"
                                    [label]='control.label'>
                      </micro-select>
                    } @else if (control.type === 'datepicker') {
                      <micro-date [error]="profileForm.get(control.name)"
                                      [formControlName]='control.name'
                                      [placeholder]="control.placeholder"
                                      [label]='control.label'>
                      </micro-date>
                    }
                  </div>
                }
              }
            </div>
            <div class="mt-1">
              <button class="btn btn-primary me-3 waves-effect waves-light" type="submit" [disabled]="updating ||profileForm.invalid">
                Save changes
              </button>
            </div>
          </form>
        }
      </div>
      <!-- /Account -->
    </div>
    <div class="card">
      <h5 class="card-header">Delete Account</h5>
      <div class="card-body">
        <form class="fv-plugins-bootstrap5 fv-plugins-framework" id="formAccountDeactivation" novalidate="novalidate"
              onsubmit="return false">
          <div class="form-check mb-6 ms-3">
            <input class="form-check-input" id="accountActivation" jf-ext-cache-id="11" name="accountActivation"
                   type="checkbox">
            <label class="form-check-label" for="accountActivation">I confirm my account deactivation</label>
            <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
          </div>
          <button class="btn btn-danger deactivate-account waves-effect waves-light" disabled="disabled"
                  jf-ext-button-ct="deactivate account"
                  type="submit">Deactivate Account
          </button>
          <input type="hidden"></form>
      </div>
    </div>
  </div>
</div>
