import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {StoreService} from "../../../services/store.service";
import {ServicesService} from "../../../services/services.service";
import {MenuService} from "../../../services/menu.service";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";
import {ValidatorsService} from "../../../services/validators.service";
import {Control, MenuItem} from "../../../interface/login.model";
import {TextEmailComponent} from "../../../mirco-components/text-email/text-email.component";
import {JsonPipe} from "@angular/common";
import {RecaptchaComponent} from "../../utils/recaptcha/recaptcha.component";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    RouterLink,
    TextEmailComponent,
    ReactiveFormsModule,
    JsonPipe,
    NgxSpinnerComponent,
    RecaptchaComponent
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit {
  public serviceData: any = {};
  public forgetPasswordForm: FormGroup;
  public forgetFormMetaData!: MenuItem;
  public isServiceFetched: boolean = false;
  public email: string | undefined;
  public isResetMailSent: boolean = false;
  public wrongEmail: boolean = false;
  public sendingEmail: boolean = false;

  @ViewChild(RecaptchaComponent) recaptchaComponent!: RecaptchaComponent; // Reference to the reCAPTCHA component


  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private storeService: StoreService,
              private services: ServicesService, private menuService: MenuService, private spinner: NgxSpinnerService,
              private validatorService: ValidatorsService, private cdr: ChangeDetectorRef,private notificationService: NotificationService,) {
    this.forgetPasswordForm = this.fb.group({});
  }

  ngOnInit() {
    this.authService.logout();
    this.getServices();
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { email: string };
    if (state) {
      this.email = state.email;
    }
    console.log('this email', navigation);
    this.verifyPublicMenu();
  }


  getServices() {
    this.services.serviceData$.subscribe(data => {
      this.serviceData = data;
    });
  }

  verifyPublicMenu() {
    this.spinner.show('forgetPasswordPage');
    this.menuService.verifyPublicMenu().subscribe({
      next: (response: any) => {
        console.log('response', response)
        this.forgetFormMetaData = response?.data;
        this.spinner.hide('forgetPasswordPage');
        this.createForgetForm();
      },
      error: (error) => {
        this.spinner.hide('forgetPasswordPage')
        console.log('error', error);
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }

  createForgetForm() {
    this.forgetFormMetaData?.create_form?.controls?.forEach((control: Control) => {
      if (control.is_visible) {
        const validators = this.validatorService.getValidators(control);
        this.forgetPasswordForm.addControl(
          control.name,
          this.fb.control('', validators)
        )
      }
    })
  }

  handleForgetSubmit() {
    this.isResetMailSent = false;
    this.sendingEmail = true;
    this.wrongEmail = false;
    this.spinner.show('forgetPasswordPage')
    const payload = this.forgetPasswordForm?.value
    this.forgetPasswordForm.disable();
    this.authService.sendResetLink(payload, this.forgetFormMetaData?.datasource).subscribe({
      next: (response: any) => {
        console.log('response', response)
        this.notificationService.success('Reset link sent successfully!')
        this.isResetMailSent = true;
        this.sendingEmail = false;
        this.forgetPasswordForm.enable();
        this.forgetPasswordForm.reset();
        this.forgetPasswordForm.markAsPristine();
        this.forgetPasswordForm.updateValueAndValidity();
        this.spinner.hide('forgetPasswordPage')
        this.recaptchaComponent.resetCaptcha();
      },
      error: (error) => {
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
        this.wrongEmail = true;
        this.sendingEmail = false;
        this.forgetPasswordForm.enable();
        console.log('error', error);
        this.spinner.hide('forgetPasswordPage')
      }
    })
  }
}
