import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {CommonModule} from "@angular/common";
import {StoreService} from "../../../services/store.service";
import {ServicesService} from "../../../services/services.service";
import {MenuService} from "../../../services/menu.service";
import {IVerifyMenu} from "../../../interface/menu.model";
import {MenuItem} from "../../../interface/login.model";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";
import {ValidatorsService} from "../../../services/validators.service";
import {TextEmailComponent} from "../../../mirco-components/text-email/text-email.component";
import {MicroPasswordComponent} from "../../../mirco-components/micro-password/micro-password.component";
import {MicroCheckBoxComponent} from "../../../mirco-components/micro-check-box/micro-check-box.component";
import {RecaptchaComponent} from "../../utils/recaptcha/recaptcha.component";
import {NotificationService} from "../../../services/notification.service";
import {J} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    NgxSpinnerComponent,
    RouterLink,
    ReactiveFormsModule,
    CommonModule,
    TextEmailComponent,
    MicroPasswordComponent,
    MicroCheckBoxComponent,
    RecaptchaComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  public serviceData: any = {};
  public loginForm: FormGroup;
  public loading: boolean = false;
  public passwordVisibility = {
    password: false,
  };
  public loginFormMetaData!: MenuItem;
  public isServiceFetched: boolean = false;
  public isFormCreated: boolean = false;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private storeService: StoreService,
              private services: ServicesService, private menuService: MenuService, private spinner: NgxSpinnerService,
              private validatorService: ValidatorsService, private cdr: ChangeDetectorRef,private notificationService: NotificationService) {
    this.loginForm = this.fb.group({});
  }

  ngOnInit() {
    this.authService.logout();
    this.verifyPublicMenu();
    this.services.serviceData$.subscribe(data => {
      this.serviceData = data;
    });
  }

  login() {
    this.loading = true;
    const payload = this.loginForm.getRawValue();
    const loginAPI = this.loginFormMetaData?.datasource;
    this.loginForm.disable();
    this.spinner.show('loginForm')
    this.authService.loginUser(payload, loginAPI).subscribe({
      next: (response) => {
        console.log('data', response)
        localStorage.setItem('access', response?.data?.access);
        localStorage.setItem('refresh', response?.data?.refresh);
        this.loginForm.enable();
        this.loading = false;
        this.spinner.hide('loginForm')
        this.router.navigate(['/workspace']);
      },
      error: (error) => {
        this.spinner.hide('loginForm')
        console.log('error', error);
        this.loginForm.enable();
        this.loading = false;
        this.notificationService.error(error?.error?.message?error?.error?.message:"Login Failed!");
      }
    })
  }


  verifyPublicMenu() {
    this.spinner.show('loginForm');
    this.menuService.verifyPublicMenu().subscribe({
      next: (response: any) => {
        console.log('response', response)
        this.loginFormMetaData = response?.data;
        this.spinner.hide('loginForm');
        this.createLoginForm();
      },
      error: (error) => {
        this.spinner.hide('loginForm')
        console.log('error', error);
      }
    })
  }

  createLoginForm() {
    this.loginFormMetaData?.create_form?.controls?.forEach((control: any) => {
      if (control.is_visible) {
        const validators = this.validatorService.getValidators(control);
        if(control.name==='rememberme' && control.is_required) {
          validators.push(Validators.requiredTrue);
        }
        this.loginForm.addControl(
          control.name,
          this.fb.control('', validators)
        )
      }
    });
    this.cdr.detectChanges();
  }


}
