import { Component } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { DynamicGroupListComponent } from "../dynamic-group-list/dynamic-group-list.component";
import { NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicListWithFilterComponent } from "../dynamic-list-with-filter/dynamic-list-with-filter.component";

@Component({
  selector: 'app-dynamic-component-layout',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, DynamicGroupListComponent, DynamicListWithFilterComponent],
  templateUrl: './dynamic-component-layout.component.html',
  styleUrl: './dynamic-component-layout.component.scss'
})
export class DynamicComponentLayoutComponent {
  accessToken = localStorage.getItem('access') || '';
  isRenderView: boolean = false;
  tab: any = [];
  metadata: any;
  constructor(private menuService: MenuService,) {
    this.getVerifyMenu();
  }
  getVerifyMenu() {
    this.menuService.verifyMenu(this.accessToken).subscribe({
      next: (response: any) => {
        debugger
        this.metadata = response?.data;
        this.tab = this.metadata?.['children'] ? this.metadata?.['children'] : []
        this.isRenderView = true;
        console.log('inventory:', response);

      }, error: (error: any) => {
        console.log('Something went wrong')
      }
    })
  }
  activeTab = 0;
  clickTab(tabIndex: number) {
    this.activeTab = tabIndex;
  }
}
