import {AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {CommonModule, DOCUMENT} from "@angular/common";
import PerfectScrollbar from "perfect-scrollbar";
import {Router, RouterLink} from "@angular/router";
import {MenuService} from "../../../services/menu.service";
import {NgxSpinnerService} from "ngx-spinner";
import {UsersService} from "../../../services/users.service";
import {MenuItem} from "../../../interface/login.model";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollable', {static: true}) scrollable!: ElementRef;
  public availableThemes: ThemeOption[] = [
    {dataTheme: 'light', displayName: 'Light'},
    {dataTheme: 'dark', displayName: 'Dark'},
    {dataTheme: 'system', displayName: 'System'},
  ];
  public selectedTheme: string = 'light';
  public layoutMenuClass = 'layout-menu-expanded';
  public menuList: any[] = [];
  public profileData: any;
  private accessToken = window.localStorage.getItem('access') || '';

  constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private document: Document, private router: Router,
              private menuService: MenuService, private spinner: NgxSpinnerService, private userService: UsersService,private authService: AuthService,) {
  }

  ngOnInit() {
    try {
      const storedTheme = localStorage.getItem('storedTheme');
      if (storedTheme && this.availableThemes.some(theme => theme.dataTheme === storedTheme)) {
        this.selectedTheme = storedTheme;
      }
    } catch (error) {
      console.warn('Error retrieving theme from localStorage:', error);
    }
    this.fetchTopMenu();
  }

  ngAfterViewInit() {
    console.log('this.scrollable.nativeElement', this.scrollable.nativeElement)
    new PerfectScrollbar(this.scrollable.nativeElement, {
      wheelPropagation: false
    });
  }

  handleMenuToggle() {
    const htmlElement = this.document.documentElement;

    if (htmlElement.classList.contains(this.layoutMenuClass)) {
      this.renderer2.removeClass(htmlElement, this.layoutMenuClass);
    } else {
      this.renderer2.addClass(htmlElement, this.layoutMenuClass);
    }
  }

  setTheme(theme: string) {
    console.log('i am clicked', theme)
    this.selectedTheme = theme;
    localStorage.setItem('storedTheme', theme);
  }

  getIcon(theme: string): string {
    switch (theme) {
      case 'light':
        return 'weather-sunny';
      case 'dark':
        return 'weather-night';
      case 'system':
        return 'monitor';
      default:
        return ''; // Or a default icon
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login'])
  }

  fetchTopMenu() {
    this.menuService.getTopMenu(this.accessToken).subscribe({
      next: (response: any) => {
        console.log('menu', response?.data);
        this.menuList = response?.data;
        this.fetchProfile();
      },
      error: (err) => {
        console.log('error', err);
      }
    })
  }

 private fetchProfile() {
    const url = this.menuList.find((menuItem: MenuItem) => menuItem.key === 'X4WZ4ZQ8SM')?.datasource;
    if (url) {
      this.userService.getProfile(this.accessToken, url).subscribe({
        next: (response: any) => {
          console.log('response:', response);
          this.profileData = response?.data;
        },
        error: (err) => {
          console.log('error', err);
        }
      })
    }
  }


}

interface ThemeOption {
  dataTheme: string;
  displayName: string;
}
