<div class="container" *ngIf="isRender">

    <div class="p-8">
        <div [formGroup]="formGroup">

            <div class="row">
                @for (control of formMetaData?.controls; track $index) {
                <!-- Create a new row for every 6th item -->
                @if ($index % 6 === 0) {
                <div class="w-100"></div> <!-- Forces a new row -->
                }
                @if (control?.is_visible) {
                @if (control?.type!='formgroup' && control?.type!='formarray') {
                <div class="{{control?.div}}">
                    @if (control?.type === 'text') {
                    <micro-text-box formControlName="{{control?.name}}" [placeholder]="control?.placeholder"
                        [label]="control?.label" [isRequired]="control?.is_required">
                    </micro-text-box>
                    }
                    @if (control?.type === 'integer') {
                    <micro-text-box [type]="control?.type " formControlName="{{control?.name}}"
                        [placeholder]="control?.placeholder" [label]="control?.label"
                        [isRequired]="control?.is_required">
                    </micro-text-box>
                    }
                    @if (control?.type === 'checkbox') {
                    <micro-check-box formControlName="{{control?.name}}" [placeholder]="control?.placeholder"
                        [label]="control?.label" [isRequired]="control?.is_required">
                    </micro-check-box>
                    }
                    @if (control?.type === 'datepicker') {
                    <micro-datetimepicker formControlName="{{control?.name}}" [placeholder]="control?.placeholder"
                        [label]="control?.label" [isRequired]="control?.is_required">
                    </micro-datetimepicker>
                    }
                    @if (control?.type === 'email') {
                    <text-email formControlName="{{control?.name}}" [placeholder]="control?.placeholder"
                        [label]="control?.label" [isRequired]="control?.is_required">
                    </text-email>
                    }
                    @if (control?.type === 'select') {
                    <micro-select *ngIf="control?.type=='select'" [dataHoldingKey]="control?.name"
                        [dataSource]="control?.datasource" formControlName='{{control?.name}}'
                        placeholder='{{control?.placeholder}}' label='{{control?.label}}'
                        [isRequired]="control?.is_required">
                    </micro-select>
                    }
                    @if (control?.type === 'toggle') {
                    <micro-toggle-button formControlName="{{control?.name}}" [placeholder]="control?.placeholder"
                        [label]="control?.label" [isRequired]="control?.is_required">
                    </micro-toggle-button>
                    }
                    @if (control?.type === 'textarea') {
                    <micro-textarea formControlName="{{control?.name}}" [placeholder]="control?.placeholder"
                        [label]="control?.label" [isRequired]="control?.is_required">
                    </micro-textarea>
                    }

                    @if (control?.type === 'password') {
                    <micro-password formControlName="{{control?.name}}" [placeholder]="control?.placeholder"
                        [label]="control?.label" [isRequired]="control?.is_required">
                    </micro-password>
                    }

                </div>}
                @if(control?.type=='formarray'){
                <div [formArrayName]="control?.name" class="form-check custom-option">
                    <div>
                        <div class="row">
                            <div class="col-6">
                                <h5 class="card-header">{{control?.label}}</h5>
                            </div>
                            <div class="col-6 d-flex justify-content-end">
                                <div class="col-xl-6 col-md-6 col-sm-6 mb-6 card-header d-flex justify-content-end">
                                    <button class="btn btn-primary me-sm-3 me-1 data-submit waves-effect waves-light"
                                        type="button" (click)="addPerants(control?.name,control?.controls)"
                                        title="{{'Add '+control.label}}">add</button>
                                </div>
                            </div>
                        </div>
                        <div class="p-4">
                            <div class="row form-checks custom-option p-6 mb-4 pl-4" [formGroupName]="i"
                                *ngFor="let control_parent of getparents(control?.name)?.controls; let i = index">
                                <div><span class="d-flex justify-content-end pt-0 pr-0 pb-3">
                                    <i class="ri-close-circle-fill" (click)="removeParent(control?.name,i)"
                                            style="font-size: 24px;" title="remove"></i></span></div>
                                @for (sub_control of control?.controls; track $index) {
                                <!-- Create a new row for every 6th item -->
                                @if ($index % 6 === 0) {
                                <div class="w-100"></div> <!-- Forces a new row -->
                                }
                                @if (sub_control?.is_visible) {
                                @if (sub_control?.type!='formgroup' && sub_control?.type!='formarray') {
                                <div class="{{sub_control?.div}}">
                                    <micro-text-box *ngIf="sub_control?.type=='text'"
                                        formControlName='{{sub_control?.name}}' [isRequired]="sub_control?.is_required"
                                        [error]="getSubControlError(control?.name, i, sub_control?.name)"
                                        placeholder='{{sub_control?.placeholder}}' label='{{sub_control?.label}}'>
                                    </micro-text-box>
                                    <micro-text-box *ngIf="sub_control?.type=='integer'" [type]="sub_control?.type"
                                        formControlName='{{sub_control?.name}}' [isRequired]="sub_control?.is_required"
                                        placeholder='{{sub_control?.placeholder}}' label='{{sub_control?.label}}'>
                                    </micro-text-box>

                                    <micro-check-box *ngIf="sub_control?.type=='checkbox'"
                                        formControlName='{{sub_control?.name}}' [isRequired]="sub_control?.is_required"
                                        placeholder='{{sub_control?.placeholder}}' label='{{sub_control?.label}}'>
                                    </micro-check-box>

                                    <text-email *ngIf="control?.type=='email'" formControlName='{{sub_control?.name}}'
                                        [isRequired]="sub_control?.is_required"
                                        placeholder='{{sub_control?.placeholder}}' label='{{sub_control?.label}}'>
                                    </text-email>
                                    <micro-select *ngIf="sub_control?.type=='select'"
                                        [dataHoldingKey]="sub_control?.name" [dataSource]="sub_control?.datasource"
                                        formControlName='{{sub_control?.name}}' [isRequired]="sub_control?.is_required"
                                        placeholder='{{sub_control?.placeholder}}' label='{{sub_control?.label}}'>
                                    </micro-select>

                                    <micro-toggle-button *ngIf="sub_control?.type=='swtich'"
                                        formControlName='{{sub_control?.name}}' [isRequired]="sub_control?.is_required"
                                        placeholder='{{sub_control?.placeholder}}' label='{{sub_control?.label}}'>

                                    </micro-toggle-button>

                                    <micro-textarea *ngIf="sub_control?.type=='textarea'"
                                        formControlName='{{sub_control?.name}}' [isRequired]="sub_control?.is_required"
                                        placeholder='{{sub_control?.placeholder}}' label='{{sub_control?.label}}'>
                                    </micro-textarea>

                                    <micro-datetimepicker *ngIf="sub_control?.type=='datepicker'"
                                        formControlName='{{sub_control?.name}}' [isRequired]="sub_control?.is_required"
                                        placeholder='{{sub_control?.placeholder}}' label='{{sub_control?.label}}'>
                                    </micro-datetimepicker>

                                    <micro-password *ngIf="sub_control?.type=='password'"
                                        formControlName='{{sub_control?.name}}' [isRequired]="sub_control?.is_required"
                                        placeholder='{{sub_control?.placeholder}}' label='{{sub_control?.label}}'>
                                    </micro-password>
                                </div>

                                }

                                @if(sub_control?.type=='formarray'){
                                <div [formArrayName]="sub_control?.name" class="form-check custom-option">
                                    <div class="mb-8">
                                        <div class="row">
                                            <div class="col-6">
                                                <h5 class="card-header">{{sub_control?.label}}</h5>
                                            </div>
                                            <div class="col-6 d-flex justify-content-end">
                                                <div
                                                    class="col-xl-6 col-md-6 col-sm-6 mb-6 card-header d-flex justify-content-end">
                                                    <button type="button"
                                                        class="btn btn-primary me-sm-3 me-1 data-submit waves-effect waves-light"
                                                        (click)="addChild(i,control?.name,sub_control?.name,sub_control?.controls)"
                                                        title="{{'Add '+sub_control.label}}">Add</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body">

                                            <div class="row form-checks custom-option p-4"
                                                *ngFor="let control_child of getChildren(i,control?.name,sub_control?.name)?.controls; let j = index"
                                                [formGroupName]="j">

                                                @for (sub_control_child of sub_control?.controls; track $index) {
                                                <!-- Create a new row for every 6th item -->
                                                @if ($index % 6 === 0) {

                                                <div class="w-100"></div> <!-- Forces a new row -->
                                                }
                                                @if (sub_control_child?.is_visible) {
                                                @if (sub_control_child?.type!='formgroup' &&
                                                sub_control_child?.type!='formarray') {
                                                <div class="{{sub_control_child?.div}}">
                                                    <micro-text-box *ngIf="sub_control_child?.type=='text'"
                                                        [error]="formGroup?.get(sub_control_child?.name)"
                                                        formControlName='{{sub_control_child?.name}}'
                                                        [isRequired]="sub_control_child?.is_required"
                                                        placeholder='{{sub_control_child?.placeholder}}'
                                                        [valueChangeCallback]="onTextboxValueChange.bind(this)"
                                                        label='{{sub_control_child?.label}}'>
                                                    </micro-text-box>

                                                    <micro-text-box *ngIf="sub_control_child?.type=='integer'"
                                                        [type]="sub_control_child?.type"
                                                        [error]="formGroup?.get(sub_control_child?.name)"
                                                        [isRequired]="sub_control_child?.is_required"
                                                        formControlName='{{sub_control_child?.name}}'
                                                        placeholder='{{sub_control_child?.placeholder}}'
                                                        label='{{sub_control_child?.label}}'
                                                        [valueChangeCallback]="onTextboxValueChange.bind(this)">
                                                    </micro-text-box>

                                                    <micro-check-box *ngIf="sub_control_child?.type=='checkbox'"
                                                        [error]="formGroup?.get(sub_control_child?.name)"
                                                        formControlName='{{sub_control_child?.name}}'
                                                        [isRequired]="sub_control_child?.is_required"
                                                        placeholder='{{sub_control_child?.placeholder}}'
                                                        label='{{sub_control_child?.label}}'>
                                                    </micro-check-box>
                                                    <text-email *ngIf="sub_control_child?.type=='email'"
                                                        [error]="formGroup?.get(sub_control_child?.name)"
                                                        formControlName='{{sub_control_child?.name}}'
                                                        [isRequired]="sub_control_child?.is_required"
                                                        placeholder='{{sub_control_child?.placeholder}}'
                                                        label='{{sub_control_child?.label}}'>
                                                    </text-email>
                                                    <micro-select *ngIf="sub_control_child?.type=='select'"
                                                        [dataHoldingKey]="sub_control_child?.name"
                                                        [dataSource]="sub_control_child?.datasource"
                                                        [error]="formGroup?.get(sub_control_child?.name)"
                                                        [isRequired]="sub_control_child?.is_required"
                                                        formControlName='{{sub_control_child?.name}}'
                                                        placeholder='{{sub_control_child?.placeholder}}'
                                                        label='{{sub_control_child?.label}}' (change)="click($even)">
                                                    </micro-select>

                                                    <micro-toggle-button *ngIf="sub_control_child?.type=='swtich'"
                                                        [error]="formGroup?.get(sub_control_child?.name)"
                                                        formControlName='{{sub_control_child?.name}}'
                                                        [isRequired]="sub_control_child?.is_required"
                                                        placeholder='{{sub_control_child?.placeholder}}'
                                                        label='{{sub_control_child?.label}}'>

                                                    </micro-toggle-button>

                                                    <micro-textarea *ngIf="sub_control_child?.type=='textarea'"
                                                        [error]="formGroup?.get(sub_control_child?.name)"
                                                        formControlName='{{sub_control_child?.name}}'
                                                        [isRequired]="sub_control_child?.is_required"
                                                        placeholder='{{sub_control_child?.placeholder}}'
                                                        label='{{sub_control_child?.label}}'>
                                                    </micro-textarea>

                                                    <micro-datetimepicker *ngIf="sub_control_child?.type=='datepicker'"
                                                        [error]="formGroup?.get(sub_control_child?.name)"
                                                        formControlName='{{sub_control_child?.name}}'
                                                        [isRequired]="sub_control_child?.is_required"
                                                        placeholder='{{sub_control_child?.placeholder}}'
                                                        label='{{sub_control_child?.label}}'>
                                                    </micro-datetimepicker>

                                                    <micro-password *ngIf="sub_control_child?.type=='password'"
                                                        [error]="formGroup?.get(sub_control_child?.name)"
                                                        formControlName='{{sub_control_child?.name}}'
                                                        [isRequired]="sub_control_child?.is_required"
                                                        placeholder='{{sub_control_child?.placeholder}}'
                                                        label='{{sub_control_child?.label}}'>
                                                    </micro-password>
                                                </div>
                                                }
                                                }
                                                }
                                                <div class="col-1"> <span><i (click)="removechild(control?.name,sub_control?.name,i,j)"
                                                            class="ri-delete-bin-6-fill" title="remove"></i></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                }
                                }
                                }

                            </div>
                        </div>
                    </div>
                </div>
                }
                }
                }
            </div>
            <div class="row mt-8">
                <div class="col-11"></div>
                <div class="col-1">
                    <button type="submit" [disabled]="formGroup.invalid"
                        class="btn btn-primary me-sm-3 me-1 data-submit waves-effect waves-light" (click)="submit()">
                        Submit</button>
                </div>
                <!-- <div class="col-1">
                    <button type="reset" class="btn btn-primary me-sm-3 me-1 data-submit waves-effect waves-light" (click)="reset()"> Reset</button>
                </div> -->

            </div>
        </div>

    </div>


</div>