<div class="row">
  <div class="col-md-12">
    <div class="nav-align-top">
      <ul class="nav nav-pills flex-column flex-md-row mb-6 gap-2 gap-lg-0">
        <li class="nav-item">
          <a class="nav-link active waves-effect waves-light" href="javascript:void(0);">
            <i class="ri-lock-line me-1_5"></i>
            Security
          </a>
        </li>
      </ul>
    </div>
    <!-- Change Password -->
    <div class="card mb-6">
      <ngx-spinner [fullScreen]="true" bdColor="rgba(0,0,0,0.5)" name="passwordForm" size="default"
                   type="ball-pulse"></ngx-spinner>

      <h5 class="card-header">{{ changePasswordMetaData?.name }}</h5>
      <div class="card-body pt-1">
        <form (ngSubmit)="handleChangePasswordChange()" [formGroup]="changePasswordForm"
              class="fv-plugins-bootstrap5 fv-plugins-framework" id="formAccountSettings">
          <div class="row">
            @for (control of changePasswordMetaData?.update_form?.controls; track control.name) {
              @if (['old_password'].includes(control.name)) {
                <div class="mb-3 col-md-6">
                  <micro-password [error]="changePasswordForm.get(control.name)"
                                  [formControlName]="control.name" [label]='control.label'
                                  [placeholder]="control.placeholder">
                  </micro-password>
                </div>
              }
            }
          </div>
          <div class="row">
            @for (control of changePasswordMetaData?.update_form?.controls; track control.name) {
              @if (['new_password', 'confirm_password'].includes(control.name)) {
                <div class="mb-3 col-md-6">
                  <micro-password [error]="changePasswordForm.get(control.name)"
                                  [formControlName]="control.name" [label]='control.label'
                                  [placeholder]="control.placeholder">
                  </micro-password>
                </div>
              }
            }
          </div>

          <h6 class="text-body">Password Requirements:</h6>
          <ul class="ps-4 mb-0">
            <li class="mb-4">Minimum 8 characters long - the more, the better</li>
            <li class="mb-4">At least one lowercase character</li>
            <li>At least one number, symbol, or whitespace character</li>
          </ul>
          <div class="mt-3">
            <button [disabled]="changePasswordForm?.invalid || !changePasswordMetaData?.is_update"
                    class="btn btn-primary me-3 waves-effect waves-light"
                    type="submit">
              Save changes
            </button>
            <button class="btn btn-outline-secondary waves-effect" type="reset">Reset</button>
          </div>
        </form>
      </div>
    </div>
    <!--/ Change Password -->
  </div>
</div>
