import {Routes} from '@angular/router';
import {HomeComponent} from "./components/layout/home/home.component";
import {LoginComponent} from "./components/authentication/login/login.component";
import {NotFoundComponent} from "./components/authentication/not-found/not-found.component";
import {MaintenanceComponent} from "./components/authentication/maintenance/maintenance.component";
import {ForgotPasswordComponent} from "./components/authentication/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./components/authentication/reset-password/reset-password.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {RegisterCoverComponent} from "./components/authentication/register-cover/register-cover.component";
import {WorkSpaceSelector} from "./components/workspace-selector/work-space-selector.component";
import {UsersComponent} from "./components/users/users.component";
import {RolePermissionComponent} from "./components/role-permission/role-permission.component";
import {CreateDynamicFormComponent} from "./components/create-dynamic-form/create-dynamic-form.component";
import {ChangePasswordComponent} from "./components/authentication/change-password/change-password.component";
import {ProfileComponent} from "./components/account/profile/profile.component";
import {VerifyAccountComponent} from "./components/authentication/verify-account/verify-account.component";
import {FullcalendarComponent} from "./components/calendar/fullcalendar/fullcalendar.component";

export const routes: Routes = [
  {
    path: '', component: HomeComponent,
    children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'calendar', component: FullcalendarComponent},
      {path: 'profile', component: ProfileComponent},
      {path: 'users', component: UsersComponent},
      {path: 'roles', component: RolePermissionComponent},
      {path: 'change-password', component: ChangePasswordComponent},
    ]
  }, // Default route for root path
  {path: 'register', component: RegisterCoverComponent},
  {path: 'login', component: LoginComponent},
  {path: 'workspace', component: WorkSpaceSelector},
  {path: 'maintenance', component: MaintenanceComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'verify-email', component: VerifyAccountComponent},
  {path:'create-user',component:CreateDynamicFormComponent},
  {path: '**', component: NotFoundComponent}
];
