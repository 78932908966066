import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MenuService} from "../../../services/menu.service";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";
import {UsersService} from "../../../services/users.service";
import {CommonModule} from "@angular/common";
import {MicroPasswordComponent} from "../../../mirco-components/micro-password/micro-password.component";
import {MenuItem} from "../../../interface/login.model";
import {ValidatorsService} from "../../../services/validators.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    ReactiveFormsModule, CommonModule, FormsModule, MicroPasswordComponent, NgxSpinnerComponent
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit {
  public changePasswordForm: FormGroup;
  public changePasswordMetaData!: MenuItem;
  private accessToken = window.localStorage.getItem('access') || '';


  constructor(private fb: FormBuilder, private menuService: MenuService, private spinner: NgxSpinnerService, private userService: UsersService,
              private validatorService: ValidatorsService, private notificationService: NotificationService) {
    this.changePasswordForm = fb.group({});
  }

  ngOnInit() {
    this.getVerifyMenu();
  }

  handleChangePasswordChange() {
    console.log('submit fired', this.changePasswordForm.value);
    const payload = this.changePasswordForm.value;
    const url: string = this.changePasswordMetaData?.datasource;
    this.spinner.show('passwordForm');
    this.changePasswordForm.disable();
    this.userService.updatePassword(this.accessToken, url, payload).subscribe({
      next: (response: any) => {
        console.log('response', response);
        window.alert(response.data);
        this.changePasswordForm.enable();
        this.changePasswordForm.reset();
        this.changePasswordForm.updateValueAndValidity();
        this.changePasswordForm.markAsPristine();
        this.spinner.hide('passwordForm');
        this.notificationService.success("Password updated successfully.");
      },
      error: (error) => {
        this.changePasswordForm.enable();
        this.spinner.hide('passwordForm');
        console.log('Something went wrong', error);
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }


  getVerifyMenu() {
    this.spinner.show('passwordForm');
    this.menuService.verifyMenu(this.accessToken).subscribe({
      next: (response: any) => {
        console.log('response:', response);
        this.changePasswordMetaData = response?.data;
        this.createChangePasswordForm();
        this.spinner.hide('passwordForm');
      }, error: (error) => {
        this.spinner.hide('passwordForm');
        console.log('Something went wrong')
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }

  createChangePasswordForm() {
    this.changePasswordMetaData?.update_form?.controls?.forEach((control) => {
      if (control.is_visible) {
        const validators = this.validatorService.getValidators(control);
        if (control.name === 'terms') {
          validators.push(Validators.requiredTrue);
        }
        this.changePasswordForm.addControl(
          control.name,
          this.fb.control('', validators)
        )
      }
    });
  }

}
