<ngx-spinner type="ball-pulse"></ngx-spinner>
<div *ngIf="!loading" class="content-wrapper">
  <!-- Content: Start -->

  <div class="flex-grow-1 container-p-y container-fluid">
    <div class="app-academy">
      <div class=" p-0 mb-6">
        <div class="d-flex flex-column flex-md-row justify-content-center p-0 pt-6">
          <div class="app-academy-md-50 d-flex align-items-md-center flex-column text-md-center mb-6 py-6">
            <div class="card-title mb-4 lh-lg px-md-12 h4 text-heading">
              Manage Your Business <br>
              <span class="text-primary text-nowrap">All Service in one place</span>.
            </div>
            <p class="mb-4 px-0 px-md-2">
              Select your stores to view and manage it
            </p>

            <form (ngSubmit)="selectWorkSpace()" [formGroup]="workspaceForm"
                  class=" align-items-center justify-content-between app-academy-md-80">
                  
              @for (control of workspaceMetadata?.create_form?.controls;track control.name){
                <mat-form-field class="w-100">
                  <mat-label>Select your workspace</mat-label>
                  <mat-select [disabled]="loading" autofocus [formControlName]="control.name">
                    <mat-option>Select</mat-option>
                    @for (workspace of workspaceList; track workspace) {
                      <mat-option [value]="workspace[control?.datasource?.value_key]">
                        {{ workspace?.[control?.datasource?.display_key]}}
                      </mat-option>
                    }
                  </mat-select>
                  <mat-error>
                    Please select the workspace to manage it.
                  </mat-error>
                </mat-form-field>
              }

              <button  class="btn btn-primary btn-icon waves-effect waves-light w-100 mt-1"
                      type="submit">
                Proceed
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
