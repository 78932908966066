<div class="card shadow-none">
  <p-table (onPage)="pageChange($event)"
           [(selection)]="selectedProducts"
           [loading]="loading"
           [paginator]="true"
           [rowsPerPageOptions]="[10, 25, 50, 100]"
           [rows]="10"
           [scrollable]="true"
           [showCurrentPageReport]="true"
           [sortOrder]="-1"
           [tableStyle]="{ 'min-width': '80rem' }"
           [value]="tableData"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           dataKey="key"
           scrollHeight="80vh"
           sortField="name"
           sortMode="multiple"
           styleClass="">
    <ng-template pTemplate="header">
      <tr>
        <th pFrozenColumn>
          <p-tableHeaderCheckbox/>
        </th>
        <th pSortableColumn="name">
          User
          <p-sortIcon field="name"/>
        </th>
        <th pSortableColumn="email">Email
          <p-sortIcon field="email"/>
        </th>
        <th pSortableColumn="phone">Phone
          <p-sortIcon field="phone"/>
        </th>
        <th pSortableColumn="role">Role
          <p-sortIcon field="role"/>
        </th>
        <th pSortableColumn="created_at">Created
          <p-sortIcon field="created_at"/>
        </th>
        <th pSortableColumn="updated_at">Updated
          <p-sortIcon field="updated_at"/>
        </th>
        <th pSortableColumn="is_active">Status
          <p-sortIcon field="is_active"/>
        </th>
        <th pSortableColumn="is_verified">Verified
          <p-sortIcon field="is_verified"/>
        </th>
        <th [frozen]="true" alignFrozen="right" pFrozenColumn>Action</th>
      </tr>
    </ng-template>
    <ng-template let-row pTemplate="body">
      <tr>
        <td pFrozenColumn>
          <p-tableCheckbox [value]="row"/>
        </td>
        <td class="">
          <div class="d-flex justify-content-start align-items-center user-name">
            <div class="avatar-wrapper">
              <div class="avatar avatar-sm me-4">
                <img alt="Avatar" class="rounded-circle" src="assets/img/avatars/2.png">
              </div>
            </div>
            <div class="d-flex flex-column">
              <a class="text-heading text-truncate">
                <span class="fw-medium">
                  {{ row.name }}
                </span>
              </a>
              <small>
                &#64;username
              </small>
            </div>
          </div>
        </td>
        <td>{{ row.email }}</td>
        <td>{{ row.phone }}</td>
        <td>
            <span class="text-truncate d-flex align-items-center text-heading">
                @if (row.role === 'admin') {
                  <i class="ri-computer-line ri-22px text-danger me-2"></i>
                }
              @if (row.role === 'manager') {
                <i class="ri-edit-box-line ri-22px text-info me-2"></i>
              }
              @if (row.role === 'Region Manager') {
                <i class="ri-pie-chart-line ri-22px text-success me-2"></i>
              }
              @if (row.role === 'staff') {
                <i class="ri-user-line ri-22px text-primary me-2"></i>
              }
              {{ row.role | titlecase }}
          </span>
        </td>
        <td>{{ row.created_at }}</td>
        <td>{{ row.updated_at }}</td>
        <td>
          @if (row.is_active) {
            <span class="badge rounded-pill bg-label-success">Active</span>
          }
          @if (!row.is_active) {
            <span class="badge rounded-pill bg-label-secondary">Inactive</span>
          }
        </td>
        <td>
          {{ row.is_verified ? 'Yes' : 'No' }}
        </td>
        <td [frozen]="true" alignFrozen="right" pFrozenColumn>
          <div class="d-flex align-items-center">
            <a class="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect delete-record"
               data-bs-toggle="tooltip"
               title="Delete Invoice">
              <i class="ri-delete-bin-7-line ri-22px"></i>
            </a>
            <a class="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect"
               data-bs-toggle="tooltip" title="Preview">
              <i class="ri-eye-line ri-22px"></i>
            </a>
            <a class="btn btn-sm btn-icon btn-text-secondary rounded-pill waves-effect"
               data-bs-toggle="tooltip" title="Edit" (click)="openEditPopup(row)">
              <i class="ri-edit-box-line me-2"></i>
            </a>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
