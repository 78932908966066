import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {ServicesService} from "../../../services/services.service";
import {MenuService} from "../../../services/menu.service";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";
import {ValidatorsService} from "../../../services/validators.service";
import {Control, MenuItem} from "../../../interface/login.model";
import {MicroPasswordComponent} from "../../../mirco-components/micro-password/micro-password.component";
import {CommonModule, NgIf} from "@angular/common";
import {RecaptchaComponent} from "../../utils/recaptcha/recaptcha.component";
import {TextEmailComponent} from "../../../mirco-components/text-email/text-email.component";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-verify-account',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule,
    RecaptchaComponent,
    RouterLink,
    TextEmailComponent,
    NgxSpinnerComponent
  ],
  templateUrl: './verify-account.component.html',
  styleUrl: './verify-account.component.scss'
})
export class VerifyAccountComponent implements OnInit {
  @ViewChild(RecaptchaComponent) recaptchaComponent!: RecaptchaComponent;
  code: string | null = null;
  isServiceFetched: boolean = false;
  public serviceData: any = {};
  public verifyAccountForm: FormGroup;
  public verifyAccountMetaData!: MenuItem;
  public verificationStatus: boolean | undefined = undefined;
  public linkSendAgain: boolean = false;
  public sendingEmail: boolean = false;
  public lastSentMailAddress: string = ""


  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private services: ServicesService,
              private menuService: MenuService,
              private spinner: NgxSpinnerService,
              private validatorService: ValidatorsService,
              private notificationService: NotificationService
  ) {
    this.verifyAccountForm = this.fb.group({});
  }

  ngOnInit() {
    this.authService.logout();
    this.getServices();
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'] || null;
      if (this.code) {
        this.verifyEmail(this.code);
      } else {
        this.verifyPublicMenu();
      }
    });
  }

  getServices() {
    this.services.serviceData$.subscribe(data => {
      this.serviceData = data;
    });
  }

  verifyPublicMenu() {
    this.spinner.show('verifyEmail');
    this.menuService.verifyPublicMenu().subscribe({
      next: (response: any) => {
        console.log('response', response)
        this.verifyAccountMetaData = response?.data;
        this.spinner.hide('verifyEmail');
        this.createVerificationForm();
      },
      error: (error) => {
        this.spinner.hide('verifyEmail')
        console.log('error', error);
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }

  createVerificationForm() {
    this.verifyAccountMetaData?.create_form?.controls?.forEach((control: Control) => {
      const validators = this.validatorService.getValidators(control);
      let controlValue: string | null = '';
      if (control.name === 'code') {
        controlValue = this.code;
      }
      this.verifyAccountForm.addControl(
        control.name,
        this.fb.control(controlValue, validators)
      )
    })
  }

  verifyEmail(code: string) {
    this.spinner.show('verifyEmail');
    this.authService.verifyEmailLink(code).subscribe({
      next: (response) => {
        console.log('response', response)
        this.spinner.hide('verifyEmail');
        this.verificationStatus = true;
        this.notificationService.success('Account verification completed successfully.!');
      },
      error: (error) => {
        this.verificationStatus = false;
        console.log('error', error);
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
        this.spinner.hide('verifyEmail');
        this.verifyPublicMenu();
      }
    })
  }

  sendNewVerificationLink() {
    const payload = this.verifyAccountForm.value;
    this.lastSentMailAddress = payload?.email;
    this.verifyAccountForm.disable();
    this.sendingEmail = true;
    this.authService.sendVerifyEmail(payload).subscribe({
      next: (response) => {
        console.log('response', response)
        this.spinner.hide('app');
        this.linkSendAgain = true;
        this.sendingEmail = false;
        this.verifyAccountForm.enable();
        this.verifyAccountForm.reset();
        this.verifyAccountForm.markAsPristine();
        this.verifyAccountForm.updateValueAndValidity();
        this.recaptchaComponent.resetCaptcha();
        this.notificationService.success('New Verification Link sent successfully.');
      },
      error: (error) => {
        this.verifyAccountForm.enable();
        this.sendingEmail = false;
        console.log('error', error);
        this.spinner.hide('app');
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }

}
