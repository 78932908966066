import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { RolesService } from "../../services/roles.service";
import { CommonModule } from "@angular/common";
  import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormArray, FormControl, FormsModule } from '@angular/forms';
import { MicroCheckBoxComponent } from '../../mirco-components/micro-check-box/micro-check-box.component';
import { MenuService } from '../../services/menu.service';
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-role-permission',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule,FormsModule,MicroCheckBoxComponent],
  templateUrl: './role-permission.component.html',
  styleUrl: './role-permission.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RolePermissionComponent implements OnInit {
  public loading: boolean = true;
  public roles: any[] = [];
  accessToken = window.localStorage.getItem('access') || '';
  public form!: FormGroup;
  public menus!: FormArray;
  constructor(private fb: FormBuilder, private roleService: RolesService,private menuService: MenuService,
             private helperService:HelperService) {
  }
  ngOnInit() {
    this.getVerifyMenu();
   this.fetchRoles();
    this.createNativeForm()
    this.getRoles();
  }

  fetchRoles() {
    this.roleService.getRoles(this.accessToken).subscribe({
      next: (res: any) => {
        this.roles = res?.data
        this.loading = false
      },
      error: err => {
        this.loading = false;
      }
    })
  }
  public role: any;
  formArray: any=[];
  isfiltered:boolean=false;
  formArrayMetadata:any=[];
  getRoles() {
    let i = 0;
    this.role = this.roleData?.data;
    const frmArray = this.form?.get('menus') as FormArray;
    frmArray.removeAt(0);
    this.formArrayMetadata=[];
    this.role?.menus?.forEach((menu: any) => {
     // const frmArray: FormArray = this.form?.controls['menus'] as FormArray;

      // Ensure menu is an array before patching to avoid error
      if(!this.isfiltered){
        this.formArrayMetadata=this.filterFormArrayControls('menus',this.metaData?.create_form?.controls);
        this.isfiltered=true;
      }

      if (Array.isArray(menu)) {
        menu.forEach((item, index) => {

            const menuControl = this.initiateForm(this.formArrayMetadata); // Assuming initiateForm creates a new form control
          menuControl.patchValue(item);
          frmArray.push(menuControl);
        });
      } else {
        // Handle if `menu` is not an array and directly set its value
        const menuControl = this.initiateForm(this.formArrayMetadata);
        menuControl.patchValue(menu);
        frmArray.push(menuControl);
      }

      i++;
    });

    this.form.updateValueAndValidity();
  }


  // getRoles() {
  //   let i = 0;
  //   this.role = this.roleData?.data;
  //   const menus = this.form?.get('menus') as FormArray;
  //   //const frmArray:FormArray=new FormArray({});
  //   this.role?.menus?.forEach((menu: any) => {
  //     const frmArray:FormArray=this.form?.controls['menus'] as FormArray;
  //    // let frm = this.initiateForm(this.metaData?.create_form?.controls);
  //    //  if (Array.isArray(menu) && this.formArray?.includes(menu.name)) {
  //    //
  //    //  }
  //     //frm.patchValue(menu)
  //    // frm.updateValueAndValidity();
  //    // menus?.push(frm);
  //    // console.log('menus', menus)
  //
  //     //menus.updateValueAndValidity();
  //     if(i>0){
  //       menus?.controls.push(menus?.controls[0]);
  //     }
  //     //menus.patchValue(menu);
  //     this.form.get('menus')?.patchValue(menu);
  //     i++
  //   });
  //   ///this.form/
  //   //this.menuFormArray.patchValue(this.role);
  //   //this.form.patchValue(this.role);
  //   this.form.updateValueAndValidity();
  // }
  //

  createNativeForm() {
    this.form = this.fb.group({
      //menus: this.fb.array([])
    });
    let frm:any = this.initiateForm(this.metaData?.create_form?.controls);
    this.form = frm;
  }
  get menuFormArray() {
    return this.form.get('menus') as FormArray;
  }

// getFormArrayControls(key:string,metaData:any) {
//   if(metaData?.type=='formarray' && metaData?.name==key){
//    return metaData?.controls;
//   }else{
//     for
//   }
// }

  filterFormArrayControls(key:string,controls:any){
    let formArrayControls:any = []

    controls?.forEach((control:any) => {
      // Check if the control is of type 'formarray'
      if (control.type === 'formarray' && control.name ==key) {
        formArrayControls.push(control?.controls);
      }

      // If the control has nested controls, apply recursion
      if (control.controls && control.controls.length > 0) {
        formArrayControls = [
          ...formArrayControls,
          ...this.filterFormArrayControls(key,control.controls)
        ];
      }
    });

    return formArrayControls?.length ? formArrayControls[0] : [];
  }
  initiateForm(metaData: any) {
    let controlsObj: any = {};
    metaData?.forEach((ele: any) => {
      if(ele?.type==='formarray'){
        this.formArray?.push(ele?.name);
       let frm:any= this.initiateForm(ele?.controls);
        controlsObj[ele?.name] = new FormArray([frm]);
        return;
      }
      controlsObj[ele?.name] = new FormControl(null)
    });
    return this.fb.group(controlsObj);
  }

  frmMetaData:any;
  initiateFormArray(frm:any,key:string,data:any){
    let formArray: any = this.frmMetaData?.filter((ele: any) => {
      ele?.name==key;
    })
    if(formArray?.length){
      data?.forEach((x: any) => {
      let newfrm:any=this.initiateForm(formArray)
        newfrm?.patchValue(x);
        frm.push(newfrm);
      })
    }
    return frm;
  }


  verifyMenuData:any;
  user_permission_form:any
  getVerifyMenu() {
    this.menuService.verifyMenu(this.accessToken).subscribe({
      next: (response: any) => {
        console.log('response:', response);
        this.verifyMenuData = response?.data;
        this.user_permission_form = this.verifyMenuData?.filter_form;
        //this.user_permission_form = this.helperService.initiateForm(this.filterFormMetaData?.controls);
       // this.isRenderFilter = true;
        //this.fetchUsers()
      }, error: (error) => {
        console.log('Something went wrong')
      }
    })
  }
  get getControls() {
    const questions = this.form?.get('menus') as FormArray;
    return questions;
  }

  public formData: any;
  getValue() {
    this.formData = this.form.getRawValue();
  }

  isVisible(val: any) {
    return !JSON.parse(val);
  }

  roleData = {
    "msg_code": [],
    "data": {
      "name": "admin",
      "parent": null,
      "menus": [
        {
          "key": "293UKMQ3NU",
          "name": "Dashboard",
          "is_read": true,
          "is_create": true,
          "is_update": false,
          "is_delete": true
        },
        {
          "key": "RFFK4JBK28",
          "name": "Users",
          "is_read": true,
          "is_create": true,
          "is_update": false,
          "is_delete": true
        },
        {
          "key": "CRLEK6P17S",
          "name": "Roles",
          "is_read": true,
          "is_create": false,
          "is_update": true,
          "is_delete": true
        },
        {
          "key": "5IX6AVM6Y5",
          "name": "Stores",
          "is_read": true,
          "is_create": true,
          "is_update": false,
          "is_delete": true
        },
        {
          "key": "X4WZ4ZQ8SN",
          "name": "My Profile",
          "is_read": true,
          "is_create": true,
          "is_update": true,
          "is_delete": true
        },
        {
          "key": "YV8Y3G9DF1",
          "name": "Change Password",
          "is_read": true,
          "is_create": false,
          "is_update": true,
          "is_delete": true
        }
      ]
    },
    "r_id": "0e749c7c-692d-11ef-8cdc-0242ac120002",
    "status": 200
  }
  // metaData: any = {
  //   "name": "role_menu",
  //   "controls": [
  //     {
  //       "div": "col-12 col-xs-12 col-md-12 col-lg-12",
  //       "name": "menus",
  //       "label": "Menus",
  //       "type": "formgroup",
  //       "is_visible": true,
  //       "datasource": {
  //         "url": "http://82.112.227.207:8000/api/menu/list",
  //         "method": "GET",
  //         "payload": {},
  //         "response": {
  //           "key": "",
  //           "name": "",
  //           "is_read": false,
  //           "is_create": false,
  //           "is_update": false,
  //           "is_delete": false
  //         }
  //       },
  //       "controls": [
  //         {
  //           "div": "",
  //           "class": "form-control",
  //           "name": "key",
  //           "type": "text",
  //           "is_visible": false,
  //           "is_readonly": true,
  //           "is_required": true
  //         },
  //         {
  //           "div": "",
  //           "class": "form-control",
  //           "name": "name",
  //           "label": "Menu Name",
  //           "type": "label",
  //           "is_visible": true,
  //           "is_readonly": true,
  //           "is_required": false
  //         },
  //         {
  //           "div": "form-check mb-0 me-4 me-lg-12",
  //           "class": "form-check-input",
  //           "name": "is_read",
  //           "label": "Read",
  //           "type": "checkbox",
  //           "is_visible": true,
  //           "is_readonly": false,
  //           "is_required": false
  //         },
  //         {
  //           "div": "form-check mb-0 me-4 me-lg-12",
  //           "class": "form-check-input",
  //           "name": "is_create",
  //           "label": "Create",
  //           "type": "checkbox",
  //           "is_visible": true,
  //           "is_readonly": false,
  //           "is_required": false
  //         },
  //         {
  //           "div": "form-check mb-0 me-4 me-lg-12",
  //           "class": "form-check-input",
  //           "name": "is_update",
  //           "label": "Update",
  //           "type": "checkbox",
  //           "is_visible": true,
  //           "is_readonly": false,
  //           "is_required": false
  //         },
  //         {
  //           "div": "form-check mb-0",
  //           "class": "form-check-input",
  //           "name": "is_delete",
  //           "label": "Delete",
  //           "type": "checkbox",
  //           "is_visible": true,
  //           "is_readonly": false,
  //           "is_required": false
  //         }
  //       ]
  //     }
  //   ]
  // }
  metaData:any={
    "key": "CRLEK6P17N",
    "name": "Roles",
    "route": "/roles",
    "component": null,
    "datasource": "https://authorization.mynextsalon.com/api/roles",
    "is_read": true,
    "is_create": true,
    "is_update": true,
    "is_delete": true,
    "filter_form": {
      "name": "role_menu_filter",
      "controls": []
    },
    "create_form": {
      "name": "role_menu_create",
      "controls": [
        {
          "div": "col-12 col-xs-12 col-md-6 col-lg-6",
          "name": "name",
          "type": "text",
          "label": "Role",
          "is_visible": true,
          "is_readonly": true,
          "is_required": true,
          "placeholder": "Enter Role"
        },
        {
          "div": "col-12 col-xs-12 col-md-6 col-lg-6",
          "name": "parent",
          "type": "select",
          "label": "Parent Role",
          "datasource": {
            "url": "https://app.mynextsalon.com/api/role",
            "method": "GET",
            "payload": {},
            "value_key": "key",
            "display_key": "name"
          },
          "is_visible": false,
          "is_readonly": true,
          "is_required": true
        },
        {
          "div": "col-12 col-xs-12 col-md-12 col-lg-12",
          "name": "menus",
          "type": "formarray",
          "label": "Menus",
          "controls": [
            {
              "div": "",
              "name": "key",
              "type": "text",
              "is_visible": false,
              "is_readonly": true,
              "is_required": true
            },
            {
              "div": "form-check mb-0 me-3 me-lg-12",
              "name": "name",
              "type": "text",
              "label": "Menu Name",
              "is_visible": true,
              "is_readonly": true,
              "is_required": false
            },
            {
              "div": "form-check mb-0 me-3 me-lg-12",
              "name": "is_read",
              "type": "checkbox",
              "label": "Read",
              "is_visible": true,
              "is_readonly": false,
              "is_required": false
            },
            {
              "div": "form-check mb-0 me-3 me-lg-12",
              "name": "is_create",
              "type": "checkbox",
              "label": "Create",
              "is_visible": true,
              "is_readonly": false,
              "is_required": false
            },
            {
              "div": "form-check mb-0 me-3 me-lg-12",
              "name": "is_update",
              "type": "checkbox",
              "label": "Update",
              "is_visible": true,
              "is_readonly": false,
              "is_required": false
            },
            {
              "div": "form-check mb-0 me-3 me-lg-12",
              "name": "is_delete",
              "type": "checkbox",
              "label": "Delete",
              "is_visible": true,
              "is_readonly": false,
              "is_required": false
            }
          ],
          "datasource": {
            "url": "https://app.mynextsalon.com/api/menu/list",
            "method": "GET",
            "payload": {},
            "response": {
              "key": "",
              "name": "",
              "is_read": false,
              "is_create": false,
              "is_delete": false,
              "is_update": false
            }
          },
          "is_visible": true
        }
      ]
    },
    "update_form": {
      "name": "role_menu_update",
      "controls": [
        {
          "div": "col-12 col-xs-12 col-md-6 col-lg-6",
          "name": "name",
          "type": "text",
          "label": "Role",
          "is_visible": true,
          "is_readonly": true,
          "is_required": true,
          "placeholder": "Enter Role"
        },
        {
          "div": "col-12 col-xs-12 col-md-6 col-lg-6",
          "name": "parent",
          "type": "select",
          "label": "Parent Role",
          "datasource": {
            "url": "https://app.mynextsalon.com/api/role",
            "method": "GET",
            "payload": {},
            "value_key": "key",
            "display_key": "name"
          },
          "is_visible": false,
          "is_readonly": true,
          "is_required": true
        },
        {
          "div": "col-12 col-xs-12 col-md-12 col-lg-12",
          "name": "menus",
          "type": "formarray",
          "label": "Menus",
          "controls": [
            {
              "div": "",
              "name": "key",
              "type": "text",
              "is_visible": false,
              "is_readonly": true,
              "is_required": true
            },
            {
              "div": "col-12 col-xs-12 col-md-4 col-lg-4",
              "name": "name",
              "type": "text",
              "label": "Menu Name",
              "is_visible": true,
              "is_readonly": true,
              "is_required": false
            },
            {
              "div": "col-3 col-xs-3 col-md-2 col-lg-2",
              "name": "is_read",
              "type": "checkbox",
              "label": "Read",
              "is_visible": true,
              "is_readonly": false,
              "is_required": false
            },
            {
              "div": "col-3 col-xs-3 col-md-2 col-lg-2",
              "name": "is_create",
              "type": "checkbox",
              "label": "Create",
              "is_visible": true,
              "is_readonly": false,
              "is_required": false
            },
            {
              "div": "col-3 col-xs-3 col-md-2 col-lg-2",
              "name": "is_update",
              "type": "checkbox",
              "label": "Update",
              "is_visible": true,
              "is_readonly": false,
              "is_required": false
            },
            {
              "div": "col-3 col-xs-3 col-md-2 col-lg-2",
              "name": "is_delete",
              "type": "checkbox",
              "label": "Delete",
              "is_visible": true,
              "is_readonly": false,
              "is_required": false
            }
          ],
          "datasource": {
            "url": "https://app.mynextsalon.com/api/menu/list",
            "method": "GET",
            "payload": {},
            "response": {
              "key": "",
              "name": "",
              "is_read": false,
              "is_create": false,
              "is_delete": false,
              "is_update": false
            }
          },
          "is_visible": true
        }
      ]
    },
    "children": []
  }

}
