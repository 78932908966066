<ngx-spinner [fullScreen]="true" bdColor="rgba(0,0,0,0.5)" name="registerPage" size="default"
             type="ball-pulse"></ngx-spinner>

<div class="authentication-wrapper authentication-cover">
  <!-- Logo -->
  @if (isServiceFetched) {
    <a class="auth-cover-brand d-flex align-items-center gap-3" routerLink="/register">
      <div class="app-brand-logo">
        <div style="color:var(--bs-primary);">
          <img [src]="serviceData?.logo" alt="" class="rounded-2 logo" height="30"
               width="30">
        </div>
      </div>
      <span class="app-brand-text text-heading fw-semibold" *ngIf="false">{{ serviceData?.name | titlecase }}</span>
    </a>
  }
  <!-- /Logo -->
  <div class="authentication-inner row m-0">
    <!-- /Left Text -->
    <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
      <div>
        <img [src]="serviceData?.cover?serviceData?.cover:'https://app.mynextsalon.com/assets/img/services/cover.png'"
             alt="auth-model" class="authentication-image-model d-none d-lg-block"
             data-app-dark-img="illustrations/auth-cover-register-illustration-dark.png"
             data-app-light-img="illustrations/auth-cover-register-illustration-light.png"
             height="700"
             style="object-fit: contain">
      </div>

    </div>
    <!-- /Left Text -->
    @if (isUserRegistered){
      <div
        class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-5 px-12 py-4">
        <div class="w-px-400 mx-auto pt-5 pt-lg-0">

          <h4 class="mb-1">Verify your email ✉️ </h4>
          <p class="text-start mb-0">
            Account activation link sent to your email address: <span class="h6">{{registeredMailAddress}}</span>.
            Please follow the link inside to continue.
          </p>
          <a class="btn btn-primary w-100 my-5 waves-effect waves-light" routerLink="/login">
           Login
          </a>
          <p class="text-center">
            Didn't get the mail?
            <a href="javascript:void(0);" (click)="resendMail()">
              Resend
            </a>
          </p>
          @if (false){
            <div class="divider my-5">
              <div class="divider-text">or</div>
            </div>

            <div class="d-flex justify-content-center gap-2">
              <a class="btn btn-icon btn-lg rounded-pill btn-text-google-plus waves-effect waves-light" href="javascript:;">
                <i class="tf-icons ri-google-fill ri-24px"></i>
              </a>
            </div>
          }
        </div>
      </div>
    } @else {
      <!-- Register -->
      <div
        class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-5 px-12 py-4">
        <div class="w-px-400 mx-auto pt-5 pt-lg-0">

          <h4 class="mb-1">Register &commat; {{ serviceData?.name |titlecase }} 🚀 </h4>
          <p class="mb-5">{{ serviceData?.description }}</p>

          <form (ngSubmit)="registerUser()" [formGroup]="registerForm"
                class="mb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                id="formAuthentication">
            @if (registerFormMetaData?.create_form?.controls?.length) {
              @for (control of registerFormMetaData?.create_form?.controls; track control.name) {
                <div class="mb-3">
                  @if (control.type === 'text') {
                    <micro-text-box [error]="registerForm.get(control.name)"
                                    formControlName='{{control.name}}'
                                    placeholder='{{control?.placeholder}}'
                                    label='{{control?.label}}'>
                    </micro-text-box>
                  } @else if (control.type === 'email') {
                    <text-email [error]="registerForm.get(control.name)"
                                formControlName='{{control.name}}' placeholder='{{control.placeholder}}'
                                label='{{control.label}}'>
                    </text-email>
                  } @else if (control.type === 'password') {
                    <micro-password [error]="registerForm.get(control.name)"
                                    formControlName='{{control?.name}}' placeholder='{{control?.placeholder}}'
                                    label='{{control?.label}}'>
                    </micro-password>
                  } @else if (control.type === 'recaptchaV2') {
                    <mns-recaptcha [control]="control"></mns-recaptcha>
                  }
                  @else if (control.type === 'checkbox' && control.name==='terms') {
                    <div class="py-2 fv-plugins-icon-container">
                      <div class="form-check mb-0">
                        <input
                          [ngClass]="{'is-invalid': registerForm.get(control.name)?.invalid && (!registerForm.get(control.name)?.pristine || registerForm.get(control.name)?.touched)}"
                          class="form-check-input" formControlName="terms" id="terms-conditions"
                          type="checkbox"
                        >
                        <label class="form-check-label" for="terms-conditions">
                          I agree to
                          <a href="javascript:void(0);" (click)="openPrivacyPolicy()" role="link">privacy policy &amp; terms</a>
                        </label>
                        <ng-container
                          *ngIf="registerForm.get(control.name)?.invalid && (!registerForm.get(control.name)?.pristine || registerForm.get(control.name)?.touched)">
                          <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                            <ng-container *ngIf="registerForm.get(control.name)?.hasError('required')">
                              Please agree terms & conditions
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  }
                </div>
              }
            }
            <button [disabled]="loading || registerForm.invalid"
                    class="btn btn-primary d-grid w-100 waves-effect waves-light"
                    type="submit">
              Sign up
            </button>
          </form>

          <p class="text-center mb-5">
            <span>Already have an account?</span>
            <a routerLink="/login">
              <span> Sign in instead</span>
            </a>
          </p>
          @if (false){
            <div class="divider my-5">
              <div class="divider-text">or</div>
            </div>

            <div class="d-flex justify-content-center gap-2">
              <a class="btn btn-icon btn-lg rounded-pill btn-text-google-plus waves-effect waves-light" href="javascript:;">
                <i class="tf-icons ri-google-fill ri-24px"></i>
              </a>
            </div>
          }
        </div>
      </div>
      <!-- /Register -->
    }

  </div>
</div>
