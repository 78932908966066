import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MicroTextBoxComponent } from "../../mirco-components/micro-text-box/micro-text-box.component";
import { MicroCheckBoxComponent } from "../../mirco-components/micro-check-box/micro-check-box.component";
import { MicroDateComponent } from "../../mirco-components/micro-date/micro-date.component";
import { TextEmailComponent } from "../../mirco-components/text-email/text-email.component";
import { MicroSelectComponent } from "../../mirco-components/micro-select/micro-select.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelperService } from '../../services/helper.service';
import { httpService } from '../../services/http.service';
import { CommonModule, NgForOf } from '@angular/common';
import { NgxSpinnerComponent } from 'ngx-spinner';
import { TableModule } from 'primeng/table';
import { MicroMultiSelectComponent } from '../../mirco-components/micro-multi-select/micro-multi-select.component';
import { MicroPasswordComponent } from '../../mirco-components/micro-password/micro-password.component';
import { MicroTextareaComponent } from '../../mirco-components/micro-textarea/micro-textarea.component';
import { MicroToggleButtonComponent } from '../../mirco-components/micro-toggle-button/micro-toggle-button.component';
import { TableComponent } from '../../mirco-components/table/table.component';
import { CreateDynamicFormComponent } from '../create-dynamic-form/create-dynamic-form.component';

@Component({
  selector: 'list-filter',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxSpinnerComponent, MicroPasswordComponent, TableModule, TableComponent, MicroCheckBoxComponent, MicroPasswordComponent, MicroTextBoxComponent, ReactiveFormsModule, MicroDateComponent, MicroSelectComponent, MicroMultiSelectComponent, MicroToggleButtonComponent, MicroTextareaComponent, NgForOf, TextEmailComponent, CreateDynamicFormComponent],
  templateUrl: './list-filter.component.html',
  styleUrl: './list-filter.component.scss'
})
export class ListFilterComponent {
  accessToken = localStorage.getItem('access') || '';
  filterForm: FormGroup = new FormGroup({});
  viewRender: boolean = false;
  @Input() data:any;
  @Input() metaData:any;
  @Output() callback:any=new EventEmitter<any>();
  listData:any;
  constructor(private formBuilder: FormBuilder, private helperService: HelperService,
    private http: httpService,
    private changeDetectorRef: ChangeDetectorRef) {
  }
  ngOnInit() {

    this.createForm();

  }
  filter() {
    this.fetchData(this.helperService.createQueryString(this.metaData?.datasource, this.filterForm.value));
  }

  createForm(){
    setTimeout(() => {
      console.log('Ref component data', this.data?.formMetaData)
      if (this.metaData != undefined) {
        this.filterForm = this.helperService.initiateForm(this.metaData?.controls);
        this.changeDetectorRef.detectChanges();
        this.viewRender = true;
       // this.formGroup.patchValue(this.data);// create a service 
       //this.filterForm.updateValueAndValidity();
       // this.changeDetectorRef?.detectChanges();
      } else{
        this.createForm()
        console.log('call createForm')
      }
    }, 100);
  }
  frmReset() {
    this.filterForm.reset();
    this.fetchData(this.metaData?.datasource)
  }
  fetchData(url:string) {
    this.http.get(url).subscribe(res => {
      console.log('filter', res);
      this.listData = res?.data;
      this.callback.emit(this.listData);
    }, error => {
      console.error('Error:', error);
      //this.callback.emit([]);
    });
  }
}
