import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {StoreService} from "../../../services/store.service";
import {ServicesService} from "../../../services/services.service";
import {MenuService} from "../../../services/menu.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ValidatorsService} from "../../../services/validators.service";
import {Control, MenuItem} from "../../../interface/login.model";
import {CommonModule} from "@angular/common";
import {MicroPasswordComponent} from "../../../mirco-components/micro-password/micro-password.component";
import {MatError} from "@angular/material/form-field";
import {RecaptchaComponent} from "../../utils/recaptcha/recaptcha.component";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    ReactiveFormsModule,
    MicroPasswordComponent,
    MatError,
    RecaptchaComponent
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {
  code: string | null = null;
  isServiceFetched: boolean = false;
  public serviceData: any = {};
  public resetPasswordForm: FormGroup;
  public resetPasswordMetaData!: MenuItem;


  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private services: ServicesService,
              private menuService: MenuService,
              private spinner: NgxSpinnerService,
              private validatorService: ValidatorsService,
              private notificationService: NotificationService
  ) {
    this.resetPasswordForm = this.fb.group({});
  }

  ngOnInit() {
    this.authService.logout();
    this.route.queryParams.subscribe((params) => {
      this.code = params['code'] || null;
      if (!this.code){
        this.router.navigate(['/forgot-password']);
      }
    });

    this.getServices();
    this.verifyPublicMenu();
  }

  getServices() {
    this.services.serviceData$.subscribe(data => {
      this.serviceData = data;
    });
  }

  verifyPublicMenu() {
    this.spinner.show('forgetPasswordPage');
    this.menuService.verifyPublicMenu().subscribe({
      next: (response: any) => {
        console.log('response', response)
        this.resetPasswordMetaData = response?.data;
        this.spinner.hide('forgetPasswordPage');
        this.createForgetForm();
      },
      error: (error) => {
        this.spinner.hide('forgetPasswordPage')
        console.log('error', error);
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }

  createForgetForm() {
    this.resetPasswordMetaData?.create_form?.controls?.forEach((control: Control) => {
      const validators = this.validatorService.getValidators(control);
      let controlValue: string | null = '';
      if (control.name === 'code') {
        controlValue = this.code;
      }
      this.resetPasswordForm.addControl(
        control.name,
        this.fb.control(controlValue, validators)
      )
    })
  }

  handleCreateNewPassword() {
    const payload = this.resetPasswordForm?.value;
    const url: string = this.resetPasswordMetaData?.datasource;
    this.resetPasswordForm.disable();
    this.spinner.show('app')
    this.authService.resetPassword(payload, url).subscribe({
      next: (response: any) => {
        this.spinner.hide('app')
        console.log('response', response)
        this.notificationService.success("Password reset successfully!")
        this.resetPasswordForm.enable();
        this.resetPasswordForm.reset();
        this.resetPasswordForm.markAsPristine();
        this.resetPasswordForm.updateValueAndValidity();
        this.router.navigate(['/login']);
      },
      error: (error) => {
        this.spinner.hide('app')
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
        this.resetPasswordForm.enable();
        console.log('error', error);
      }
    })
  }
}
