import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {IVerifyMenu} from "../interface/menu.model";
import {ServicesService} from "./services.service";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private serviceData: any;

  constructor(private http: HttpClient, private services: ServicesService) {
    this.services.serviceData$.subscribe({
      next: (serviceData: any) => {
        this.serviceData = serviceData;
      }
    })
  }

  getMenu(token: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = this.serviceData?.urls?.find((urlObj: {
      name: string,
      url: string
    }) => urlObj.name === 'left_menu')?.url;
    return this.http.get(url, {headers});
  }

  getTopMenu(token: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = this.serviceData?.urls?.find((urlObj: {
      name: string,
      url: string
    }) => urlObj.name === 'top_menu')?.url;
    return this.http.get(url, {headers});
  }

  verifyMenu(token: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const url = this.serviceData?.urls?.find((urlObj: {
      name: string,
      url: string
    }) => urlObj.name === 'verify_menu')?.url;
    return this.http.get(url, {headers});
  }

  verifyPublicMenu() {
    console.log('data:', this.serviceData);
    const url = this.serviceData?.urls?.find((urlObj: {
      name: string,
      url: string
    }) => urlObj.name === 'verify_public_menu')?.url;
    return this.http.get(url);
  }
}
