import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, forwardRef, OnInit, OnChanges, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'micro-password',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './micro-password.component.html',
  styleUrl: './micro-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MicroPasswordComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MicroPasswordComponent),
      multi: true
    }
  ],
})
export class MicroPasswordComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {
  @Input() type: string = 'text';
  @Input() isRequired:boolean=false;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() error: any
  public innerValue: any = '';
  public onChange: (value: any) => void = () => { };
  public onTouched: () => void = () => { };
  formGroup!: FormGroup;
  control: FormControl = new FormControl('');
  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {

  }

  public class = 'ri-eye-off-line ri-20px'
  ngOnInit(): void {
    this.control.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }
  isViewP: boolean = false;
  viewP() {
    if (!this.isViewP) {
      this.type = 'text';
      this.class = 'ri-eye-line ri-20px'
      this.isViewP=true
    } else {
      this.class = 'ri-eye-off-line ri-20px'
      this.type = 'password';
      this.isViewP=false;
    }
  }

  triggerChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['error']) {
      this.error = changes['error'].currentValue;
    }
  }
  // ngOnDestroy(): void {
  //   // Clean up subscriptions if any
  // }
  // markNameControlAsTouched(key:any) {
  //   this.formGroup.get(key)?.markAsTouched();
  // }
  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    this.innerValue = value;
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  validate(control: FormControl): { [key: string]: any } | null {
    return this.control.errors;
  }

}
