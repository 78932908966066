import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { TextEmailComponent } from "../../mirco-components/text-email/text-email.component";
import { MicroSelectComponent } from "../../mirco-components/micro-select/micro-select.component";
import { MicroTextBoxComponent } from "../../mirco-components/micro-text-box/micro-text-box.component";
import { MicroCheckBoxComponent } from "../../mirco-components/micro-check-box/micro-check-box.component";
import { MicroDateComponent } from "../../mirco-components/micro-date/micro-date.component";
import { ListFilterComponent } from "../list-filter/list-filter.component";
import { TableComponent } from "../../mirco-components/table/table.component";
import { DynamicGroupListComponent } from "../dynamic-group-list/dynamic-group-list.component";
import { MatDialog } from '@angular/material/dialog';
import { CreateDynamicFormComponent } from "../create-dynamic-form/create-dynamic-form.component";
import { DynamicFormGroupComponent } from "../dynamic-form-group/dynamic-form-group.component";
import { httpService } from '../../services/http.service';
import { DynamicTableComponent } from "../dynamic-table/dynamic-table.component";

@Component({
  selector: 'dynamic-list-with-filter',
  standalone: true,
  imports: [TextEmailComponent, MicroSelectComponent, MicroTextBoxComponent, MicroCheckBoxComponent, MicroDateComponent, ListFilterComponent, TableComponent, DynamicGroupListComponent, CreateDynamicFormComponent, DynamicFormGroupComponent, DynamicTableComponent],
  templateUrl: './dynamic-list-with-filter.component.html',
  styleUrl: './dynamic-list-with-filter.component.scss'
})
export class DynamicListWithFilterComponent {
  accessToken = localStorage.getItem('access') || '';
  filterFormMetaData: any;
  listData: any;
  width: string = 'width:400px !important;';
  viewRender: boolean = false;
  @Input() metaData: any = [];
  public loading: boolean = true;
  constructor(private dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef, private http: httpService) {

  }
  ngOnInit() {
    this.initiate();
  }
  initiate() {
    setTimeout(() => {
      console.log('Ref component data', this.metaData)
      if (this.metaData != undefined) {
        debugger
        this.metaData['formPopupWidth'] = '75%';
        this.filterFormMetaData = this.getFormMetaData('filter_form');
        this.filterFormMetaData['datasource']=this.metaData?.datasource;
        this.width = 'width:' + this.metaData?.formPopupWidth + ' !important;';
        //this.filterForm = this.helperService.initiateForm(this.metaData?.controls);
        //this.changeDetectorRef.detectChanges();
        this.viewRender = true;
        this.fetchFormData();
        // this.formGroup.patchValue(this.data);// create a service 
        //this.filterForm.updateValueAndValidity();
        // this.changeDetectorRef?.detectChanges();
      } else {
        this.initiate()
        console.log('call createForm')
      }
    }, 100);
  }
  getlistData(data: any) {
    this.listData = data;
  }

  editRecord(recordData: any) {
    debugger
    this.createDynamicForm('edit', recordData);
  }
  data: any;
  OffCanvasShow: boolean = false
  createDynamicForm(actionType: string = 'add', rowData: any = null) {
    if (this.dialog?.openDialogs?.length == 0) {
      console.log('metadata', this.metaData?.create_form)
      let formMetaData = actionType == 'add' ? this.metaData?.create_form : this.metaData?.update_form;
      formMetaData['dataSource'] = this.metaData?.datasource
      this.data = {
        metaData:formMetaData, rowData: rowData
      }
      //this.changeDetectorRef.detectChanges();
      this.OffCanvasShow = true;
      
    }

  }

  //service method
  getFormMetaData(objKey: string) {

    return this.metaData[objKey] ? this.metaData[objKey] : [];

  }
  closeOffCanvas() {
    this.OffCanvasShow = false;
    // if (data) {
    this.fetchFormData();
    // }

  }
  fetchFormData() {
    this.loading = true;
    let url = this.metaData.datasource;
    this.http.get(url).subscribe({
      next: (response: any) => {
        this.listData = response.data;
        this.loading = false;
      }, error: err => {
        console.log('hello', err)
        this.loading = false
      }
    })
  }
  setListData(data: any) {
    this.listData = data;
  }
  response: any = {
    "msg_code": [],
    "data": {
      "key": "BLKK3GVN4XZPQYMKI5YJ",
      "category": "7F619ATPKFJBWKL35M7T",
      "gender": "RD312AZBQI82AHTKIZM5",
      "name": "Hair Cut & Beard Trim",
      "description": "this is desciption",
      "hsn_code": "1236",
      "variants": [
        {
          "key": "V3PYXYC7UXDZKF5M16A3",
          "sku": "SKU003",
          "barcode": "BARCODE003",
          "unit": "48UZRCK1XRXVMHRXDXE3",
          "price": 249,
          "mrp": 449,
          "tax_rate": 18,
          "is_tax_inclusive": false,
          "duration": 45,
          "bundles": [
            {
              "key": "PLDBJQIEL4QAJXZ3ZH5D",
              "included_variant": "HVOESYBDLRWKAL7FCBM3",
              "quantity": 1
            },
            {
              "key": "9CJ2SR5DQBR31FZEOFJB",
              "included_variant": "RC9HCS6GRZMQJYN9E3A3",
              "quantity": 1
            }
          ]
        }, {
          "key": "V3PYXYC7UXDZKF5M16A3",
          "sku": "SKU003",
          "barcode": "BARCODE003",
          "unit": "48UZRCK1XRXVMHRXDXE3",
          "price": 249,
          "mrp": 449,
          "tax_rate": 18,
          "is_tax_inclusive": false,
          "duration": 45,
          "bundles": [
            {
              "key": "PLDBJQIEL4QAJXZ3ZH5D",
              "included_variant": "HVOESYBDLRWKAL7FCBM3",
              "quantity": 1
            },
            {
              "key": "9CJ2SR5DQBR31FZEOFJB",
              "included_variant": "RC9HCS6GRZMQJYN9E3A3",
              "quantity": 1
            }
          ]
        }
      ]
    },
    "r_id": "ff9c0df6-a4ba-11ef-9553-0242ac170002",
    "status": 200
  }
  formMetaData: any = {
    "name": "service_create",
    "controls": [
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "category",
        "type": "select",
        "label": "Cateogry",
        "datasource": {
          "url": "https://salon.mynextsalon.com/api/lookup/category",
          "method": "GET",
          "value_key": "key",
          "display_key": "name"
        },
        "is_visible": true,
        "is_readonly": false,
        "is_required": true,
        "placeholder": "Select Category"
      },
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "gender",
        "type": "select",
        "label": "Gender",
        "datasource": {
          "url": "https://salon.mynextsalon.com/api/lookup/gender",
          "method": "GET",
          "value_key": "key",
          "display_key": "name"
        },
        "is_visible": true,
        "is_readonly": false,
        "is_required": false,
        "placeholder": "Select Gender"
      },
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "name",
        "type": "text",
        "label": "Product Name",
        "regex": "",
        "is_visible": true,
        "max_length": 255,
        "min_length": 1,
        "is_readonly": false,
        "is_required": true,
        "placeholder": "Enter Product Name"
      },
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "hsn_code",
        "type": "text",
        "label": "HSN Code",
        "regex": "",
        "is_visible": true,
        "max_length": 10,
        "min_length": 10,
        "is_readonly": false,
        "is_required": false,
        "placeholder": "Enter HSN Code"
      },
      {
        "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
        "name": "variants",
        "type": "formarray",
        "is_visible": true,
        "label": "Prices",
        "controls": [
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "sku",
            "type": "text",
            "label": "SKU",
            "regex": "",
            "is_visible": true,
            "max_length": 255,
            "min_length": 1,
            "is_readonly": false,
            "is_required": true,
            "placeholder": "Enter SKU"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "barcode",
            "type": "text",
            "label": "Barcode",
            "regex": "",
            "is_visible": true,
            "max_length": 255,
            "min_length": 1,
            "is_readonly": false,
            "is_required": false,
            "placeholder": "Enter Barcode"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "unit",
            "type": "select",
            "label": "Unit",
            "datasource": {
              "url": "https://salon.mynextsalon.com/api/lookup/unit",
              "method": "GET",
              "value_key": "key",
              "display_key": "name"
            },
            "is_visible": true,
            "is_readonly": false,
            "is_required": false,
            "placeholder": "Select Unit"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "price",
            "type": "integer",
            "label": "Price",
            "regex": "",
            "is_visible": true,
            "max_length": 100,
            "min_length": 1,
            "is_readonly": false,
            "is_required": true,
            "placeholder": "Enter Price"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "mrp",
            "type": "integer",
            "label": "MRP",
            "regex": "",
            "is_visible": true,
            "max_length": 100,
            "min_length": 1,
            "is_readonly": false,
            "is_required": true,
            "placeholder": "Enter MRP"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "tax_rate",
            "type": "integer",
            "label": "Tax Rate",
            "regex": "",
            "is_visible": true,
            "max_length": 100,
            "min_length": 1,
            "is_readonly": false,
            "is_required": true,
            "placeholder": "Tax Rate"
          },
          {
            "div": "col-xl-2 col-md-2 col-sm-2 mb-4",
            "name": "is_tax_inclusive",
            "type": "swtich",
            "label": "Is Tax Inclusive",
            "regex": "",
            "is_visible": true,
            "is_readonly": false,
            "is_required": true
          },
          {
            "div": "col-xl-4 col-md-4 col-sm-4",
            "name": "bundles",
            "type": "formarray",
            "is_visible": true,
            "label": "Included Services",
            "controls": [
              {
                "div": "col-xl-5 col-md-5 col-sm-5",
                "name": "included_variant",
                "type": "select",
                "label": "Select Product",
                "datasource": {
                  "url": "https://salon.mynextsalon.com/api/itemmaster/service/variant",
                  "method": "GET",
                  "value_key": "key",
                  "display_key": "name"
                },
                "is_visible": true,
                "is_readonly": false,
                "is_required": true,
                "placeholder": "Select Service"
              },
              {
                "div": "col-xl-5 col-md-5 col-sm-5",
                "name": "quantity",
                "type": "integer",
                "label": "Quantity",
                "regex": "",
                "is_visible": true,
                "max_length": 100,
                "min_length": 1,
                "is_readonly": false,
                "is_required": true,
                "placeholder": "Enter Quantity"
              }
            ],
            "add_button": true,
            "remove_button": true
          }
        ]
      }
    ]
  }

}
