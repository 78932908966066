import {Component, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {AuthService} from "../../../services/auth.service";
import {ServicesService} from "../../../services/services.service";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";
import {MenuItem} from "../../../interface/login.model";
import {ValidatorsService} from "../../../services/validators.service";
import {MenuService} from "../../../services/menu.service";
import {MicroTextBoxComponent} from "../../../mirco-components/micro-text-box/micro-text-box.component";
import {TextEmailComponent} from "../../../mirco-components/text-email/text-email.component";
import {MicroPasswordComponent} from "../../../mirco-components/micro-password/micro-password.component";
import {MicroCheckBoxComponent} from "../../../mirco-components/micro-check-box/micro-check-box.component";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {PrivacyPolicyComponent} from "../privacy-policy/privacy-policy.component";
import {RecaptchaComponent} from "../../utils/recaptcha/recaptcha.component";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-register-cover',
  standalone: true,
  providers: [AuthService],
  imports: [MatButtonModule, MatDialogModule, RouterLink, FormsModule, ReactiveFormsModule, CommonModule, NgxSpinnerComponent, MicroTextBoxComponent, TextEmailComponent, MicroPasswordComponent, MicroCheckBoxComponent, RecaptchaComponent,],
  templateUrl: './register-cover.component.html',
  styleUrl: './register-cover.component.scss'
})
export class RegisterCoverComponent implements OnInit {

  public registerForm!: FormGroup;
  public loading: boolean = false;
  public passwordVisibility = {
    password: false, otp: false,
  };
  public serviceData: any = {};
  public registerFormMetaData!: MenuItem;
  public isServiceFetched: boolean = false;
  public isUserRegistered: boolean = false;
  public registeredMailAddress: string = "";

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private services: ServicesService, private spinner: NgxSpinnerService, private validatorService: ValidatorsService, private menuService: MenuService, public dialog: MatDialog, private notificationService: NotificationService) {
    this.registerForm = this.fb.group({});
  }

  ngOnInit() {
    this.getServices();
    this.verifyPublicMenu()
  }

  registerUser() {
    this.loading = true;
    const payload = this.registerForm.getRawValue();
    this.registerForm.disable();
    this.spinner.show('registerPage')
    this.authService.registerUser(payload).subscribe({
      next: (response) => {
        console.log('data', response)
        this.spinner.hide('registerPage')
        this.notificationService.success('Registered successfully.');
        this.registerForm.enable();
        this.registeredMailAddress = this.registerForm.get('email')?.value;
        this.loading = false;
        if (response?.status === 200) {
          this.isUserRegistered = true;
        }
      }, error: (error) => {
        this.spinner.hide('registerPage')
        console.log('error', error);
        this.registerForm.enable();
        this.loading = false;
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }, complete: () => {
        this.spinner.hide('registerPage')
        this.loading = false;
        this.registerForm.enable();
      }
    })
  }

  resendMail() {
    const payload = {email: this.registeredMailAddress}
    this.spinner.show('registerPage')
    this.authService.sendVerifyEmail(payload).subscribe({
      next: (response) => {
        this.spinner.hide('registerPage')
        console.log('data', response)
        this.notificationService.success('Verification link resent successfully.');
        this.registerForm.enable();
      }, error: (error) => {
        this.spinner.hide('registerPage')
        console.log('error', error);
        this.registerForm.enable();
        this.loading = false;
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }

  getServices() {
    this.services.serviceData$.subscribe(data => {
      this.serviceData = data;
    });
  }

  verifyPublicMenu() {
    this.spinner.show('registerPage');
    this.menuService.verifyPublicMenu().subscribe({
      next: (response: any) => {
        console.log('response', response)
        this.registerFormMetaData = response?.data;
        this.spinner.hide('registerPage');
        this.createRegisterForm();
      }, error: (error) => {
        this.spinner.hide('registerPage');
        console.log('error', error);
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }

  createRegisterForm() {
    this.registerFormMetaData?.create_form?.controls?.forEach((control: any) => {
      if (control.is_visible) {
        const validators = this.validatorService.getValidators(control);
        if (control.name === 'terms') {
          validators.push(Validators.requiredTrue);
        }
        this.registerForm.addControl(control.name, this.fb.control('', validators))
      }
    });
  }

  openPrivacyPolicy() {
    if (this.dialog.openDialogs.length === 0) {
      const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
        width: '75vw', maxWidth: '85vw', disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        this.registerForm.get('terms')?.patchValue(result);
      });
    }
  }
}
