import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableModule, TablePageEvent} from "primeng/table";
import {CommonModule} from "@angular/common";
import {UsersService} from "../../services/users.service";
import {InputSwitchModule} from "primeng/inputswitch";
import {FormsModule} from "@angular/forms";
import {Button} from "primeng/button";
import { httpService } from '../../services/http.service';

@Component({
  selector: 'dynamic-table',
  standalone: true,
  imports: [CommonModule, TableModule, FormsModule, InputSwitchModule, Button],
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent implements OnInit {
  @Input() tableData: any[] = [];
  @Input() url!: string;
  @Input() loading: boolean = true;
  @Output() callback: any = new EventEmitter<any>();
  metaKey: boolean = true;
  selectedProducts: any[] = [];
  tableColumns: any[] = []
  isRender:boolean=false;
  constructor(private http: httpService) {
  }

  ngOnInit() {
    //setTimeout(() => {
    //  this.init()
   // }, 200);
    this.initiate();
  }

  initiate() {
    if (this.tableData!=undefined) {
      this.tableColumns = (Object.keys(this.tableData[0]))?.filter((x:any) => x!=='key');
      this.isRender=true;
    } else {
      this.initiate();
      //this.tableColumns=[]
    }
  }
  pageChange(event: TablePageEvent) {
    console.log('page change event fire:', event)
  }

  openEditPopup(data: any) {
    this.http.get(this.url + '?key=' + data.key).subscribe({
      next: (res: any) => {
        this.loading = false,
          this.edit(res?.data);
        console.log('response:', res?.data);
      },
      error: (err: any) => {
        this.loading = false;
      }
    })
  }
  edit(data: any) {
    this.callback.emit(data)
  }
}
