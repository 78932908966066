import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validator } from '@angular/forms';

@Component({
  selector: 'micro-textarea',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule],
  templateUrl: './micro-textarea.component.html',
  styleUrl: './micro-textarea.component.scss',
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  changeDetection:ChangeDetectionStrategy.OnPush,
  providers:[
     {
      provide:NG_VALUE_ACCESSOR,useExisting:forwardRef(()=>MicroTextareaComponent),multi:true
     },
     {
      provide:NG_VALIDATORS,useExisting:forwardRef(()=>MicroTextareaComponent),multi:true
     }
  ]
})
export class MicroTextareaComponent implements ControlValueAccessor, Validator, OnInit, OnChanges{
  @Input() placeholder: string = '';
  @Input() isRequired:boolean=false;
  @Input() label:string='';
  @Input() error: any 
  public innerValue: any = '';
  public onChange: (value: any) => void = () => { };
  public onTouched: () => void = () => { };

  formGroup!: FormGroup;
  control: FormControl = new FormControl('');

  constructor(private fb: FormBuilder,private changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.control.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }
  triggerChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['isError']) {
      this.error = changes['isError'].currentValue;
    }
  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    this.innerValue = value;
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  validate(control: FormControl): { [key: string]: any } | null {
    return this.control.errors;
  }
  
}
