import { Injectable } from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validator, Validators} from "@angular/forms";
import { httpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private fb: FormBuilder, private http: httpService) {

  }
  appData:any;
  formGroup: FormGroup = new FormGroup({})
  initiateForm(formMetaData: any) {
    this.formGroup=new FormGroup({});
    // this.formGroup.addControl('key', new FormControl(null));
     formMetaData?.forEach((ctrObj: any) => {
      let validatorsArray: any[] = [];
      if (ctrObj?.is_required) {
        validatorsArray.push(Validators.required)
      }
      if (ctrObj?.regex) {
        validatorsArray.push(Validators.pattern(ctrObj?.regex))
      }
      this.formGroup.addControl(ctrObj?.name, new FormControl(null, validatorsArray));
    })
    return this.formGroup;
  }

  getAllErrors(form: FormGroup): { [key: string]: string[] } {
    const errors: { [key: string]: string[] } = {};

    Object.keys(form.controls).forEach(controlName => {
      const control = form.get(controlName);
      if (control && control.invalid) {
        errors[controlName] = this.getControlErrors(control);
      }
    });

    return errors;
  }
  private getControlErrors(control: AbstractControl): string[] {
    const errorMessages: string[] = [];
    const errors = control.errors;
    console.log('Error in Form:', errors);
    errorMessages.push(JSON.stringify(errors))
    return errorMessages;
  }
  createQueryString(baseUrl: string = '', dataObj: any) {
    const queryString = new URLSearchParams(dataObj).toString();
    const fullUrl = baseUrl != null && baseUrl != '' && baseUrl != undefined ? `${baseUrl}?${queryString}` : queryString;
    return fullUrl;
  }
}
