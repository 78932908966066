import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {EventClickArg} from '@fullcalendar/core'
import {FullCalendarComponent, FullCalendarModule} from "@fullcalendar/angular";
import {CalendarOptions} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {MicroCheckBoxComponent} from "../../../mirco-components/micro-check-box/micro-check-box.component";
import {MicroDateComponent} from "../../../mirco-components/micro-date/micro-date.component";
import {MicroSelectComponent} from "../../../mirco-components/micro-select/micro-select.component";
import {MicroTextBoxComponent} from "../../../mirco-components/micro-text-box/micro-text-box.component";
import {NgForOf, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {TextEmailComponent} from "../../../mirco-components/text-email/text-email.component";
import {UsersService} from "../../../services/users.service";
import {NgxSpinnerService} from "ngx-spinner";
import {MatDialog} from "@angular/material/dialog";
import {httpService} from "../../../services/http.service";
import {Control, MenuItem} from "../../../interface/login.model";
import {ValidatorsService} from "../../../services/validators.service";
import {CreateDynamicFormComponent} from "../../create-dynamic-form/create-dynamic-form.component";
import {ToastrService} from "ngx-toastr";
import {MenuService} from "../../../services/menu.service";
import {AppointmentService} from "../../../services/appointment.service";

@Component({
  selector: 'app-fullcalendar',
  standalone: true,
  imports: [FullCalendarModule, MicroCheckBoxComponent, MicroDateComponent, MicroSelectComponent, MicroTextBoxComponent, NgForOf, NgIf, ReactiveFormsModule, TextEmailComponent, CreateDynamicFormComponent],
  templateUrl: './fullcalendar.component.html',
  styleUrl: './fullcalendar.component.scss'
})
export class FullcalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  public filterForm: FormGroup;
  public createControls = {
    "name": "appointment_create",
    "controls": [{
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "name",
      "type": "text",
      "label": "Title",
      "regex": "",
      "is_visible": true,
      "max_length": 255,
      "min_length": 1,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Enter Title"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "start_date",
      "type": "datetimepicker",
      "label": "Start Date",
      "regex": "",
      "is_visible": true,
      "max_length": 255,
      "min_length": 5,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Enter Start Date"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "end_date",
      "type": "datetimepicker",
      "label": "End Date",
      "regex": "",
      "is_visible": true,
      "max_length": 255,
      "min_length": 5,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Enter End Date"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "phone",
      "type": "text",
      "label": "Phone",
      "regex": "",
      "is_visible": true,
      "max_length": 15,
      "min_length": 10,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Enter Phone"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "gender",
      "type": "select",
      "label": "Gender",
      "datasource": {
        "url": "https://authorization.mynextsalon.com/api/lookup/gender",
        "method": "GET",
        "value_key": "key",
        "display_key": "name"
      },
      "is_visible": true,
      "is_readonly": false,
      "is_required": true,
      "placeholder": "Select Gender"
    }, {
      "div": "col-12 col-xs-12 col-md-12 col-lg-12",
      "name": "dob",
      "type": "number",
      "label": "Age",
      "is_visible": true,
      "is_readonly": false,
      "is_required": false,
      "placeholder": "Select Age"
    }]
  }
  public OffCanvasShow: boolean = false;
  public data: any;

  public calendarMode: string = '';
  public calendarModeApplied: boolean = false;
  public calendarOptions: CalendarOptions = {};
  public appointmentMetaData!: MenuItem;
  private calendarModes: string[] = ['multiMonthYear', 'dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listMonth'];
  private accessToken = localStorage.getItem('access') || '';

  constructor(private userService: UsersService, private fb: FormBuilder, private spinner: NgxSpinnerService, private dialog: MatDialog,
              private http: httpService, private validatorService: ValidatorsService, private toastr: ToastrService, private menuService: MenuService,
              private appointmentService: AppointmentService) {
    this.filterForm = this.fb.group({})
    this.setInitialCalendarOptions();
  }

  ngOnInit() {
    this.getVerifyMenu();
  }

  ngAfterViewInit() {
    // this.scrollCurrentIntoView()
    // setInterval(() => {
    //   this.scrollCurrentIntoView()
    // }, 1000 * 60)
  }

  handleDragSelect(selectionInfo: any) {
    this.data = {
      formMetaData: this.appointmentMetaData.create_form,
      rowData: {
        start_date: selectionInfo.startStr.split('+')[0],
        end_date: selectionInfo.endStr.split('+')[0],
      },
    }
    this.OffCanvasShow = true;
  }

  handleDateClick(arg: any) {
    this.OffCanvasShow = true;
    console.log('args', arg)
    this.data = {
      formMetaData: this.appointmentMetaData.create_form,
      rowData: {
        name: 'I am date click not new range'
      }
    }
  }

  handleEventClick(info: EventClickArg) {
    info.jsEvent.preventDefault();
    const event = info.event;
    if (event.allDay) {
      this.data = {
        formMetaData: this.appointmentMetaData.update_form,
        rowData: {
          name: event.title,
          start_date: event.startStr ? event.startStr + 'T00:00:00' : '',
          end_date: event.endStr ? event.endStr + 'T00:00:00' : '',
        }
      }
    } else {
      this.data = {
        formMetaData: this.appointmentMetaData.update_form,
        rowData: {
          name: event.title,
          start_date: event.startStr.split('+')[0],
          end_date: event.endStr.split('+')[0],
        }
      }
    }
    this.OffCanvasShow = true;
  }

  createFilterForm() {
    this.appointmentMetaData.filter_form?.controls?.forEach((control: Control) => {
      if (control.is_visible) {
        const validators = this.validatorService.getValidators(control);
        this.filterForm.addControl(
          control.name,
          this.fb.control('', validators)
        )
      }
    });
  }

  handleAppointmentFilter() {
    console.log('Submit', this.filterForm.value);
    this.filterForm.disable();
    setTimeout(() => {
      this.filterForm.enable();
    }, 2500)
  }

  closeOffCanvas(data: any) {
    this.OffCanvasShow = false;
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.unselect(); // Programmatically unselects any current selection
    if (data) {
      this.fetchAppointments();
    }
  }

  fetchAppointments() {
    debugger;
    const url: string = this.appointmentMetaData.datasource;
    if (url && this.accessToken) {
      this.appointmentService.getAppointments(url, 'GET', this.accessToken).subscribe({
        next: (response: any) => {
          console.log('response:', response);
          this.createEventsFromAppointment(response?.data);
        },
        error: err => {
          console.log('error:', err);
        }
      })
    }
  }

  createEventsFromAppointment(data: any[]) {
    if (Array.isArray(data) && data.length) {
      const eventList:any[] = data.map(item => {
        return {
          title: item.name,
          start: `${item.date}T${item.start_time}`,
          end: `${item.date}T${item.end_time}`,
          allDay: false,
        };
      });

      if (!this.calendarOptions.events) {
        this.calendarOptions.events = []; // Initialize events if not already set
      }

      // Refresh FullCalendar
      if (this.calendarComponent) {
        this.calendarComponent.events = eventList;
        this.calendarComponent.getApi().refetchEvents();

      }

    }
  }


  scrollCurrentIntoView(): void {
    (document.querySelector('.fc .fc-timegrid-now-indicator-arrow') as HTMLElement).scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  calendarModeChanged(info: any) {
    console.log('calendarModeChanged: ', info);
    localStorage.setItem('calendarMode', JSON.stringify(info.view.type));
  }

  setInitialCalendarOptions() {
    const savedViewMode = this.getCalendarMode();
    this.calendarOptions = {
      initialView: savedViewMode,
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, multiMonthPlugin, listPlugin],
      weekends: true,
      selectable: true,
      selectMirror: true,
      unselectAuto: false,
      select: (selectionInfo: any) => this.handleDragSelect(selectionInfo),
      unselect: (selectionInfo: any) => {

      },
      datesSet: (info: any) => {
        console.log('datesSet', info)
        if (this.calendarMode !== info.view.type) {
          console.log('calendarMode changed for view type', info.view.type, this.calendarMode);
          this.calendarMode = info.view.type;
          this.calendarModeChanged(info);
        }
      },
      customButtons: {
        addAppointment: {
          text: 'Add Appointment',
          click: () => {
            this.OffCanvasShow = true;
            this.data = {
              formMetaData: this.appointmentMetaData.create_form,
              rowData: {}
            }
          }
        }
      },
      headerToolbar: {
        left: "prev,next addAppointment",
        center: 'title',
        right: this.calendarModes.join(','),
      },
      nowIndicator: true,
      buttonText: {
        year: 'Year',
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List'
      },
      views: {
        multiMonthFourMonth: {
          type: 'multiMonth',
          duration: {months: 12}
        }
      },
      businessHours: [
        {
          daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
          startTime: '07:00',
          endTime: '21:00'
        }
      ],
      dateClick: (arg: any) => this.handleDateClick(arg),
      eventClick: (info: EventClickArg) => this.handleEventClick(info),
      events: [],
    };
    this.calendarModeApplied = true;
  }

  getVerifyMenu() {
    this.menuService.verifyMenu(this.accessToken).subscribe({
      next: (response: any) => {
        console.log('response:', response);
        this.appointmentMetaData = response?.data;
        this.createFilterForm();
        this.fetchAppointments();
      }, error: (error) => {
        console.log('Something went wrong')
      }
    })
  }

  private getCalendarMode() {
    const type = localStorage.getItem('calendarMode');
    if (type && this.calendarModes.includes(JSON.parse(type))) {
      return JSON.parse(type);
    }
    return 'timeGridDay'
  }
}
