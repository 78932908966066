import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string = 'https://authorization.mynextsalon.com';
  private serviceData: any;

  constructor(private http: HttpClient) {
    const data = localStorage.getItem('services');
    if (data) {
      this.serviceData = JSON.parse(data)
    }
  }

  registerUser(payload: any): Observable<any> {
    return this.http.post(this.baseUrl + '/auth/signup', payload);
  }

  loginUser(payload: any, url: string): Observable<any> {
    return this.http.post(url, payload);
  }

  selectWorkspace(token: string, payload: any, url: string): Observable<any> {
    return this.http.post(url, payload, {headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)});
  }

  getWorkspace(token: string, url: string): Observable<any> {
    return this.http.get(url, {headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)});
  }

  sendVerifyEmail(payload: any): Observable<any> {
    return this.http.post(this.baseUrl + '/auth/verify-email', payload);
  }

  verifyEmailLink(code: string) {
    return this.http.get(this.baseUrl + '/auth/verify-email?code=' + code);
  }

  sendResetLink(payload: any, url: string): Observable<any> {
    return this.http.post(url, payload);
  }

  resetPassword(payload: any, url: string): Observable<any> {
    return this.http.post(url, payload)
  }

  logout() {
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
  }

}
