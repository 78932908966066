<div class="position-relative" data-select2-id="62"><select id="multicol-country" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="multicol-country" tabindex="-1" aria-hidden="true">
    <option value="" data-select2-id="2">Select</option>
    <option value="Australia" data-select2-id="68">Australia</option>
    <option value="Bangladesh" data-select2-id="69">Bangladesh</option>
    <option value="Belarus" data-select2-id="70">Belarus</option>
    <option value="Brazil" data-select2-id="71">Brazil</option>
    <option value="Canada" data-select2-id="72">Canada</option>
    <option value="China" data-select2-id="73">China</option>
    <option value="France" data-select2-id="74">France</option>
    <option value="Germany" data-select2-id="75">Germany</option>
    <option value="India" data-select2-id="76">India</option>
    <option value="Indonesia" data-select2-id="77">Indonesia</option>
    <option value="Israel" data-select2-id="78">Israel</option>
    <option value="Italy" data-select2-id="79">Italy</option>
    <option value="Japan" data-select2-id="80">Japan</option>
    <option value="Korea" data-select2-id="81">Korea, Republic of</option>
    <option value="Mexico" data-select2-id="82">Mexico</option>
    <option value="Philippines" data-select2-id="83">Philippines</option>
    <option value="Russia" data-select2-id="84">Russian Federation</option>
    <option value="South Africa" data-select2-id="85">South Africa</option>
    <option value="Thailand" data-select2-id="86">Thailand</option>
    <option value="Turkey" data-select2-id="87">Turkey</option>
    <option value="Ukraine" data-select2-id="88">Ukraine</option>
    <option value="United Arab Emirates" data-select2-id="89">United Arab Emirates</option>
    <option value="United Kingdom" data-select2-id="90">United Kingdom</option>
    <option value="United States" data-select2-id="91">United States</option>
  </select><span class="select2 select2-container select2-container--default select2-container--below select2-container--focus" dir="ltr" data-select2-id="1" style="width: 409.2px;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-multicol-country-container"><span class="select2-selection__rendered" id="select2-multicol-country-container" role="textbox" aria-readonly="true"><span class="select2-selection__placeholder">Select value</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span></div>