<ngx-spinner [fullScreen]="true" bdColor="rgba(0,0,0,0.5)" name="loginPage" size="default"
             type="ball-pulse"></ngx-spinner>

<div class="authentication-wrapper authentication-cover">
  <!-- Logo -->
  @if (isServiceFetched) {
    <a class="auth-cover-brand d-flex align-items-center gap-3" routerLink="/login">
      <div class="app-brand-logo">
        <div style="color:var(--bs-primary);">
          <img [src]="serviceData?.logo" alt="LOGO" class="rounded-2 logo" height="50"
               width="50">
        </div>
      </div>
      <span class="app-brand-text demo text-heading fw-semibold" *ngIf="false">{{ serviceData?.name }}</span>
    </a>
  }

  <!-- /Logo -->
  <div class="authentication-inner row m-0">
    <!-- /Left Section -->

    <div class="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
      <div>
        <img [src]="serviceData?.cover?serviceData?.cover:'https://app.mynextsalon.com/assets/img/services/cover.png'"
             alt="auth-model" class="authentication-image-model d-none d-lg-block"
             data-app-dark-img="illustrations/auth-cover-login-illustration-dark.png"
             data-app-light-img="illustrations/auth-cover-login-illustration-light.png"
             height="700"
             style="object-fit: contain">
      </div>
    </div>
    <!-- /Left Section -->
    <!-- Login -->
    <div
      class="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-5 px-md-12 py-4">
      <ngx-spinner [fullScreen]="false" bdColor="rgba(0,0,0,0.5)" color="#ffffff" name="loginForm" size="default"
                   type="ball-pulse">
      </ngx-spinner>

      @if (loginFormMetaData?.key) {
        <div class="w-px-400 mx-auto pt-5 pt-lg-0">
          <h4 class="mb-1">Welcome to {{ serviceData?.name | titlecase }}! 👋🏻</h4>
          <p class="mb-3">{{ serviceData.description }}</p>

          <form (ngSubmit)="login()" [formGroup]="loginForm" class="mb-5 fv-plugins-bootstrap5 fv-plugins-framework"
                id="formAuthentication">
            @if (loginFormMetaData?.create_form?.controls?.length) {
              @for (control of loginFormMetaData?.create_form?.controls; track control.name) {
                <div class="mb-3">
                  @if (control.type === 'email') {
                    <text-email [error]="loginForm.get(control.name)"
                                formControlName='{{control.name}}' placeholder='{{control.placeholder}}'
                                label='{{control.label}}'>
                    </text-email>
                  } @else if (control.type === 'password') {
                    <micro-password [error]="loginForm.get(control.name)"
                                    formControlName='{{control?.name}}' placeholder='{{control?.placeholder}}'
                                    label='{{control?.label}}'>
                    </micro-password>
                  } @else if (control.type === 'recaptchaV2') {
                    <mns-recaptcha [control]="control"></mns-recaptcha>
                  } @else if (control.type === 'checkbox') {
                    <div class="d-flex justify-content-between flex-wrap py-2 align-items-baseline">
                      <div class="form-check mb-0">
                        <micro-check-box [error]="loginForm.get(control.name)"
                                         formControlName='{{control?.name}}' placeholder='{{control?.placeholder}}'
                                         label='{{control?.label}}'>
                        </micro-check-box>
                      </div>
                      <a class="float-end mb-1" routerLink="/forgot-password"
                         [state]="{ email: loginForm?.get('email')?.value }">
                        <span>Forgot Password?</span>
                      </a>
                    </div>
                  }
                </div>
              }
            }
            <button [disabled]="loading || loginForm?.invalid"
                    class="btn btn-primary d-grid w-100 waves-effect waves-light" type="submit">
              Login
            </button>
          </form>

          <p class="text-center">
            <span>New on our platform? </span>
            <a routerLink="/register">
              <span>Create an account</span>
            </a>
          </p>
        </div>
      }
    </div>
    <!-- /Login -->
  </div>
</div>
