<h2 mat-dialog-title>MY Next Salon Privacy Policy and Terms</h2>
<mat-dialog-content class="mat-typography">
  <div class="privacy-policy">
    <h4>Privacy Policy</h4>
    <p><strong>Effective Date:</strong> 01-Nov-2024</p>

    <p>
      <strong>MyNextSalon</strong> ("we", "us", "our") respects your privacy and is committed to
      protecting your personal data. This privacy policy explains how we handle your personal
      information, how we use it, and your rights regarding your information when you use our salon
      management website and services.
    </p>

    <h5>1. Information We Collect</h5>
    <p>We may collect the following types of personal information from users of our website:</p>
    <ul>
      <li><strong>Business Name</strong></li>
      <li><strong>Full Name</strong></li>
      <li><strong>Email Address</strong></li>
      <li><strong>Phone Number</strong></li>
      <li><strong>Password</strong></li>
      <li>Any other information you provide through forms on our website</li>
    </ul>

    <h5>2. How We Use Your Information</h5>
    <p>We use your personal information for the following purposes:</p>
    <ul>
      <li>To provide and improve our salon management services</li>
      <li>To manage your account and support user interactions</li>
      <li>To communicate with you regarding updates, services, and promotions</li>
      <li>To comply with legal obligations or enforce our terms and conditions</li>
    </ul>

    <h5>3. Google Analytics</h5>
    <p>
      We use <strong>Google Analytics</strong> to collect information about how visitors use our website. This
      includes data about your browser type, the pages you visit, the time spent on the site, and how you
      interact with content. We use this information to improve the website's functionality and user
      experience.
    </p>
    <p>
      <strong>Note:</strong> Google Analytics does not identify individual users or associate your IP address
      with any other data held by Google. You can opt out of Google Analytics tracking through your
      browser settings or by using <a href="https://tools.google.com/dlpage/gaoptout">Google's opt-out tools</a>.
    </p>

    <h5>4. Data Sharing</h5>
    <p>We do not share your personal information with third parties, except in the following cases:</p>
    <ul>
      <li>If required by law</li>
      <li>
        With service providers who assist us in operating our website and services, as long as they agree
        to keep your information confidential
      </li>
    </ul>

    <h5>5. Data Security</h5>
    <p>
      We take the security of your personal information seriously. We have implemented appropriate
      physical, technical, and organizational measures to protect your data from unauthorized access, use,
      or disclosure.
    </p>

    <h5>6. Your Rights</h5>
    <p>You have the right to:</p>
    <ul>
      <li>Access your personal data and request corrections if needed</li>
      <li>Request the deletion of your personal data</li>
      <li>Object to the processing of your personal data</li>
      <li>Request the restriction of your data processing</li>
    </ul>
    <p>
      If you wish to exercise any of these rights, please contact us at
      <strong>
        <a href="mailto:contactus@mynextsalon.com">contactus&#64;mynextsalon.com</a>
      </strong>.
    </p>

    <h5>7. Changes to This Policy</h5>
    <p>
      We may update this privacy policy from time to time. The latest version will always be posted on our
      website, and you will be notified of any significant changes.
    </p>

    <h5>8. Contact Us</h5>
    <p>
      If you have any questions or concerns regarding this privacy policy, please contact us at:
    </p>
    <p><strong>Email:</strong>  <a href="mailto:contactus@mynextsalon.com" target="_blank" rel="noreferrer">contactus&#64;mynextsalon.com</a></p>
    <p><strong>Phone:</strong>  <a href="https://wa.me/+918285753336" target="_blank" rel="noreferrer">+91 82857 53336</a></p>
    <p><strong>Address:</strong> Gurgaon, India</p>
  </div>

  <hr />

  <div class="terms-of-service">
    <h4>Terms of Service</h4>
    <p><strong>Effective Date:</strong> [Insert Date]</p>

    <p>
      These terms of service ("Terms") govern your use of the <strong>MyNextSalon</strong> website and
      services. By using our website, you agree to comply with and be bound by the following terms.
    </p>

    <h5>1. User Responsibilities</h5>
    <p>When using our services, you agree to:</p>
    <ul>
      <li>Provide accurate and truthful information during registration</li>
      <li>Maintain the confidentiality of your account information, including your password</li>
      <li>Use the services for lawful purposes only</li>
      <li>Not engage in any activity that may harm the functionality or security of the website</li>
    </ul>

    <h5>2. Service Availability</h5>
    <p>
      We do our best to ensure uninterrupted access to our website. However, we cannot guarantee that
      our services will always be available, error-free, or secure. We may suspend or terminate services at
      any time without notice for maintenance or security purposes.
    </p>

    <h5>3. Limitation of Liability</h5>
    <p>
      To the maximum extent permitted by law, <strong>MyNextSalon</strong> will not be held liable for any
      damages arising from the use or inability to use our services, including, but not limited to, data
      loss, business interruption, or loss of profits.
    </p>

    <h5>4. Modifications to the Terms</h5>
    <p>
      We reserve the right to modify these Terms at any time. Any changes will be posted on the website,
      and by continuing to use our services after changes are made, you agree to the new Terms.
    </p>

    <h5>5. Governing Law</h5>
    <p>
      These Terms are governed by the laws of <strong>Gurgaon India</strong>, and any disputes will be
      resolved in the courts of <strong>Gurgaon India</strong>.
    </p>

    <h5>6. Contact Information</h5>
    <p>
      For any questions regarding these Terms, please contact us at:
    </p>
    <p><strong>Email:</strong>  <a href="mailto:contactus@mynextsalon.com" target="_blank" rel="noreferrer">contactus&#64;mynextsalon.com</a></p>
    <p><strong>Phone:</strong>  <a href="https://wa.me/+918285753336" target="_blank" rel="noreferrer">+91 82857 53336</a></p>
    <p><strong>Address:</strong> Gurgaon, India</p>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">I Disagree</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>I Agree</button>
</mat-dialog-actions>
