import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableModule, TablePageEvent} from "primeng/table";
import {CommonModule} from "@angular/common";
import {UsersService} from "../../services/users.service";
import {InputSwitchModule} from "primeng/inputswitch";
import {FormsModule} from "@angular/forms";
import {Button} from "primeng/button";

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [CommonModule, TableModule, FormsModule, InputSwitchModule, Button],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent {
  @Input() tableData: any[] = [];

  private accessToken = localStorage.getItem('access') || '';
  @Input() loading: boolean = true;
  @Output() callback:any=new EventEmitter<any>();
  metaKey: boolean = true;
  selectedProducts:any[]=[];

  constructor(private userService: UsersService) {
  }

  pageChange(event:TablePageEvent) {
    console.log('page change event fire:', event)
  }

  openEditPopup(user: any) {
    this.userService.getUser(this.accessToken, user.key).subscribe({
      next: (res: any) => {
        this.loading = false,
        this.edit(res?.data);
        console.log('response:', res?.data);
      },
      error: err => {
        this.loading = false;
      }
    })
  }
  edit(data:any){
    this.callback.emit(data)
  }
}
