<footer class="content-footer footer bg-footer-theme bg-white">
  <div class="container-xxl">
    <div
      class="footer-container d-flex align-items-center justify-content-between py-3 flex-md-row flex-column">
      <div class="text-body mb-2 mb-md-0">
        © {{currentFullYear}}, MADE IN INDIA with
        <span class="text-danger"><i class="tf-icons mdi mdi-heart"></i></span>
      </div>
      <div class="d-none d-lg-inline-block">
        <a href="mailto:abhishekyadav.mern@gmail.com"
           class="footer-link" target="_blank">
          Support
        </a>
      </div>
    </div>
  </div>
</footer>
