<div class="form-password-toggle">

    <div class="input-group input-group-merge">
      <div class="form-floating form-floating-outline">
        <input  [ngClass]="{'is-invalid':error?.invalid && (error?.dirty || error?.touched)}"
        [type]="type"
        [formControl]="control"
        [placeholder]="placeholder"
        (blur)="onTouched()"
        class="form-control">
        <label>{{label}}</label>
      </div>

      <span class="input-group-text cursor-pointer" (click)="viewP()"><i  [ngClass]="class"></i></span>

    </div>
    <ng-container
    *ngIf="error?.invalid && (error?.dirty || error?.touched)">
    <div class="fv-plugins-message-container fv-pluins-message-container--enabled text-danger invalid-feedback">
      <ng-container *ngIf="error?.hasError('required')">
        {{label}} is required.
      </ng-container>
    </div>
  </ng-container>
  </div>
