import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class httpService {

  constructor(private http: HttpClient) {

  }
  get(url: string): Observable<any> {
    let accessToken = localStorage.getItem('access') || '';
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    return this.http.get(url, { headers });
  }
  post(url: string,payload:any): Observable<any> {
    let accessToken = localStorage.getItem('access') || '';
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    return this.http.post(url,payload, { headers });
  }
  patch(url: string,payload:any): Observable<any> {
    let accessToken = localStorage.getItem('access') || '';
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    return this.http.patch(url,payload, { headers });
  }
}