import {Component, OnInit, Renderer2} from '@angular/core';
import {SideNavComponent} from "./components/layout/side-nav/side-nav.component";
import {FooterComponent} from "./components/layout/footer/footer.component";
import {HeaderComponent} from "./components/layout/header/header.component";
import {HomeComponent} from "./components/layout/home/home.component";
import {RouterLink, RouterModule, RouterOutlet} from "@angular/router";
import {CommonModule} from "@angular/common";
import {NgxSpinnerComponent, NgxSpinnerService} from "ngx-spinner";
import {ServicesService} from "./services/services.service";


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterOutlet, RouterLink, SideNavComponent, FooterComponent, HeaderComponent, HomeComponent, NgxSpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'MyNextSalon';
  public isServiceFetched: boolean = false;
  private language: string = 'en';
  private direction: string = 'ltr';
  private theme: string = 'theme-default-dark';

  constructor(private renderer2: Renderer2, private spinner: NgxSpinnerService, private services: ServicesService) {
  }

  ngOnInit() {
    this.setLanguageDirection(this.language, this.direction);
    this.setTheme(this.theme);
    this.setServices();
  }

  setLanguageDirection(language: string, direction: string) {
    this.language = language; // Update internal language
    this.direction = direction; // Update internal language
    this.renderer2.setAttribute(document.documentElement, 'lang', this.language); // Set 'lang' attribute on document root
    this.renderer2.setAttribute(document.documentElement, 'dir', this.direction); // Set 'lang' attribute on document root
  }

  setTheme(theme: string) {
    this.theme = theme;
    this.renderer2.setAttribute(document.documentElement, 'data-theme', this.theme);
  }

  setServices() {
    this.spinner.show('app')
    this.services.fetchServiceData();
    this.services.serviceData$.subscribe(data => {
      console.log('service data loaded in app', data);
      this.isServiceFetched = true;
      this.spinner.hide('app');
    });
  }
}
