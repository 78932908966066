import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, NG_VALUE_ACCESSOR, NG_VALIDATORS, ReactiveFormsModule, ControlValueAccessor, Validator } from '@angular/forms';

@Component({
  selector: 'micro-check-box',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './micro-check-box.component.html',
  styleUrl: './micro-check-box.component.scss',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: MicroCheckBoxComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: MicroCheckBoxComponent, multi: true }
  ]
})
export class MicroCheckBoxComponent implements ControlValueAccessor, Validator,OnInit, OnChanges{
  @Input() placeholder: string = '';
  @Input() label:string='';
  @Input() error: any
  public checked: boolean=false;
  public onChange: (value: boolean) => void = () => { };
  public onTouched: () => void = () => { };
  formGroup!: FormGroup;
  control: FormControl = new FormControl('');
  public forId:string;
  constructor(private fb: FormBuilder,private changeDetectorRef: ChangeDetectorRef) {
    this.forId = crypto.randomUUID();
  }
  ngOnInit(): void {
    this.control.valueChanges.subscribe(value => {
      this.onChange(value);
    });
  }
  onCheckboxChange(event: any) {
     this.value= (event?.target as HTMLInputElement)?.checked
    this.onChange(this.value);
  }
  triggerChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['isError']) {
      this.error = changes['isError'].currentValue;
    }
  }
  markNameControlAsTouched(key:any) {
    this.formGroup.get(key)?.markAsTouched();
  }
  get value(): any {
    return this.checked;
  }

  set value(value: any) {

    if (value !== this.checked) {
      this.checked = value;
      this.control.setValue(this.value)
      this.onChange(value);
    }
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched  
 = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
  validate(control: FormControl): { [key: string]: any } | null {
    return this.control.errors;
  }
}
