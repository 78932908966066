<div class="misc-wrapper">
  <h3 class="mb-2 mx-2">Under Maintenance! 🚧</h3>
  <p class="mb-4 mx-2">Sorry for the inconvenience but we're performing some maintenance at the moment</p>
  <div class="d-flex justify-content-center mt-5">
    <img
      src="./assets/img/illustrations/tree-3.png"
      alt="misc-tree"
      class="img-fluid misc-object d-none d-lg-inline-block"
      width="150"/>
    <img
      src="./assets/img/illustrations/misc-mask-light.png"
      alt="misc-error"
      class="scaleX-n1-rtl misc-bg d-none d-lg-inline-block"
      data-app-light-img="illustrations/misc-mask-light.png"
      data-app-dark-img="illustrations/misc-mask-dark.png"/>
    <div class="d-flex flex-column align-items-center">
      <img
        src="./assets/img/illustrations/misc-under-maintenance.png"
        alt="misc-error"
        class="img-fluid z-1"
        width="780"/>
      <div class="z-1">
        <button role="link" type="button" class="btn btn-primary text-center my-5">Back to home</button>
      </div>
    </div>
  </div>
</div>
