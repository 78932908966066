import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validator } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, timeout } from 'rxjs/operators';
import { httpService } from '../../services/http.service';
@Component({
  selector: 'micro-select',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './micro-select.component.html',
  styleUrl: './micro-select.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MicroSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MicroSelectComponent),
      multi: true
    }
  ],
})
export class MicroSelectComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {
  @Input() type: string = 'date';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() error: any;
  @Input() dataSource: any;
  @Input() dataHoldingKey:any;
  public innerValue: any = '';
  public onChange: (value: any) => void = () => { };
  public onTouched: () => void = () => { };
  data: any
  formGroup!: FormGroup;
  control: FormControl = new FormControl('');
  filteredOptions!: Observable<string[]>;
  options: string[] = []

  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef, private http: httpService) {

  }

  ngOnInit() {
    this.control.valueChanges.subscribe(value => {
      this.onChange(value);
    });
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    //setTimeout(() => {

    //}, 100);
  }
  triggerChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['error']) {
      this.error = changes['error'].currentValue;
    }
    this.fetchDropdownData();
  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    if (value !== this.innerValue) {
      value=value=='null'?null:value;
      this.innerValue = value;
      this.onChange(value);
    }
  }

  writeValue(value: any): void {
    this.innerValue = value;
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  validate(control: FormControl): { [key: string]: any } | null {
    return this.control.errors;
  }
  private _filter(value: string): string[] {
   // const filterValue = value.toLowerCase();

    return this.options;//.filter(option => option.toLowerCase().includes(filterValue));

  }
  private fetchDropdownData() {
    console.log('fetchDropdownData' + this.label, this.dataSource)
    if (this.dataSource) {
      if (this.dataSource?.method == 'GET')
        this.http.get(this.dataSource.url).subscribe(result => {
          this.options = result?.data;
          console.log(this.options);
          this.changeDetectorRef.detectChanges();
        }, error => {
          console.error('Error:', error);
        });
    }
  }
  get getOptions(){
    return this.options[this.dataHoldingKey];
  }
}
