import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ValidatorsService} from "../../../services/validators.service";
import {MenuService} from "../../../services/menu.service";
import {HelperService} from "../../../services/helper.service";
import {MatDialog} from "@angular/material/dialog";
import {MenuItem} from "../../../interface/login.model";
import {JsonPipe} from "@angular/common";
import {MicroTextBoxComponent} from "../../../mirco-components/micro-text-box/micro-text-box.component";
import {TextEmailComponent} from "../../../mirco-components/text-email/text-email.component";
import {MicroSelectComponent} from "../../../mirco-components/micro-select/micro-select.component";
import {MicroDateComponent} from "../../../mirco-components/micro-date/micro-date.component";
import {UsersService} from "../../../services/users.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    JsonPipe,
    ReactiveFormsModule,
    MicroTextBoxComponent,
    TextEmailComponent,
    MicroSelectComponent,
    MicroDateComponent
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  public profileForm: FormGroup;
  public profileFormMetaData!: MenuItem;
  public profileData: any;
  private accessToken = window.localStorage.getItem('access') || '';
  public isFormCreated:boolean = false;
  public updating: boolean = false;

  constructor(private fb: FormBuilder, private authService: AuthService, private cdr: ChangeDetectorRef,
              private userService: UsersService, private spinner: NgxSpinnerService, private validatorService: ValidatorsService,
              private menuService: MenuService, public helperService: HelperService, public dialog: MatDialog,
              private notificationService: NotificationService) {
    this.profileForm = this.fb.group({});
  }

  ngOnInit() {
    this.getVerifyMenu()
  }

  getVerifyMenu() {
    this.spinner.show('app');
    this.menuService.verifyMenu(this.accessToken).subscribe({
      next: (response: any) => {
        console.log('response:', response);
        this.profileFormMetaData = response?.data;
        this.fetchProfile();
        this.spinner.hide('app');
      }, error: (error) => {
        this.spinner.hide('app');
        console.log('Something went wrong')
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }


  createProfileForm() {
    this.profileFormMetaData?.update_form?.controls?.forEach((control: any) => {
      if (control.is_visible) {
        const validators = this.validatorService.getValidators(control);
        const controlValue = this.profileData?.[control.name]??'';
        this.profileForm.addControl(
          control.name,
          this.fb.control(controlValue, validators)
        )
      }
    });
    this.isFormCreated=true
  }

  handleProfileUpdate() {
    const payload = this.profileForm.value;
    const url: string = this.profileFormMetaData.datasource;
    this.profileForm.disable();
    this.spinner.show('app');
    this.updating = true;
    this.userService.patchProfile(this.accessToken, url, payload).subscribe({
      next: (response: any) => {
        console.log('response:', response);
        this.profileForm.enable();
        this.profileForm.updateValueAndValidity();
        this.profileForm.markAsPristine();
        this.spinner.hide('passwordForm');
        this.notificationService.success('Profile updated successfully.');
        this.spinner.hide('app');
        this.updating = false;
      },
      error: (error) => {
        this.updating = false;
        this.spinner.hide('app');
        this.profileForm.enable();
        console.log('Something went wrong', error);
        this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
      }
    })
  }
  private fetchProfile() {
    const url = this.profileFormMetaData.datasource;
    if (url) {
      this.spinner.show('app');
      this.userService.getProfile(this.accessToken, url).subscribe({
        next: (response: any) => {
          console.log('response:', response);
          this.profileData = response?.data;
          this.createProfileForm();
          this.spinner.hide('app');
        },
        error: (error) => {
          this.spinner.hide('app');
          console.log('error', error);
          this.notificationService.error(error?.error?.message ? error?.error?.message : 'Something went wrong');
        }
      })
    }
  }
}
